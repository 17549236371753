import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Image,
  Carousel,
  Alert,
  Accordion,
} from 'react-bootstrap';
import { Link, Navigate, useLocation } from 'react-router-dom';
import UserContext from '../../../context/usercontext';
import UserBasicData from './userBasicData';
import UpdateUser from './updateUser';
import BeforeAfter from './BeforeAfter';
import api from '../../../apis/api';
import defaultImage from '../../../img/default.png';
import '../../../index.css';
import SizeHistory from './sizes';
import CoachReport from './CoachReport';
import { useSearchParams } from 'react-router-dom';
import { getUserByEmail } from '../../../apis/findUserByEmail';

const SingleUser = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const userEmail = searchParams.get('email');
  console.log('userEmail', userEmail);
  const handleGetUserData = async () => {
    try {
      const response = await getUserByEmail(userEmail);
      console.log(response.data);
      setSingleUser(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    handleGetUserData();
  }, []);

  const { user, setUser } = useContext(UserContext);
  const [modalShow, setModalShow] = useState(false);
  const [userReport, setUserReport] = useState([]);

  const [userMonthlyReport, setUserMonthlyReport] = useState([]);
  const [isWeeklyError, setIsWeeklyError] = useState(false);
  const [isMonthlyError, setIsMonthlyError] = useState(false);
  const [isWeeklyLoading, setIsWeeklyLoading] = useState(false);
  const [singleUser, setSingleUser] = useState({});

  const coach = 'admin';
  const coachName = 'Azar';
  const [nextgoal, setNextGoal] = useState('');
  const [isWeeklyEmpty, setIsWeeklyEmpty] = useState(false);
  const [isMonthlyEmpty, setIsMonthlyEmpty] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [steps, setSteps] = useState(0);
  const [fiber, setFiber] = useState(0);
  const [exercise, setExercise] = useState(0);
  const [calories, setCalories] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [fat, setFat] = useState(0);
  const [protein, setProtein] = useState(0);
  const [maintainCalorie, setMaintainCalorie] = useState(0);
  const [sendReportStatus, setSendReportStatus] = useState('idle');
  //get value of the input field and store it in an object

  const [lastCoachResponses, setLastCoachResponses] = useState([]);
  const { name, userProduct, level, email, _id } = singleUser;

  const handleChangeResponse = (e) => {
    setNextGoal(e.target.value);
  };
  const handleCalories = (e) => {
    setCalories(e.target.value);
  };
  const handleMaintainCalorie = (e) => {
    setMaintainCalorie(e.target.value);
  };
  const handleProtein = (e) => {
    setProtein(e.target.value);
  };
  const handleCarbs = (e) => {
    setCarbs(e.target.value);
  };
  const handleFat = (e) => {
    setFat(e.target.value);
  };
  const handleFiber = (e) => {
    setFiber(e.target.value);
  };
  const handleSteps = (e) => {
    setSteps(e.target.value);
  };
  const handleExercise = (e) => {
    setExercise(e.target.value);
  };

  const handleGetCoachreports = () => {
    api.get(`/coachresponse/${_id}`).then((res) => {
      console.log(res.data);
      setLastCoachResponses(res.data);
    });
  };

  useEffect(() => {
    handleGetCoachreports();
  }, []);
  const handleUserReport = async () => {
    console.log('handleUserReport');
    const res = await api
      .get(`/report/report/${_id}`)

      .then((res) => {
        console.log(res);
        setUserReport(res.data);
        if (res.data.length === 0) {
          setIsWeeklyEmpty(true);
          console.log(res.data.length);
        }
      })
      .catch((err) => {
        setIsWeeklyError(true);
        setErrorMsg(err.message);
      });
  };

  const handleDismiss = () => {
    setIsMonthlyError(false);
    setIsWeeklyError(false);
  };
  const getMonthlyReport = () => {
    api
      .get(`/report/sizereport/${_id}`)
      .then((res) => {
        setUserMonthlyReport(res.data);
        console.log(res.data);

        // Check if userMonthlyReport is empty
        if (res.data.length === 0) {
          setIsMonthlyEmpty(true);
          console.log(res.data.length);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsMonthlyError(true);
        setErrorMsg(err.message);
      });
  };

  useEffect(() => {
    getMonthlyReport();
    handleUserReport();
  }, []);

  const handleUserUpdate = () => {
    console.log('selectedProduct', selectedProduct);

    api
      .post(`/api/addProductToUser/${_id}`, {
        level: selectedProduct === 'Shape up Academy' ? 0 : 4,
        product: selectedProduct,
      })
      .then((res) => {
        console.log(res.data);
        alert('User updated successfully');
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  const handleUserPaymentType = () => {
    api
      .put(`/api/updateUserPaymentfrompanel/${_id}`, {
        paymentType: paymentType,
      })
      .then((res) => {
        console.log(res.data);
        alert('User updated successfully');
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  return (
    <div
      style={{
        fontFamily: 'Vazirmatn',
      }}>
      {user?.role === 'admin' ? (
        <Container
          dir="rtl"
          style={{
            fontFamily: 'Vazirmatn',
          }}>
          <Row>
            <Col lg={8} xs={12} md={12}>
              <Col lg={12} xs={12} md={12}>
                <Col lg={12} xs={12} md={12}>
                  <Row>
                    {/* <Col>
                      <BeforeAfter _id={_id} name={singleUser?.name} />
                    </Col> */}

                    <Col>
                      <SizeHistory userId={singleUser._id} />
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col lg={12} xs={12} md={12}>
                <CoachReport userId={singleUser._id} name={singleUser.name} />
              </Col>
            </Col>
            <Col lg={4} xs={12} md={12}>
              {/* <Button onClick={handleUserReport}>Weekly Report</Button> */}
              {isWeeklyError && (
                <Alert variant="danger" onDismiss={handleDismiss}>
                  {errorMsg}
                </Alert>
              )}
              {/* {isWeeklyLoading && <Spinner animation="border" />} */}
              {/* {isWeeklyEmpty && (
                <Alert variant="warning" onDismiss={handleDismiss}>
                  گزارشی ارسال نشده است
                </Alert>
              )} */}
              <UserBasicData
                name={singleUser?.name}
                userBasicData={singleUser?.userBasicData}
                userNutritionData={singleUser?.userNutritionData}
                userWorkOutData={singleUser?.userWorkOutData}
                maintainCalorie={singleUser?.maintainCalorie}
              />
              <Button
                onClick={() => handleUserReport()}
                variant="primary"
                style={{
                  fontFamily: 'Vazirmatn',
                }}
                className="mt-3 mb-3">
                مشاهده گزارش هفتگی
              </Button>

              {userReport.length > 0 && (
                <div
                  style={{
                    height: '500px', // adjust this value to your needs
                    overflowY: 'auto',
                    fontFamily: 'Vazirmatn',
                  }}
                  className=" justify-content-between align-items-center border border-1 p-3 rounded
                  ">
                  <h4
                    style={{
                      fontFamily: 'Vazirmatn',
                    }}
                    dir="rtl">
                    آخرین گزارش:
                    {userReport[userReport.length - 1]?.date
                      ? new Intl.DateTimeFormat('en-US', {
                          dateStyle: 'short',
                          timeStyle: 'short',
                        }).format(
                          new Date(userReport[userReport.length - 1].date)
                        )
                      : null}{' '}
                  </h4>
                  <br />
                  <br></br>
                  <div dir="rtl">
                    <span
                      style={{
                        fontFamily: 'Vazirmatn',
                      }}>
                      ورزش قدرتی:
                      {userReport[userReport.length - 1]?.daysOfStrength} روز در
                      هفته
                      <br />
                      کاردیو:
                      {userReport[userReport.length - 1]?.daysOfCardio} روز در
                      هفته
                      <br />
                      میانگین وزن این هفته :
                      {userReport[userReport.length - 1]?.aveWeight} کیلوگرم
                    </span>
                  </div>
                  <br />
                  <span dir="rtl">
                    <span
                      dir="rtl"
                      style={{
                        fontSize: 14,

                        fontFamily: 'Vazirmatn',

                        textAlign: 'left',
                      }}>
                      {userReport[userReport.length - 1]?.description}
                    </span>

                    <Carousel interval={null} slide={false}>
                      {userReport[userReport.length - 1]?.data?.map(
                        (item, i) => (
                          <Carousel.Item key={i}>
                            <Image src={item.url} alt="report" thumbnail />
                          </Carousel.Item>
                        )
                      )}
                    </Carousel>
                  </span>
                </div>
              )}
              <Accordion defaultActiveKey={userReport?.length > 0 ? '0' : ''}>
                {userReport?.map(
                  (item, index) =>
                    item.archive !== true && (
                      <div key={index}>
                        <Accordion.Item
                          style={{
                            fontFamily: 'Vazirmatn',
                          }}
                          eventKey={index}>
                          <Accordion.Header>
                            تاریخ گزارش:
                            {item?.date
                              ? new Intl.DateTimeFormat('en-US', {
                                  dateStyle: 'short',
                                  timeStyle: 'short',
                                }).format(new Date(item.date))
                              : null}{' '}
                            <br></br>
                            <br></br>
                          </Accordion.Header>
                          <Accordion.Body dir="rtl">
                            <span
                              style={{
                                fontSize: 14,

                                textAlign: 'left',
                              }}>
                              {item?.description}
                            </span>
                            <br></br>
                            <div dir="rtl">
                              <span>
                                ورزش قدرتی:
                                {
                                  userReport[userReport.length - 1]
                                    ?.daysOfStrength
                                }{' '}
                                روز در هفته
                                <br />
                                کاردیو:
                                {
                                  userReport[userReport.length - 1]
                                    ?.daysOfCardio
                                }{' '}
                                روز در هفته
                              </span>
                            </div>
                            {/* <Button
                                      className="float-end"
                                      variant="warning"
                                      onClick={() =>
                                        handleDeleteWeeklyReport(item._id)
                                      }>
                                      Remove
                                    </Button> */}
                            <Carousel interval={null} slide={false}>
                              {item?.data?.map((item, i) => (
                                <Carousel.Item key={index}>
                                  <Image
                                    key={i}
                                    src={item}
                                    alt="report"
                                    thumbnail
                                  />
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    )
                )}
              </Accordion>
            </Col>
          </Row>

          <UpdateUser
            email={email}
            level={level}
            userProduct={userProduct}
            setPaymentType={setPaymentType}
            handleUserPaymentType={handleUserPaymentType}
            setSelectedProduct={setSelectedProduct}
            handleUserUpdate={handleUserUpdate}
          />
          {modalShow && (
            <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Modal heading
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img style={{ width: '100%', height: 'auto' }} alt="dd" />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={sendReportStatus === 'loading'}
                  onClick={() => setModalShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Container>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default SingleUser;
