import { useEffect, useState } from 'react';
import api from '../../../apis/api';
import Chart from 'react-google-charts';
import { Col, Form, Row } from 'react-bootstrap';
import '../../../index.css';
const SizeHistory = ({ userId }) => {
  const [reports, setReports] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState(['وزن']); // Default selected size

  const getReports = async () => {
    if (userId) {
      try {
        const res = await api.get(`/report/sizereport/${userId}`);
        setReports(res.data); // Store all reports without filtering
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    getReports();
  }, [userId]);

  const sizeNames = [
    'دور سینه',
    'بازوی چپ',
    'بازوی راست',
    'دور شکم',
    'دور کمر',
    'دور باسن',
    'ران چپ',
    'ران راست',
    'وزن',
  ];

  const handleSizeChange = (sizeName) => {
    setSelectedSizes((prevSizes) =>
      prevSizes.includes(sizeName)
        ? prevSizes.filter((size) => size !== sizeName)
        : [...prevSizes, sizeName]
    );
  };

  const chartData = [
    ['Date', ...selectedSizes], // Dynamic headers based on selected sizes
    ...reports.map((report) => [
      new Date(report.date),
      ...selectedSizes.map((sizeName) => {
        const size = report.sizes.find((s) => s.name === sizeName);
        return size ? size.value : null;
      }),
    ]),
  ];

  return (
    <div
      style={{
        fontFamily: 'Vazirmatn',
      }}>
      {/* Checkbox controls */}
      <Row>
        <Col>
          <Form>
            {sizeNames.map((sizeName) => (
              <Form.Check
                key={sizeName}
                type="checkbox"
                label={sizeName}
                checked={selectedSizes.includes(sizeName)}
                onChange={() => handleSizeChange(sizeName)}
              />
            ))}
          </Form>
        </Col>
        <Col>
          {/* Chart */}
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={chartData}
            options={{
              title: 'نمودار وزن',
              curveType: 'function',
              legend: { position: 'bottom' },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SizeHistory;
