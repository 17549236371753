import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { getAllorders } from '../../apis/orders';

function OrderIndex() {
  const [orderList, setOrderList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleGetOrders = async () => {
    const res = await getAllorders().then((res) => {
      console.log(res.data);
      if (res) {
        setOrderList(res.data);
      } else {
        console.log('Error');
      }
    });
  };

  useEffect(() => {
    handleGetOrders();
  }, []);

  return (
    <Container dir="rtl" style={{ fontFamily: 'Vazirmatn' }}>
      {orderList && orderList.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>آدرس ایمیل</th>
              <th> شماره سفارش</th>
              <th>نام محصول</th>
              <th>تاریخ سفارش </th>
              <th>واحد پولی</th>
              <th>وضعیت سفارش</th>
              <th>مبلغ کل </th>
              {/* <th>Order Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {orderList?.map((order, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <a href={`/user/query?email=${order.userEmail}`}>
                      {order.userEmail}
                    </a>
                  </td>
                  <td>{order.orderNumber}</td>
                  <td>{order.orderItems}</td>
                  <td>{order.orderDate}</td>
                  <th>{order.currency}</th>
                  <td>{order.orderStatus}</td>
                  <td>{order.orderTotal}</td>
                  {/* <td style={{ display: 'flex', gap: '10px' }}>
                    <Button>View</Button>
                    <Button>Edit</Button>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Container>
  );
}

export default OrderIndex;
