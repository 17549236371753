import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import api from '../../apis/api';
import uploadImage from '../../apis/uploadImage';
import { TagsInput } from 'react-tag-input-component';
import AddNewDay from './Modals';
import { getAllExcercises, getsinglePlan } from '../../apis/UpdateWorkoutPlan';
import { Link, useParams } from 'react-router-dom';
import SearchComponent from '../../components/SearchComponent';
import { IconDelete } from '../../img/Icons';

function SinglePlan() {
  const { id: planId } = useParams();
  const [plan, setPlan] = useState({});
  console.log(plan);
  const [planName, setPlanName] = useState('');
  const [target, setTarget] = useState('');
  const [level, setLevel] = useState('');
  const [duration, setDuration] = useState('');
  const [DaysPerWeek, setDaysPerWeek] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [totalSessions, setTotalSessions] = useState(0);
  const [imageUrls, setImageUrls] = useState({});
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState(['Lose Fat', 'Advanced']);
  const [key, setKey] = useState('home');
  const [addNewDay, setAddNewDay] = useState(false);
  const [status, setStatus] = useState('idle');
  const [allworkouts, setAllworkouts] = useState([]);
  const [show, setShow] = useState(false);
  const handleGetAllExcercise = async () => {
    try {
      const res = await getAllExcercises();
      console.log(res);
      setAllworkouts(res);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handlePlanData = () => {
    setTarget(plan.goal || '');
    setLevel(plan.level || '');
    setDuration(plan.duration || '');
    setDaysPerWeek(plan.DaysPerWeek || '');
    setDescription(plan.description || '');
    setLocation(plan.location || '');
    setTotalSessions(plan.totalSessions || 0);
    setImageUrls({ urls: plan.image || '' });
    setTags(plan.tags || []);
    setPlanName(plan.title || '');
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];

    const onProgress = (percent) => {
      console.log(`${percent}% uploaded`);
    };

    try {
      setStatus('loading');
      const result = await uploadImage(file, onProgress);
      console.log(result.imageUrls);
      setImageUrls(result.imageUrls);
      setStatus('idle');
    } catch (error) {
      console.error(error);
      setStatus('error');
    }
  };

  const handleSinglePlan = async () => {
    setStatus('loading');
    try {
      const res = await getsinglePlan(planId);
      console.log(res[0]);
      if (res) {
        setPlan(res[0]);
        handlePlanData();
      } else {
        console.log('no data found');
        setStatus('error');
      }
    } catch (error) {
      console.log(error.message);
      setStatus('error');
    }
  };

  useEffect(() => {
    handleSinglePlan();
  }, []);

  useEffect(() => {
    handleGetAllExcercise();
  }, []);

  const userId = '64e26209c0bab3ae4f0825c';
  const handleUpdatePlan = async () => {
    try {
      api
        .put(`/newplan/prebuild/${planId}`, {
          name: planName,
          userId: userId,
          goal: target,
          level: level,
          duration: duration,
          DaysPerWeek: DaysPerWeek,
          image: imageUrls.urls,
          description: description,
          location: location,
          totalSessions: totalSessions,
          tags: tags,
          creator: 'Fitlinez AI',
        })
        .then((res) => {
          console.log(res.data);
          //refresh the page
          window.location.reload();
          // setShowTable(true);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginTop: '30px',
      }}>
      <Row>
        <Col xs={12} md={3}>
          <Image
            style={{
              height: '80%',
            }}
            src={plan.image || 'https://via.placeholder.com/150'}
            thumbnail
          />
        </Col>
        <Col xs={12} md={6}>
          <Form>
            <Form.Group className="mb-3 d-flex justify-content-between gap-3">
              <Form.Control
                type="text"
                defaultValue={planName}
                placeholder={`${plan.name}` || `Plan Name`}
                onChange={(e) => setPlanName(e.target.value)}
              />
              <Form.Control
                type="file"
                placeholder="image"
                onChange={handleImage}
              />
            </Form.Group>
            <Form.Group className=" mb-3 d-flex justify-content-between gap-3">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setTarget(e.target.value);
                }}>
                {/* default value  */}

                <option value="">{plan.goal || 'Select Goal'}</option>

                <option value="Lose Fat">Lose Fat</option>
                <option value="Gain Muscle">Gain muscle</option>
                <option value="Maintain Weight">Maintain Weight</option>
                <option value="Stay Fit">Stay Fit</option>
              </Form.Select>

              <Form.Select
                defaultValue={level}
                aria-label="Default select example"
                onChange={(e) => {
                  setLevel(e.target.value);
                }}>
                <option value="">{plan.level || 'Select Level'}</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
              </Form.Select>

              <Form.Select
                defaultValue={location}
                aria-label="Default select example"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}>
                <option value="">{plan.location}</option>

                <option value="Home">Home</option>
                <option value="Gym">Gym</option>
                <option value="both">home & Gym</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <TagsInput
                value={tags}
                onChange={setTags}
                name="tags"
                placeHolder={
                  plan.tags?.length > 0 ? plan.tags : 'Add tags for your plan'
                }
              />
            </Form.Group>
            <Form.Group className=" mb-3 d-flex justify-content-between gap-3">
              <Form.Control
                defaultValue={duration}
                type="number"
                placeholder={plan.duration || 'Duration of the plan in weeks'}
                onChange={(e) => setDuration(e.target.value)}
              />
              <Form.Control
                defaultValue={DaysPerWeek}
                type="number"
                placeholder={plan.DaysPerWeek || 'Days Per Week'}
                onChange={(e) => setDaysPerWeek(e.target.value)}
              />
              <Form.Control
                defaultValue={totalSessions}
                type="number"
                placeholder={plan.totalSessions || 'Total Sessions in the plan'}
                onChange={(e) => setTotalSessions(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Button
                variant="success"
                //onClick={handleCreatePlan}
                disabled={loading}>
                {loading ? 'Uploading...' : 'Add new Plan'}
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Button variant="primary" onClick={() => setAddNewDay(!addNewDay)}>
        + Add New day{' '}
      </Button>
      {addNewDay && (
        <AddNewDay planId={planId} show={addNewDay} setShow={setAddNewDay} />
      )}
      <Row>
        {plan?.data?.map((item, index) => {
          return (
            <Col
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                // padding: '10px',
                border: '0.5px solid lightgrey',
                borderRadius: '5px',
                margin: '10px',
              }}>
              {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <SearchComponent items={allworkouts} />
              </div> */}
              <Link
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  // gap: '10px',
                  alignItems: 'center',
                  textDecoration: 'none',
                  backgroundColor: '#f8f9fa',
                }}
                to={`/EditDay/${planName}-${item.title}`}
                state={{
                  planId: planId,
                  item: item.data,
                  items: allworkouts,
                  day: item.title,
                }}>
                <span>
                  <span>{item.day}</span>
                  <h4>{item.title}</h4>
                </span>
              </Link>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  // gap: '10px',
                  alignItems: 'center',
                  textDecoration: 'none',
                  backgroundColor: '#f8f9fa',
                }}>
                {item.data?.length} exercises{' '}
              </span>
              {item?.data?.map((exercise, i) => {
                return (
                  <p
                    key={i}
                    style={{
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                      cursor: 'pointer',
                      //padding: '5px',
                      width: '150px',
                      // height: '150px',
                      //    border: '0.5px solid grey',
                      borderRadius: '5px',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        // flexDirection: 'column',
                        gap: '5px',
                      }}>
                      <span
                        style={{
                          fontSize: '12px',
                        }}>
                        {exercise.name}
                      </span>
                      <span>
                        {/* <img
                          src={exercise.gifUrl}
                          style={{
                            width: '100px',
                            height: '100px',
                          }}
                        /> */}
                      </span>
                    </div>
                  </p>
                );
              })}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default SinglePlan;
