import React from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Row,
} from 'react-bootstrap';

function UpdateUser({
  email,
  level,
  userProduct,
  setPaymentType,
  handleUserPaymentType,
  setSelectedProduct,
  handleUserUpdate,
}) {
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>User Data</Card.Header>
          <Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>{email}</ListGroup.Item>
              <ListGroup.Item>
                User Level:
                {level === 4 ? (
                  <Badge bg="primary">Golden</Badge>
                ) : level === 1 ? (
                  <Badge bg="secondary">Silver</Badge>
                ) : (
                  'Normal'
                )}
              </ListGroup.Item>

              <ListGroup.Item>
                Products:
                {userProduct ? (
                  userProduct.map((p) => (
                    <span className="badge bg-primary">{p.name}</span>
                  ))
                ) : (
                  <span>not provided</span>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Header>Edit</Card.Header>
          <Card.Body>
            <Row className="mt-3 pb-2">
              <Col>Update User Level:</Col>
              <Col>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setPaymentType(e.target.value)}>
                  <option value="">Select </option>
                  <option value="rial">Rial</option>
                  <option value="euro">Euro</option>
                  <option value="partial">Partial</option>
                </Form.Select>
              </Col>
              <Col>
                <Button
                  onClick={handleUserPaymentType}
                  className="mt-9"
                  variant="primary">
                  Update
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>Update Products:</Col>
              <Col>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedProduct(e.target.value);
                  }}>
                  <option value="">Select </option>
                  <option value="Shape up 5">Shape up 5</option>
                  <option value="Shape up 6">Shape up 6</option>
                  <option value="Shape up pro 4">Shape up pro 4</option>
                  <option value="Shape up pro 5">Shape up pro 5</option>
                  <option value="Shape up Academy">Shape up Academy</option>
                  <option value="Fitlinez app 1-month">
                    Fitlinez app 1-month
                  </option>
                  <option value="Fitlinez app 3-month">
                    Fitlinez app 3-month
                  </option>
                  <option value="Fitlinez app 12-month">
                    Fitlinez app 12-month
                  </option>
                  <option value="Nutrition coaching">Nutrition coaching</option>
                </Form.Select>
              </Col>
              <Col>
                <Button
                  onClick={handleUserUpdate}
                  className=""
                  variant="primary">
                  Update
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default UpdateUser;
