import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import UserContext from '../../context/usercontext';
import api from '../../apis/api';

const Booking = () => {
  const { user, setUser } = useContext(UserContext);
  const [booking, setBooking] = useState([]);
  console.log(booking);
  useEffect(() => {
    fetch('https://jobitta.com/form')
      .then((res) => res.json())
      .then((data) => setBooking(data));
  }, []);

  const sendEmail = async (name, email) => {
    const result = await api({
      method: 'POST',
      url: 'https://jobitta.com/form/sendemail',
      data: {
        name: name,
        email: email,
      },
    });
    alert(result.data);
  };

  return (
    <>
      {user?.role === 'admin' ? (
        <div>
          <h1>Booking</h1>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Country</th>
                <th>Instagram</th>
                <th>Email</th>
                <th>Product</th>
                <th>Date</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {booking.map((booking, index) => {
                //get sum of all bookings

                return (
                  <tr key={booking.id}>
                    <td>{index + 1}</td>
                    <td>{booking.name}</td>
                    <td>{booking.country}</td>
                    <td>{booking.instagram}</td>
                    <td>{booking.email}</td>
                    <td>{booking.product}</td>
                    <td>{booking.date}</td>
                    <td>{booking.age}</td>
                    {/* <td>
                      <button
                        onClick={() => sendEmail(booking.name, booking.email)}
                        className="btn btn-success">
                        Send invitation Email
                      </button>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default Booking;
