import api from './api';

const handleAddDays = ({
  daynName,
  selectedItems,
  planId,
  planName,
  title,
  listId,
}) => {
  try {
    localStorage.setItem(
      `plan${[daynName]}`,
      JSON.stringify({ day: daynName, data: selectedItems })
    );
    api
      .put(`/newplan/prebuild/${planId}`, {
        name: planName,
        day: daynName,
        title: title,
        data: listId,
      })
      .then((res) => {
        console.log('res', res);
        alert('added');
        window.location.reload();
      });
    // alert('added');
    //refresh the page
    //window.location.reload();
  } catch (error) {
    console.log(error.message);
  }
};

const getsinglePlan = async (id) => {
  try {
    const res = await api.get(`/newplan/prebuild/${id}`);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

const getAllExcercises = async () => {
  try {
    const response = await api.get('/newPlan');

    const filteredData = response.data.filter(
      (item) => item.active === false || item.active === true
    );
    return filteredData;
  } catch (error) {
    console.log(error.message);
  }
};

const deleteSingleItem = async (planId, itemId, day) => {
  try {
    const res = await api.put(`/newPlan/removesingleItem/`, {
      itemId: itemId,
      planId: planId,
      day: day,
    });
    console.log('result of deleting exercise from plan', res.data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};
const addSingleItem = async (planId, itemId, day) => {
  console.log('planId', planId);
  console.log('itemId', itemId);
  console.log('day', day);
  try {
    const res = await api.put(`/newPlan/addsingleItem/`, {
      itemId: itemId,
      planId: planId,
      day: day,
    });
    console.log('result of adding exercise to plan', res.data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

const addNewDayToPlan = async (planId, dayName, dayIndex) => {
  console.log('planId', planId);
  console.log('dayName', dayName);
  console.log('dayIndex', dayIndex);
  try {
    const res = await api.put(`/newPlan/addDaytoPlan/`, {
      planId: planId,
      day: dayName,
      dayIndex: dayIndex,
    });
    console.log('result of adding new day to plan', res.data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

const handleUpdateDescription = async (planId, itemId, day, description) => {
  console.log('planId', planId);
  console.log('itemId', itemId);
  console.log('day', day);
  console.log('description', description);

  try {
    const res = await api.put(`/newPlan/editDescription/`, {
      planId: planId,
      itemId: itemId,
      day: day,
      description: description,
    });
    console.log('result of updating description', res.data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

const getsingleDay = async (planId, day) => {
  console.log('planId', planId);
  console.log('day', day);
  try {
    const res = await api.post(`/newplan/singleDay`, {
      planId: planId,
      day: day,
    });
    console.log('result of getting single day', res.data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
};

export {
  handleAddDays,
  getsinglePlan,
  getAllExcercises,
  deleteSingleItem,
  addSingleItem,
  addNewDayToPlan,
  handleUpdateDescription,
  getsingleDay,
};
