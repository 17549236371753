import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IconDelete, IconEdit } from '../../img/Icons';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import SearchComponent from '../../components/SearchComponent';
import {
  deleteSingleItem,
  getsingleDay,
  handleUpdateDescription,
} from '../../apis/UpdateWorkoutPlan';
import MyModal from '../../components/MyModal';

function EditDay() {
  const { state } = useLocation();
  const [item, setItem] = useState([]);
  console.log('item', item);
  console.log('state', state);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [descriptions, setDescriptions] = useState({});

  const handleDeleteItem = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const getItems = async () => {
    try {
      const res = await getsingleDay(state.planId, state.day);
      console.log('result of getting single day', res.data);
      setItem(res);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const handleConfirmDelete = async () => {
    try {
      await deleteSingleItem(state.planId, selectedId, state.day);
      setShowModal(false);
      getItems();
      // Optionally, refresh the items or update state here
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const updateDescription = async (id) => {
    try {
      await handleUpdateDescription(
        state.planId,
        id,
        state.day,
        descriptions[id] || ''
      );
      alert('Description updated successfully');
      getItems();
      // Optionally, show a success message or update state here
    } catch (error) {
      console.error('Error updating description:', error);
    }
  };

  const handleDescriptionChange = (id, value) => {
    setDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [id]: value,
    }));
  };

  const sortedData = [...item].sort((a, b) => {
    if (a.type === 'warmup' && b.type !== 'warmup') {
      return -1; // "warmup" appears before other types
    } else if (a.type !== 'warmup' && b.type === 'warmup') {
      return 1; // Other types appear after "warmup"
    } else if (a.type === 'cooldown' && b.type !== 'cooldown') {
      return 1; // Other types appear before "cooldown"
    } else if (a.type !== 'cooldown' && b.type === 'cooldown') {
      return -1; // "cooldown" appears after other types
    } else {
      return a.order - b.order;
    }
  });

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Row className="w-100">
        <Col className="d-flex flex-column align-items-center">
          <div style={{ marginBottom: '20px', width: '100%', zIndex: 100 }}>
            <SearchComponent
              items={state.items}
              planId={state.planId}
              day={state.day}
            />
          </div>
          {sortedData?.map((exercise, i) => (
            <Card
              key={i}
              style={{
                width: '80%',
                margin: '10px',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
              }}>
              <Card.Img
                variant="top"
                style={{
                  width: '30%',
                  height: '20vw',
                  objectFit: 'cover',
                }}
                src={exercise.gifUrl}
              />
              <Card.Title>{exercise.name}</Card.Title>
              <div>{exercise.bodyPart}</div>
              <div>{exercise.level}</div>
              <div>{exercise.equipment}</div>
              <div>{exercise.description}</div>
              <Card.Body
                className="d-flex justify-content-between"
                style={{ width: '100%' }}>
                <Form.Control
                  onChange={(e) =>
                    handleDescriptionChange(exercise._id, e.target.value)
                  }
                  type="text"
                  placeholder="Description"
                  value={descriptions[exercise._id] || ''}
                  style={{
                    width: '60%',
                    marginRight: '10px',
                    fontFamily: 'vazirmatn',
                  }}
                />
                <Button
                  onClick={() => updateDescription(exercise._id)}
                  variant="primary"
                  className="me-2">
                  Update Description
                </Button>
                <Button disabled variant="success" className="me-2">
                  <IconEdit /> Edit Exercise
                </Button>
                <Button
                  onClick={() => handleDeleteItem(exercise._id)}
                  variant="danger">
                  <IconDelete /> Delete
                </Button>
              </Card.Body>
            </Card>
          ))}
        </Col>
        <MyModal
          title="Delete Item"
          body="Are you sure you want to delete this item?"
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleDelete={handleConfirmDelete}
        />
      </Row>
    </Container>
  );
}

export default EditDay;
