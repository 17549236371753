import api from './api';

const getAllorders = async () => {
  try {
    const response = await api.get('/orders/all');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export { getAllorders };
