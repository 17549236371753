import React from 'react';
import { Accordion, Card, Col, ListGroup, Row } from 'react-bootstrap';
import '../../../index.css';
function UserBasicData({
  userBasicData,
  userWorkOutData,
  userNutritionData,
  name,
  maintainCalorie,
}) {
  console.log('userBasicData', userBasicData);
  return (
    <Row
      style={{
        fontFamily: 'Vazirmatn',
      }}
      dir="rtl"
      className=" justify-content-between align-items-center border border-1 p-3 rounded">
      <Col>
        <ListGroup.Item>
          <h3> {name}</h3>
          <hr></hr>
        </ListGroup.Item>
        <ListGroup.Item>
          <p> سن: {userBasicData?.age}</p>
        </ListGroup.Item>
        <hr></hr>
        <ListGroup.Item>
          <p> وزن اولیه : {userBasicData?.weight}</p>
        </ListGroup.Item>
        <hr></hr>
        <ListGroup.Item>
          <p> کالری تثبیت : {maintainCalorie ? maintainCalorie : ''}</p>
        </ListGroup.Item>
        <hr></hr>
        <ListGroup.Item>
          <p> قد : {userBasicData?.height}</p>
          <hr></hr>
        </ListGroup.Item>

        {userBasicData?.country && (
          <ListGroup.Item>
            <p>کشور محل سکونت: {userBasicData?.country}</p>
            <hr></hr>
          </ListGroup.Item>
        )}
        {userBasicData?.illnessName && (
          <ListGroup.Item>
            <p> {userBasicData?.illnessName}</p>
            <hr></hr>
          </ListGroup.Item>
        )}

        {userBasicData?.painName && (
          <ListGroup.Item>
            <p>{userBasicData?.painName}</p>
            <hr></hr>
          </ListGroup.Item>
        )}

        {userWorkOutData?.reasonToNotWorkout && (
          <ListGroup.Item>
            <p>{userWorkOutData?.reasonToNotWorkout}</p>
            <hr></hr>
          </ListGroup.Item>
        )}

        {/* {userWorkOutData?.workoutPlace && (
            <ListGroup.Item>
              <p>{userWorkOutData?.workoutPlace}</p>
            </ListGroup.Item>
          )} */}
        {userWorkOutData?.dayOfStrengthTraining && (
          <ListGroup.Item>
            <p>
              {userWorkOutData?.dayOfStrengthTraining} روز ورزش قدرتی در هفته
            </p>
            <hr></hr>
          </ListGroup.Item>
        )}

        {userWorkOutData?.excersiceType && (
          <ListGroup.Item>
            <p>نوع ورزش: {userWorkOutData?.excersiceType} </p>
            <hr></hr>
          </ListGroup.Item>
        )}
        {/* {userWorkOutData?.workOutExperience && (
            <ListGroup.Item>
              <p>{userWorkOutData?.workOutExperience}</p>
            </ListGroup.Item>
          )} */}

        {userNutritionData?.dietName && (
          <ListGroup.Item>
            <p>رژیم: {userNutritionData?.dietName}</p>
            <hr></hr>
          </ListGroup.Item>
        )}
        {userNutritionData?.lastdiet && (
          <ListGroup.Item>
            <p>آخرین رژیم : {userNutritionData?.lastdiet}</p>
          </ListGroup.Item>
        )}
      </Col>
    </Row>
  );
}

export default UserBasicData;
