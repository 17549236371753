import api from './api';

const uploadImage = async (file, onProgress) => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(
        'https://backend.fitlinez.com/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            onProgress(percent);
          },
        }
      );

      resolve({
        percent: 100,
        imageUrls: response.data,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export default uploadImage;
