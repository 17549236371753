import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import api from '../apis/api';

const SupportForm = (props) => {
  const [solved, setSolved] = useState(false);
  const [reply, setReply] = useState('');
  const { ticket } = props;

  const handleSubmit = (e) => {
    console.log(solved, reply);
    e.preventDefault();
    api
      .put(`https://jobitta.com/support/${ticket._id}`, {
        reply,
        solved,
      })
      .then((res) => {
        alert('Reply sent');
        window.location.reload();
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e);
      }}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Reply</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Solved?</InputGroup.Text>
          <InputGroup.Checkbox
            onChange={(e) => setSolved(e.target.checked)}
            aria-label="Checkbox for following text input"
          />
          <Form.Control
            onChange={(e) => setReply(e.target.value)}
            as="textarea"
            required
            aria-label="Text input with checkbox"
          />
        </InputGroup>
      </Form.Group>

      <div className="d-grid gap-2">
        <Button type="submit" variant="primary" size="lg">
          Reply
        </Button>
      </div>
    </Form>
  );
};

export default SupportForm;
