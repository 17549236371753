import React, { useEffect, useState } from 'react';
import {
  deleteComment,
  getComments,
  sendReplyToComment,
} from '../../../apis/getData';
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Form,
  ListGroup,
  Row,
} from 'react-bootstrap';

function CommentsList() {
  const [comments, setComments] = useState([]);
  const [status, setStatus] = useState('idle');
  const [showReply, setShowReply] = useState(null); // change this to null
  const [response, setResponse] = useState('');

  const handleAllComments = () => {
    setStatus('loading');
    getComments()
      .then((data) => {
        setComments(data.comments);
        setStatus('idle');
      })
      .catch((error) => {
        console.error(error);
        setStatus('error');
      });
  };

  const handleDeleteComment = (id) => {
    deleteComment(id)
      .then(() => {
        handleAllComments();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleShowReplyById = (id) => {
    setShowReply(id);
    handleAllComments();
  };

  useEffect(() => {
    handleAllComments();
  }, []);

  const handleReply = (id) => {
    console.log('id', id);
    console.log('response', response);
    console.log('adminId', 'admin');
    console.log('adminName', 'Admin');
    sendReplyToComment(id, response, 'adminId', 'adminName');
    handleAllComments();
  };
  return (
    <div style={{ fontFamily: 'Vazirmatn' }} className="p-4 " dir="rtl">
      <ListGroup>
        {comments
          ?.sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((comment) => (
            // Render each comment here

            <ListGroup.Item key={comment._id}>
              {comment?.isDeleted && <Badge bg="danger">حذف شده</Badge>}
              <p className="font-semibold">
                {comment.userName} در {comment.productId}:{' '}
              </p>

              <Button
                variant="secondary"
                size="sm"
                onClick={() => handleShowReplyById(comment._id)}>
                {' '}
                پاسخ
              </Button>
              <Button
                variant="warning"
                size="sm"
                onClick={() => handleDeleteComment(comment._id)}>
                {' '}
                حذف
              </Button>

              <p className="text-lg mb-2 mt-4">{comment.comment}</p>
              {showReply === comment._id && ( // check if this comment's id matches showReply
                <Form
                  className=" mt-4 gap-4 grid grid-cols-3 w-1/2 
                "
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleReply(comment._id);
                  }}>
                  <Row>
                    <Col>
                      <Form.Control
                        type="textarea"
                        id="inputPassword5"
                        aria-describedby=""
                        onChange={(e) => setResponse(e.target.value)}
                      />{' '}
                    </Col>

                    <Col>
                      {' '}
                      <Button
                        type="submit"
                        className="bg-blue-500 text-white p-2">
                        ارسال
                      </Button>{' '}
                    </Col>
                  </Row>
                </Form>
              )}
              {comment.responses && comment.responses.length > 0 && (
                <ListGroup>
                  {comment.responses.map((response) => (
                    <ListGroup.Item key={response._id}>
                      <p className="font-semibold">{response.adminName}:</p>
                      <p>{response.response}</p>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
}

export default CommentsList;
