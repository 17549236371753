import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { addNewDayToPlan } from '../../apis/UpdateWorkoutPlan';

const AddNewDay = ({ show, planId, setShow }) => {
  const [dayName, setDayName] = useState('');
  const [dayIndex, setDayIndex] = useState('');
  const weekNames = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const handleAddNewDay = async () => {
    await addNewDayToPlan(planId, dayName, dayIndex);
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Day</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Control
          onChange={(e) => setDayName(e.target.value)}
          type="text"
          placeholder="Day name"
          value={dayName}
        />
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => setDayIndex(e.target.value)}
          value={dayIndex}>
          <option value="">Select Day</option>
          {weekNames.map((day, index) => (
            <option key={index} value={day}>
              {day}
            </option>
          ))}
        </Form.Select>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => setShow(false)} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleAddNewDay} variant="primary">
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewDay;
