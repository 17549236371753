import React, { useState } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import api from '../../apis/api';

const BlogPostComponent = () => {
  const [blogPost, setBlogPost] = useState({
    author: '',
    title: '',
    description: '',
    lead: '',
    body: '',
    tags: [],
    category: '',
    image: '',
  });

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setBlogPost({ ...blogPost, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (content, editor) => {
    setBlogPost({ ...blogPost, body: content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/blog', blogPost);
      console.log(response.data);
      // Handle success (e.g., show message, redirect)
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Container xs={12} md={8} lg={6} className="mt-5">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col></Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="text"
                name="title"
                placeholder="title"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                name="description"
                type="text"
                placeholder="description"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Editor
            name="body"
            apiKey="y7gnmtbsaxnjbgh3405ioqbdm24eit5f0ovek49w8yvq5r9q"
            init={{
              branding: false,
              height: 400,
              menubar: true,
              plugins:
                'print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern',
              toolbar:
                'formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
              image_advtab: true,
            }}
            onEditorChange={handleEditorChange} // Updated line
          />
        </Row>
        <Row>
          <Col>
            <Button
              //disabled={isSending}
              className="mt-3"
              variant="success"
              type="submit">
              Send
            </Button>{' '}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default BlogPostComponent;
