import React, { useState, useEffect } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../../apis/api';
import '../../index.css';
import heic2any from 'heic2any';

const UserPhotoGallery = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [images, setImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState(false);

  // Fetch users data for the search
  useEffect(() => {
    api
      .get('/getUsers') // Replace with your API endpoint to get all users
      .then((response) => setUsers(response.data))
      .catch((error) => console.error('Error fetching users:', error));
  }, []);

  // Handle the user selection
  const handleOnSelect = (item) => {
    const userId = item._id; // Get the user's ID

    // Fetch user's images by ID
    setLoadingImages(true);
    api
      .get(`/report/imagereport/${userId}`)
      .then(async (response) => {
        const imagesWithConvertedUrls = await Promise.all(
          response.data.map(async (imageSet) => {
            const convertedData = await Promise.all(
              imageSet.data.map(async (imgUrl) => {
                if (imgUrl.endsWith('.heic')) {
                  try {
                    const response = await fetch(imgUrl);
                    if (!response.ok) throw new Error('Failed to fetch image');
                    const blob = await response.blob();
                    const convertedBlob = await heic2any({
                      blob,
                      toType: 'image/jpeg',
                    });
                    return URL.createObjectURL(convertedBlob);
                  } catch (error) {
                    console.error('Error converting HEIC image:', error);
                    return imgUrl; // Fallback to original URL if conversion fails
                  }
                }
                return imgUrl;
              })
            );
            return { ...imageSet, data: convertedData };
          })
        );
        setSelectedUser(item);
        setImages(imagesWithConvertedUrls); // Set images to be displayed
        setLoadingImages(false); // Done loading
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
        setLoadingImages(false); // Done loading
      });
  };

  return (
    <div
      dir="rtl"
      className="container mt-5"
      style={{
        fontFamily: 'Vazirmatn',
      }}>
      <h1>تصاویر شاگردان</h1>

      {/* Search bar */}
      <div className="mb-4">
        <ReactSearchAutocomplete
          items={users}
          fuseOptions={{ keys: ['name'] }} // Search by user name
          resultStringKeyName="name"
          onSelect={handleOnSelect}
          placeholder="جستجوی شاگردان بر اساس نام"
        />
      </div>

      {/* Display selected user's images */}
      {selectedUser && (
        <div>
          <h2> تصاویر {selectedUser.name}</h2>
          {loadingImages ? (
            <p>Loading images...</p>
          ) : images.length > 0 ? (
            images.map((imageSet) => (
              <div key={imageSet._id} className="mb-5">
                <h5>تاریخ: {new Date(imageSet.date).toLocaleDateString()}</h5>
                <div className="row">
                  {imageSet.data.map((imgUrl, index) => (
                    <div key={index} className="col-md-3 mb-2">
                      <img src={imgUrl} alt="User" className="thumbnail" />
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p>No images available for this user.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default UserPhotoGallery;
