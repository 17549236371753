const equipment = [
  'None',
  'Dumbbells',
  'Barbells',
  'Weight Plates',
  'Kettlebells',
  'Resistance Bands',
  'Pull-Up Bar',
  'Bench',
  'Squat Rack',
  'Cable Machine',
  'Treadmill',
  'Elliptical Machine',
  'Stationary Bike',
  'Rowing Machine',
  'Jump Rope',
  'Medicine Ball',
  'Yoga Mat',
  'Foam Roller',
  'TRX Suspension Trainer',
  'Battle Ropes',
  'Gymnastic Rings',
  'Sandbags',
  'Punching Bag',
  'Stability Ball',
  'Step Platform',
  'Box for Box Jumps',
  'Parallettes',
  'Ab Wheel',
  'Weightlifting Belt',
  'Weighted Vest',
  'Agility Ladder',
  'Cones',
  'Smith Machine',
  'Leg Press Machine',
  'Lat Pulldown Machine',
  'Dip Station',
  'Plyometric Box',
  'Sled Push/Pull',
  'Speed Rope',
  'Power Tower',
  'GHD (Glute-Ham Developer)',
  'Trap Bar',
  'EZ Curl Bar',
  'Seated Leg Curl Machine',
];

export default equipment;
