import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import api from '../../apis/api';
import { Link } from 'react-router-dom';

function EditPlan() {
  const [myPlans, setMyPlans] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [plan, setPlan] = useState({});
  const [allworkouts, setAllworkouts] = useState([]);
  const [status, setStatus] = useState('idle');
  const userId = '64e26209c0bab3ae4f0825c0';

  const getMyPlans = async () => {
    setStatus('loading');
    try {
      const res = await api.get(`/newplan/myprebuild`, {
        params: {
          userId: '64e26209c0bab3ae4f0825c0',
        },
      });
      // console.log(res.data);
      setMyPlans(res.data);
      setStatus('resolved');
    } catch (err) {
      console.log(err);
      setStatus('error');
    }
  };
  useEffect(() => {
    getMyPlans();
  }, []);

  useEffect(() => {
    //get all excercise
    const getAllExcercise = async () => {
      setStatus('loading');
      try {
        const response = await api.get('/workout/newPlan');

        const filteredData = response.data.filter(
          (item) => item.active === false || item.active === true
        );
        setAllworkouts(filteredData);
        setStatus('resolved');
      } catch (error) {
        setStatus('error');
        console.log(error.message);
      }
    };
    getAllExcercise();
  }, []);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          marginTop: '10px',
        }}>
        {status === 'loading' && <p>Loading...</p>}
        {status === 'error' && <p>Something went wrong</p>}
        {status === 'resolved' && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Plan Name</th>
                <th>Plan Level</th>

                <th>Image</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {myPlans.map((plan) => {
                return (
                  <tr
                    key={plan._id}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      // navigate to /singlePlan/${plan._id}
                      window.location.href = `/singlePlan/${plan._id}`;
                    }}>
                    <td>{plan.name}</td>
                    <td>{plan.level}</td>

                    <td>
                      <img
                        src={plan.image}
                        alt="plan"
                        style={{ width: '50px', height: '50px' }}
                      />
                    </td>
                    <td>{plan.active ? 'Active' : 'Inactive'}</td>
                    {/* <td>
                      <Link to={`/singlePlan/${plan._id}`}>
                        <Button variant="primary">Edit</Button>
                      </Link>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </Container>
  );
}

export default EditPlan;
