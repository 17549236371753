import React from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';

export const AllList = ({
  filteredExcercise,
  handleCheckboxChange,
  selectedItems,
}) => {
  return (
    <Table hover>
      <tbody>
        {filteredExcercise.map((item, i) => {
          return (
            <tr key={item._id}>
              <td>{i + 1}</td>
              <td>
                <img
                  src={item.gifUrl}
                  style={{ width: '150px', height: '150px' }}
                />
              </td>
              <td>{item.loc}</td>
              <td>{item.name}</td>

              <td>{item.type}</td>

              <td></td>
              <td>
                <Form.Check
                  type="checkbox"
                  onChange={(e) => handleCheckboxChange(e, item)}
                  checked={selectedItems.some(
                    (selectedItem) => selectedItem._id === item._id
                  )}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
