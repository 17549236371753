const Alll = [
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/RIhWJL2ro9g-mZ.gif',
    id: '0001',
    name: '3/4 - sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/xxA98VDPW6U49q.gif',
    id: '0002',
    name: '45° side bend',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/nV3kPCh44qL4-a.gif',
    id: '0003',
    name: 'air bike',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/kJuo-nAc600HE8.gif',
    id: '1512',
    name: 'all fours squad stretch',
    target: 'quads',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/X1BMHRKoUVzkBW.gif',
    id: '0006',
    name: 'alternate heel touchers',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/A0t8brhDn17MYT.gif',
    id: '0007',
    name: 'alternate lateral pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/XmETDeKbY96pvd.gif',
    id: '1368',
    name: 'ankle circles',
    target: 'calves',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/05yHoe8SwwSXo4.gif',
    id: '3293',
    name: 'archer pull up',
    target: 'lats',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/1vzrz7cWl8g3Xm.gif',
    id: '3294',
    name: 'archer push up',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/yq1vIiNcfW3vJY.gif',
    id: '2355',
    name: 'arm slingers hanging bent knee legs',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/iCyIRxr7WgnodQ.gif',
    id: '2333',
    name: 'arm slingers hanging straight legs',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/mTFmPx1CPpEI1i.gif',
    id: '3214',
    name: 'arms apart circular toe touch (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/A40xTOfXPbYNYl.gif',
    id: '3204',
    name: 'arms overhead full sit-up (male)',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/9CRQJlsNROH-xb.gif',
    id: '0009',
    name: 'assisted chest dip (kneeling)',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/ul5efeEV9uoEl-.gif',
    id: '0011',
    name: 'assisted hanging knee raise',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/DIFxdRKcw71SxR.gif',
    id: '0010',
    name: 'assisted hanging knee raise with throw down',
    target: 'abs',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/I6P6PPA97ufUVt.gif',
    id: '1708',
    name: 'assisted lying calves stretch',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/O1ZhyEQYVAm32u.gif',
    id: '1709',
    name: 'assisted lying glutes stretch',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/hGgSp1YYpQFmZi.gif',
    id: '1710',
    name: 'assisted lying gluteus and piriformis stretch',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/l1tyxXzawhznps.gif',
    id: '0012',
    name: 'assisted lying leg raise with lateral throw down',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/vDHyybI-RQbPcC.gif',
    id: '0013',
    name: 'assisted lying leg raise with throw down',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/pvpp6rRlyntbRu.gif',
    id: '0014',
    name: 'assisted motion russian twist',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/4mwVs7cwaIsP1m.gif',
    id: '0015',
    name: 'assisted parallel close grip pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/h9CEQf0KkUJEb-.gif',
    id: '0016',
    name: 'assisted prone hamstring',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/3NXMjjcXicQWtp.gif',
    id: '1713',
    name: 'assisted prone lying quads stretch',
    target: 'quads',
  },
  {
    bodyPart: 'waist',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/TI1lJVghLAfNdz.gif',
    id: '1714',
    name: 'assisted prone rectus femoris stretch',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/TtncVNfL9TfCFg.gif',
    id: '0017',
    name: 'assisted pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'chest',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/EhayKkz9LY9HV8.gif',
    id: '1716',
    name: 'assisted seated pectoralis major stretch with stability ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/ZRG-l3e-wgVhP0.gif',
    id: '1712',
    name: 'assisted side lying adductor stretch',
    target: 'adductors',
  },
  {
    bodyPart: 'waist',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/X0vURE2CsuvK7s.gif',
    id: '1758',
    name: 'assisted sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/l-5Yo3jAgVLyMX.gif',
    id: '1431',
    name: 'assisted standing chin-up',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/UpzRxb9zN5GNfT.gif',
    id: '1432',
    name: 'assisted standing pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/PYWyUTWzAQ9SXM.gif',
    id: '0018',
    name: 'assisted standing triceps extension (with towel)',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/Iy14KnnGRI63-y.gif',
    id: '0019',
    name: 'assisted triceps dip (kneeling)',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/C4cjloEVEYbpyr.gif',
    id: '2364',
    name: 'assisted wide-grip chest dip (kneeling)',
    target: 'pectorals',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/4ZWr68iZFHj4Bl.gif',
    id: '3220',
    name: 'astride jumps (male)',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/KkD0PA9osfXzij.gif',
    id: '3672',
    name: 'back and forth step',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/UUOvzL1InQfA0D.gif',
    id: '1314',
    name: 'back extension on exercise ball',
    target: 'spine',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/MEv992pmcC1gMq.gif',
    id: '3297',
    name: 'back lever',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/bsY0KeEBTKFaeH.gif',
    id: '1405',
    name: 'back pec stretch',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/M88OfknQcKhX9g.gif',
    id: '1473',
    name: 'backward jump',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/YACpr-27wgLk60.gif',
    id: '0020',
    name: 'balance board',
    target: 'quads',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/Fz1XA5ZpzTFHRH.gif',
    id: '0968',
    name: 'band alternating biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/zIuxB5l6hJxFeV.gif',
    id: '0969',
    name: 'band alternating v-up',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/i-jxn95y5wDA1c.gif',
    id: '0970',
    name: 'band assisted pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/bQf85FfBHDNKq0.gif',
    id: '0971',
    name: 'band assisted wheel rollerout',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/4su3VDh10EzK52.gif',
    id: '1254',
    name: 'band bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/WFXCntmj6iICAT.gif',
    id: '0980',
    name: 'band bent-over hip extension',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/qHDixJaHUwzA93.gif',
    id: '0972',
    name: 'band bicycle crunch',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/qdzkAtQdJZyom8.gif',
    id: '0974',
    name: 'band close-grip pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/hytbODuC8zNZa0.gif',
    id: '0975',
    name: 'band close-grip push-up',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/3aUa5OOkDIXKpd.gif',
    id: '0976',
    name: 'band concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/6Djly1Vk01ppFg.gif',
    id: '3117',
    name: 'band fixed back close grip pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/pD314ccjCWpyjk.gif',
    id: '3116',
    name: 'band fixed back underhand pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/FJlghwYsS0Gxxt.gif',
    id: '0977',
    name: 'band front lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/TWBvY6FNT4CnY5.gif',
    id: '0978',
    name: 'band front raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/qcVV85momoQktY.gif',
    id: '1408',
    name: 'band hip lift',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/oCLoldjlrTgPkb.gif',
    id: '0979',
    name: 'band horizontal pallof press',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/GOvXMp6t0yWPnr.gif',
    id: '0981',
    name: 'band jack knife sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/woEyAPWnFFxnVq.gif',
    id: '0983',
    name: 'band kneeling one arm pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/qsN8CApfKPNl7E.gif',
    id: '0985',
    name: 'band kneeling twisting crunch',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/tB3HiA6sFTP5fK.gif',
    id: '0984',
    name: 'band lying hip internal rotation',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/suXqqTh3yBIJkQ.gif',
    id: '1002',
    name: 'band lying straight leg raise',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/VpcVUvb03Xpx2J.gif',
    id: '0986',
    name: 'band one arm overhead biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/E7wwPP5geHStsR.gif',
    id: '0987',
    name: 'band one arm single leg split squat',
    target: 'quads',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/VmP8iE2Eyz7Km-.gif',
    id: '0988',
    name: 'band one arm standing low row',
    target: 'upper back',
  },
  {
    bodyPart: 'chest',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/bKF-f-rykFUpm9.gif',
    id: '0989',
    name: 'band one arm twisting chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/31ehe-d5tlqyop.gif',
    id: '0990',
    name: 'band one arm twisting seated row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/7882OjNN0hYHOh.gif',
    id: '0991',
    name: 'band pull through',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/KLD0IzLH03TBd6.gif',
    id: '0992',
    name: 'band push sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/KiNeVtl9G99maU.gif',
    id: '0993',
    name: 'band reverse fly',
    target: 'delts',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/kvwo8l2u7jBHfA.gif',
    id: '0994',
    name: 'band reverse wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/4lTo4SV7culno1.gif',
    id: '0996',
    name: 'band seated hip internal rotation',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/cpWXNxyKJ5vdkq.gif',
    id: '1011',
    name: 'band seated twist',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/CdtEV6Rg1rGbbS.gif',
    id: '0997',
    name: 'band shoulder press',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/uPBufSX1g7nOFC.gif',
    id: '1018',
    name: 'band shrug',
    target: 'traps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/K4C8qCGXIa5WWU.gif',
    id: '0998',
    name: 'band side triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/gWmh1zc0Ts6xtF.gif',
    id: '0999',
    name: 'band single leg calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/zEz9H135eDinKg.gif',
    id: '1000',
    name: 'band single leg reverse calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/jjv2kRFPpWM3Ew.gif',
    id: '1001',
    name: 'band single leg split squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/slseRYd1GgirtE.gif',
    id: '1004',
    name: 'band squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/sYDfBio6Nzwm8D.gif',
    id: '1003',
    name: 'band squat row',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/ou9Kv00MS5TyJN.gif',
    id: '1005',
    name: 'band standing crunch',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/00rl8ePejDgVbt.gif',
    id: '1022',
    name: 'band standing rear delt row',
    target: 'delts',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/McVrLTF4vRb5PQ.gif',
    id: '1007',
    name: 'band standing twisting crunch',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/01vT2u3KGzA96m.gif',
    id: '1008',
    name: 'band step-up',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/wcWOPX6bhAtjPy.gif',
    id: '1009',
    name: 'band stiff leg deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/noxE7elgnrTE4D.gif',
    id: '1023',
    name: 'band straight back stiff leg deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/XJFoqrRDTdZ62e.gif',
    id: '1010',
    name: 'band straight leg deadlift',
    target: 'spine',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/E4Y5i6qUJKXxXQ.gif',
    id: '1012',
    name: 'band twisting overhead press',
    target: 'delts',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/90GcCt92i5fytN.gif',
    id: '1369',
    name: 'band two legs calf raise - (band under both legs) v. 2',
    target: 'calves',
  },
  {
    bodyPart: 'back',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/xSbLHb4q116cYe.gif',
    id: '1013',
    name: 'band underhand pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/1ADpYgUsuHoghV.gif',
    id: '1014',
    name: 'band v-up',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/we6AYiGs2TSwpP.gif',
    id: '1015',
    name: 'band vertical pallof press',
    target: 'abs',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/sJI8GYXheS876p.gif',
    id: '1016',
    name: 'band wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'band',
    gifUrl: 'https://backend.fitlinez.com/all/mdKIB36cBxi9uS.gif',
    id: '1017',
    name: 'band y-raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/zVB7qnza3ojg9e.gif',
    id: '0023',
    name: 'barbell alternate biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/j2o3crv8GgBZeX.gif',
    id: '0024',
    name: 'barbell bench front squat',
    target: 'quads',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/MHlE9Ssd6Xxo97.gif',
    id: '0025',
    name: 'barbell bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/nRkpl3WGyWzNbn.gif',
    id: '0026',
    name: 'barbell bench squat',
    target: 'quads',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/DizLsCJDZrguOq.gif',
    id: '1316',
    name: 'barbell bent arm pullover',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/QDK3ZJPxjbBjYO.gif',
    id: '0027',
    name: 'barbell bent over row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/sqcbkssOG5rFBJ.gif',
    id: '2407',
    name: 'barbell biceps curl (with arm blaster)',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/J-llrbO4BzCOe2.gif',
    id: '0028',
    name: 'barbell clean and press',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/Yj25rStrUGLgPb.gif',
    id: '0029',
    name: 'barbell clean-grip front squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/hGyvkg8afL1psO.gif',
    id: '0030',
    name: 'barbell close-grip bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/suSrWjtBYp-AUU.gif',
    id: '0031',
    name: 'barbell curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/QrQ8D9ornAXWod.gif',
    id: '0032',
    name: 'barbell deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/w2L3UZPzxR5QvH.gif',
    id: '0033',
    name: 'barbell decline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/h4gYY5vxaCZ7iA.gif',
    id: '0034',
    name: 'barbell decline bent arm pullover',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/Ze6MHNMVIn0KK1.gif',
    id: '0035',
    name: 'barbell decline close grip to skull press',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/5i3tmYcPYu8rc7.gif',
    id: '1255',
    name: 'barbell decline pullover',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/f-m2Innpl69QFo.gif',
    id: '0036',
    name: 'barbell decline wide-grip press',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/9fYJWCEoezMPBl.gif',
    id: '0037',
    name: 'barbell decline wide-grip pullover',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/VtuHktWFUx-tVr.gif',
    id: '0038',
    name: 'barbell drag curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/kwif0mXLpovIgS.gif',
    id: '1370',
    name: 'barbell floor calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/SURuIEWX1wwLBI.gif',
    id: '0039',
    name: 'barbell front chest squat',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/sJC-uoxTtdmTkt.gif',
    id: '0041',
    name: 'barbell front raise',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/BCxRtbOov1Wc7O.gif',
    id: '0040',
    name: 'barbell front raise and pullover',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/I7FhV5y8otpiUb.gif',
    id: '0042',
    name: 'barbell front squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/6v7uRUXbX8XBz5.gif',
    id: '0043',
    name: 'barbell full squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/IFKGPgxTYYlsZN.gif',
    id: '1461',
    name: 'barbell full squat (back pov)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/406RBLRX0InDQM.gif',
    id: '1462',
    name: 'barbell full squat (side pov)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/5KJ00NnaxjTsRa.gif',
    id: '1545',
    name: 'barbell full zercher squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/jxP4UVp2a4EQUI.gif',
    id: '1409',
    name: 'barbell glute bridge',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/r7fBDxkyWV4W2d.gif',
    id: '3562',
    name: 'barbell glute bridge two legs on bench (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/P73mNyzPzgnGvW.gif',
    id: '0044',
    name: 'barbell good morning',
    target: 'hamstrings',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/sIh5cLF6-qHdev.gif',
    id: '0045',
    name: 'barbell guillotine bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/5ZP52b8enh3k8k.gif',
    id: '0046',
    name: 'barbell hack squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/7t8dED0BVRhmdr.gif',
    id: '1436',
    name: 'barbell high bar squat',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/c04CiGbKKLKsEG.gif',
    id: '0047',
    name: 'barbell incline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/YcSCRSIAkh1M1I.gif',
    id: '1719',
    name: 'barbell incline close grip bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/DMVuO300lRyxJi.gif',
    id: '0048',
    name: 'barbell incline reverse-grip press',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/CGBM6hMZF5aWVJ.gif',
    id: '0049',
    name: 'barbell incline row',
    target: 'upper back',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/8j1kw8bKqk4K1f.gif',
    id: '0050',
    name: 'barbell incline shoulder raise',
    target: 'serratus anterior',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/h07JjJiUzcSl3u.gif',
    id: '0051',
    name: 'barbell jefferson squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/iGeWFpds1yE78J.gif',
    id: '0052',
    name: 'barbell jm bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/4XjyEHS8MurUat.gif',
    id: '0053',
    name: 'barbell jump squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/z4qzu9RyiHNqyK.gif',
    id: '1410',
    name: 'barbell lateral lunge',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/-Zjr7QiUvakrrc.gif',
    id: '1435',
    name: 'barbell low bar squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/VYWb7O4x980csL.gif',
    id: '0054',
    name: 'barbell lunge',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/b0N9gmuYx3PDHv.gif',
    id: '1720',
    name: 'barbell lying back of the head tricep extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/uvEz3-7H9UBn24.gif',
    id: '0055',
    name: 'barbell lying close-grip press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/O7pSGYklV-h6IV.gif',
    id: '0056',
    name: 'barbell lying close-grip triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/5Y1IYp9b3vzLS-.gif',
    id: '0057',
    name: 'barbell lying extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/yMsVXJDDpw1bxo.gif',
    id: '0058',
    name: 'barbell lying lifting (on hip)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/GzrCDgMCxGvK7p.gif',
    id: '0059',
    name: 'barbell lying preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/Lfn0BIq2D8saPI.gif',
    id: '0061',
    name: 'barbell lying triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/8u1KLv-aYtqYHw.gif',
    id: '0060',
    name: 'barbell lying triceps extension skull crusher',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/ikyjfGMjNSOZcE.gif',
    id: '0063',
    name: 'barbell narrow stance squat',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/knpThFSEBN1Kk-.gif',
    id: '0064',
    name: 'barbell one arm bent over row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/NeIKeFEEgQPkRm.gif',
    id: '0065',
    name: 'barbell one arm floor press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/MZSLwsUam3ZjPA.gif',
    id: '0066',
    name: 'barbell one arm side deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/eL11oBGpbU6OKT.gif',
    id: '0067',
    name: 'barbell one arm snatch',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/dZDTsm7MPCOAhm.gif',
    id: '0068',
    name: 'barbell one leg squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/yvi0B61TsxTtT5.gif',
    id: '0069',
    name: 'barbell overhead squat',
    target: 'quads',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/tGGy6l8TqAcSxn.gif',
    id: '1411',
    name: 'barbell palms down wrist curl over a bench',
    target: 'forearms',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/fpsGd2QimH8f3l.gif',
    id: '1412',
    name: 'barbell palms up wrist curl over a bench',
    target: 'forearms',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/grmxezw-QEt1mV.gif',
    id: '3017',
    name: 'barbell pendlay row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/tAfscOhDQqlXZc.gif',
    id: '1751',
    name: 'barbell pin presses',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/oFGkS4tIwY-seP.gif',
    id: '0070',
    name: 'barbell preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/5KhPQGbQiulTkZ.gif',
    id: '0071',
    name: 'barbell press sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/OllsgPgoBz8z0Z.gif',
    id: '0072',
    name: 'barbell prone incline curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/-hkz6cj9I1A2oi.gif',
    id: '0073',
    name: 'barbell pullover',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/vaLJDMAQCYrdTp.gif',
    id: '0022',
    name: 'barbell pullover to press',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/l3jAO9IaCLiTsQ.gif',
    id: '0074',
    name: 'barbell rack pull',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/HwaGiWo9e0Jsz4.gif',
    id: '0075',
    name: 'barbell rear delt raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/OZotamH342hHwf.gif',
    id: '0076',
    name: 'barbell rear delt row',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/5gsHBlQxKdw20A.gif',
    id: '0078',
    name: 'barbell rear lunge',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/TF3oVWhvzkWaSg.gif',
    id: '0077',
    name: 'barbell rear lunge v. 2',
    target: 'glutes',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/6mBXukdrHinbW4.gif',
    id: '0079',
    name: 'barbell revers wrist curl v. 2',
    target: 'forearms',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/OKiXo4vqvUDpwK.gif',
    id: '2187',
    name: 'barbell reverse close-grip bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/bDXwq932ClH0T3.gif',
    id: '0080',
    name: 'barbell reverse curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/CU6jD2CQKlAgSa.gif',
    id: '0118',
    name: 'barbell reverse grip bent over row',
    target: 'upper back',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/0ix7beqFa2gRb-.gif',
    id: '1256',
    name: 'barbell reverse grip decline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/e4HmwKKa1jmPQ5.gif',
    id: '1257',
    name: 'barbell reverse grip incline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/7-k7cpFjHu0pws.gif',
    id: '1317',
    name: 'barbell reverse grip incline bench row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/HMhHcidtWC-z00.gif',
    id: '1721',
    name: 'barbell reverse grip skullcrusher',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/S4TM3JvzDPPGAK.gif',
    id: '0081',
    name: 'barbell reverse preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/ZhHNqWVRgkTvb7.gif',
    id: '0082',
    name: 'barbell reverse wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/fP-pTDeePWS3Ai.gif',
    id: '0084',
    name: 'barbell rollerout',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/fZxwLLXw6i5qGx.gif',
    id: '0083',
    name: 'barbell rollerout from bench',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/GtykCq3Xe9qqwn.gif',
    id: '0085',
    name: 'barbell romanian deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/Y2wbP2h7M7LH3T.gif',
    id: '0086',
    name: 'barbell seated behind head military press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/DDI7i2sIMciO8K.gif',
    id: '0087',
    name: 'barbell seated bradford rocky press',
    target: 'delts',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/3S7LvQvdJoHwm3.gif',
    id: '0088',
    name: 'barbell seated calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/ROj7QChuUZJiVS.gif',
    id: '1371',
    name: 'barbell seated calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/7JyCSRUDfwwib4.gif',
    id: '1718',
    name: 'barbell seated close grip behind neck triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/cLFecHBkg7iyjJ.gif',
    id: '0089',
    name: 'barbell seated close-grip concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/X0Ipc9R5jHYet8.gif',
    id: '0090',
    name: 'barbell seated good morning',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/-k1Ysmb-DsaQJa.gif',
    id: '0091',
    name: 'barbell seated overhead press',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/AXnq8UBowzXvOq.gif',
    id: '0092',
    name: 'barbell seated overhead triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/BcUKw7DqgSmcUj.gif',
    id: '0094',
    name: 'barbell seated twist',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/RvKMkr2Wvl2T0w.gif',
    id: '0095',
    name: 'barbell shrug',
    target: 'traps',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/dyWzwpRceRCQtU.gif',
    id: '0096',
    name: 'barbell side bent v. 2',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/xIH9Bio9VFsmZR.gif',
    id: '0098',
    name: 'barbell side split squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/WVLgATeOa2EEIF.gif',
    id: '0097',
    name: 'barbell side split squat v. 2',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/XDsygr0ced2Tep.gif',
    id: '1756',
    name: 'barbell single leg deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/z7-vSEwdrvVHUc.gif',
    id: '0099',
    name: 'barbell single leg split squat',
    target: 'quads',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/YBNSXC98tKuy0y.gif',
    id: '2799',
    name: 'barbell sitted alternate leg raise',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/836uyBCTUKtPNE.gif',
    id: '2800',
    name: 'barbell sitted alternate leg raise (female)',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/37AasSRJltnSiu.gif',
    id: '0100',
    name: 'barbell skier',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/RAxkyt655fQwWZ.gif',
    id: '0101',
    name: 'barbell speed squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/r-EHMHdEqWvTLR.gif',
    id: '2810',
    name: 'barbell split squat v. 2',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/uin4ZEwyoerY6L.gif',
    id: '0102',
    name: 'barbell squat (on knees)',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/USqXl3-gl06zNL.gif',
    id: '2798',
    name: 'barbell squat jump step rear lunge',
    target: 'quads',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/u9q9PKTjRkQGyI.gif',
    id: '0103',
    name: 'barbell standing ab rollerout',
    target: 'abs',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/bftXVNUjl6lQEm.gif',
    id: '0104',
    name: 'barbell standing back wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/EO51T-h6z1iPU3.gif',
    id: '0105',
    name: 'barbell standing bradford press',
    target: 'delts',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/x70QciIPqugJCW.gif',
    id: '1372',
    name: 'barbell standing calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/RxtGOv5CEMAy1v.gif',
    id: '0106',
    name: 'barbell standing close grip curl',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/Nh2wxlpoaN3xRz.gif',
    id: '1456',
    name: 'barbell standing close grip military press',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/zuualpS7R3UDTP.gif',
    id: '2414',
    name: 'barbell standing concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/jRLlM2n6ZbziRv.gif',
    id: '0107',
    name: 'barbell standing front raise over head',
    target: 'delts',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/KCo54cBj8pHXgo.gif',
    id: '0108',
    name: 'barbell standing leg calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/lQM9WQZNsF7pO8.gif',
    id: '0109',
    name: 'barbell standing overhead triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/K909r34zhxD47e.gif',
    id: '0110',
    name: 'barbell standing reverse grip curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/2YOQDx1yGI2G1h.gif',
    id: '0111',
    name: 'barbell standing rocking leg calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/1wjeYzeMJPHo4K.gif',
    id: '0112',
    name: 'barbell standing twist',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/cXcK1Dcx4iIJas.gif',
    id: '1629',
    name: 'barbell standing wide grip biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/d5-0IyhZxX-SAL.gif',
    id: '1457',
    name: 'barbell standing wide military press',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/QTQ5yGBm91wHXp.gif',
    id: '0113',
    name: 'barbell standing wide-grip curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/Q-GSe6XgaT-5jv.gif',
    id: '0114',
    name: 'barbell step-up',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/0WYxQIspRNra2A.gif',
    id: '0115',
    name: 'barbell stiff leg good morning',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/LMsntHh2k34EPU.gif',
    id: '0116',
    name: 'barbell straight leg deadlift',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/nvGB3AH5BBR5BD.gif',
    id: '0117',
    name: 'barbell sumo deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/X3ZfnGH-lU32ji.gif',
    id: '3305',
    name: 'barbell thruster',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/QErRU2djzUiY55.gif',
    id: '0120',
    name: 'barbell upright row',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/W4fESRY4S68Tuu.gif',
    id: '0119',
    name: 'barbell upright row v. 2',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/l1o0JdCNXFf7yM.gif',
    id: '0121',
    name: 'barbell upright row v. 3',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/zxRFWjEszHoY1i.gif',
    id: '0122',
    name: 'barbell wide bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/nTWZqgcm2iPgOQ.gif',
    id: '1258',
    name: 'barbell wide reverse grip bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/sLSIgrnpaULnvu.gif',
    id: '0124',
    name: 'barbell wide squat',
    target: 'quads',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/A02yHtg954bziA.gif',
    id: '0123',
    name: 'barbell wide-grip upright row',
    target: 'delts',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/f5dREqXlXvijaF.gif',
    id: '0126',
    name: 'barbell wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/fz5yUGrgoLU95F.gif',
    id: '0125',
    name: 'barbell wrist curl v. 2',
    target: 'forearms',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/KtqkpmrnQqvjIH.gif',
    id: '0127',
    name: 'barbell zercher squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/0ctg2v2S4XyMgn.gif',
    id: '3212',
    name: 'basic toe touch (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/WYJ6ZzBJZ8DL0-.gif',
    id: '0128',
    name: 'battling ropes',
    target: 'delts',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/YozHFncRFmxR7A.gif',
    id: '3360',
    name: 'bear crawl',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'chest',
    equipment: 'assisted',
    gifUrl: 'https://backend.fitlinez.com/all/3FwtQOVO1NwSNt.gif',
    id: '1259',
    name: 'behind head chest stretch',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/NQtL-lencyVp9m.gif',
    id: '0129',
    name: 'bench dip (knees bent)',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/1meKYK1kgEisbe.gif',
    id: '1399',
    name: 'bench dip on floor',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/GKOwS5p2zLceg0.gif',
    id: '0130',
    name: 'bench hip extension',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/IQB0ycV7SiGIp4.gif',
    id: '3019',
    name: 'bench pull-ups',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/YNrb6bK-7w1wCQ.gif',
    id: '3639',
    name: 'bent knee lying twist (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/8TrXW6madWQwVR.gif',
    id: '1770',
    name: 'biceps leg concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/9Gil3uvjVfP-F6.gif',
    id: '0139',
    name: 'biceps narrow pull-ups',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/HJcANM2gZnL7jo.gif',
    id: '0140',
    name: 'biceps pull-up',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/vFjwC6-d0jI2o1.gif',
    id: '0137',
    name: 'body-up',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/I6lFi9XkqyZLff.gif',
    id: '3543',
    name: 'bodyweight drop jump squat',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/JI9OU0X1AzeBtp.gif',
    id: '3544',
    name: 'bodyweight incline side plank',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ZQYx0VPa0J9G2Q.gif',
    id: '1771',
    name: 'bodyweight kneeling triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/QJSgj65IBPWZk4.gif',
    id: '1769',
    name: 'bodyweight side lying biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/cGwx3nhKIneF45.gif',
    id: '3168',
    name: 'bodyweight squatting row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/h6tXsL2SFMYH1x.gif',
    id: '3167',
    name: 'bodyweight squatting row (with towel)',
    target: 'upper back',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/AwGszH2FxLzTf4.gif',
    id: '1373',
    name: 'bodyweight standing calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/V51nvarw6XuLBe.gif',
    id: '3156',
    name: 'bodyweight standing close-grip one arm row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5PMj4Zl4EitgUF.gif',
    id: '3158',
    name: 'bodyweight standing close-grip row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/eR-LJcn0qkvlCG.gif',
    id: '3162',
    name: 'bodyweight standing one arm row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/0yHrY-fIMKRR7p.gif',
    id: '3161',
    name: 'bodyweight standing one arm row (with towel)',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/g4-FZHpxz0lcxn.gif',
    id: '3166',
    name: 'bodyweight standing row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/g7U3xWGZKFePqL.gif',
    id: '3165',
    name: 'bodyweight standing row (with towel)',
    target: 'upper back',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/-09oOJnTF1sLmJ.gif',
    id: '0138',
    name: 'bottoms-up',
    target: 'abs',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/n4LSwxL0WFVSEm.gif',
    id: '1374',
    name: 'box jump down with one leg stabilization',
    target: 'calves',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/WbCeHQJbEjFOC8.gif',
    id: '2466',
    name: 'bridge - mountain climber (cross body)',
    target: 'abs',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/xPg8h7-EgRltZo.gif',
    id: '1160',
    name: 'burpee',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/zsRmURSkmzmkJG.gif',
    id: '0870',
    name: 'butt-ups',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/BnaRBPRQHBZMwJ.gif',
    id: '1494',
    name: 'butterfly yoga pose',
    target: 'adductors',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/nMiUmsdqvSGyTu.gif',
    id: '0148',
    name: 'cable alternate shoulder press',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/C-YbovuFl1d5td.gif',
    id: '0149',
    name: 'cable alternate triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/UpR6SuLI8B0pda.gif',
    id: '3235',
    name: 'cable assisted inverse leg curl',
    target: 'hamstrings',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/vtRVB7xrE8rIIu.gif',
    id: '0150',
    name: 'cable bar lateral pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/VHEhhYkdIcBRK-.gif',
    id: '0151',
    name: 'cable bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/5s2gJ0kMLg2REi.gif',
    id: '1630',
    name: 'cable close grip curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/EBYeekNkYZj9q5.gif',
    id: '1631',
    name: 'cable concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/ZQC5unjPHuReQk.gif',
    id: '0152',
    name: 'cable concentration extension (on knee)',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/kg5xpiHFl7LQWl.gif',
    id: '0153',
    name: 'cable cross-over lateral pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/XtEEcYy4a3ENhF.gif',
    id: '0154',
    name: 'cable cross-over revers fly',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/kzdRmMrWUg957X.gif',
    id: '0155',
    name: 'cable cross-over variation',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/SHHddFkUUnSZC0.gif',
    id: '0868',
    name: 'cable curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/RTn7ukUK5lucyp.gif',
    id: '0157',
    name: 'cable deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/PZYsOCSRmOggS7.gif',
    id: '0158',
    name: 'cable decline fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/XSL58-pvDOl0wO.gif',
    id: '1260',
    name: 'cable decline one arm press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/mRbmbPPicubU47.gif',
    id: '1261',
    name: 'cable decline press',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/SPTh2IuDQF-GsV.gif',
    id: '0159',
    name: 'cable decline seated wide-grip row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/YGVSA2CvebTM3y.gif',
    id: '1632',
    name: 'cable drag curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/6SSzxAaI-Zp4vH.gif',
    id: '0160',
    name: 'cable floor seated wide-grip row',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/W6L-WB2dKCpSzB.gif',
    id: '0161',
    name: 'cable forward raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/HHQj0rfZIDeIMd.gif',
    id: '0162',
    name: 'cable front raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/M8zX4dgMnZMZX3.gif',
    id: '0164',
    name: 'cable front shoulder raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/GknU9f8bmPWWwH.gif',
    id: '0165',
    name: 'cable hammer curl (with rope)',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/vCsYxyL4BRDY99.gif',
    id: '1722',
    name: 'cable high pulley overhead tricep extension',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/6J0ZnNtbwbM5OP.gif',
    id: '0167',
    name: 'cable high row (kneeling)',
    target: 'upper back',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/CAUg4yZrrDsbVl.gif',
    id: '0168',
    name: 'cable hip adduction',
    target: 'adductors',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/OBVcbpwMXYs2jd.gif',
    id: '0169',
    name: 'cable incline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/oI6iIw2G0efBSt.gif',
    id: '1318',
    name: 'cable incline bench row',
    target: 'upper back',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/nyo-PteZg86dHo.gif',
    id: '0171',
    name: 'cable incline fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/Nag52TJzZdVr3X.gif',
    id: '0170',
    name: 'cable incline fly (on stability ball)',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/T3iyOz9YfUmUv5.gif',
    id: '0172',
    name: 'cable incline pushdown',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/lZeVvYVtGvQDr9.gif',
    id: '0173',
    name: 'cable incline triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/sXaqGv7esoBgSu.gif',
    id: '0174',
    name: 'cable judo flip',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/PEU1g7NIzmj5Qy.gif',
    id: '0860',
    name: 'cable kickback',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/F8ZUqVEDlYMbPe.gif',
    id: '0175',
    name: 'cable kneeling crunch',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/t2mAh2I7rz5NJX.gif',
    id: '3697',
    name: 'cable kneeling rear delt row (with rope) (male)',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/i1JQOyCIM5jMu4.gif',
    id: '0176',
    name: 'cable kneeling triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/JGERZCEzc0yWJx.gif',
    id: '2330',
    name: 'cable lat pulldown full range of motion',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/b8isdTFpEj1Cge.gif',
    id: '0177',
    name: 'cable lateral pulldown (with rope attachment)',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/Rf-AV35u9CVsGh.gif',
    id: '2616',
    name: 'cable lateral pulldown with v-bar',
    target: 'lats',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/3K7rfAiZXeNK6w.gif',
    id: '0178',
    name: 'cable lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/qawHiVQ7UF0nRk.gif',
    id: '0179',
    name: 'cable low fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/F4h2vPefTesKVM.gif',
    id: '0180',
    name: 'cable low seated row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/p-AvlqLaZiE7Vh.gif',
    id: '1634',
    name: 'cable lying bicep curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/4QPF6wEVjyqUco.gif',
    id: '0182',
    name: 'cable lying close-grip curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/pmR6LfoZ-ZpSw2.gif',
    id: '0184',
    name: 'cable lying extension pullover (with rope attachment)',
    target: 'lats',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/1qsyWuIoXtMEVb.gif',
    id: '0185',
    name: 'cable lying fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/VHng6rBx6ActWL.gif',
    id: '0186',
    name: 'cable lying triceps extension v. 2',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/O7hfeDrUITETTI.gif',
    id: '0188',
    name: 'cable middle fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/Jsr4AikBK5tYsI.gif',
    id: '0189',
    name: 'cable one arm bent over row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/6iSs3RoWSKzGyk.gif',
    id: '0190',
    name: 'cable one arm curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/GnV1q5EDWPDVJF.gif',
    id: '1262',
    name: 'cable one arm decline chest fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/tm6kLiONUD6ClT.gif',
    id: '1263',
    name: 'cable one arm fly on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/jpUY9XjNl2BMU5.gif',
    id: '1264',
    name: 'cable one arm incline fly on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/tc1DQd9jAzltcA.gif',
    id: '1265',
    name: 'cable one arm incline press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/PlnKGNT4klwEpq.gif',
    id: '1266',
    name: 'cable one arm incline press on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/mGJ94YB5td4NDg.gif',
    id: '0191',
    name: 'cable one arm lateral bent-over',
    target: 'pectorals',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/o8QcyQdOxIIo5j.gif',
    id: '0192',
    name: 'cable one arm lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/hnfYCfCKpZ5cGx.gif',
    id: '1633',
    name: 'cable one arm preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/U3QARMbj63268o.gif',
    id: '1267',
    name: 'cable one arm press on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/xE4N4Y-BVDM7I2.gif',
    id: '3563',
    name: 'cable one arm pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/poLPXUlD-aXF-k.gif',
    id: '1635',
    name: 'cable one arm reverse preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/ouAV78D1hAXs9B.gif',
    id: '0193',
    name: 'cable one arm straight back high row (kneeling)',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/MYezG4iWCbDiUy.gif',
    id: '1723',
    name: 'cable one arm tricep pushdown',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/NmGLgCY5bDGFuU.gif',
    id: '1636',
    name: 'cable overhead curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/pB9wXncRrfQkJ4.gif',
    id: '1637',
    name: 'cable overhead curl on exercise ball',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/FBJO3-OkhYwl8J.gif',
    id: '0194',
    name: 'cable overhead triceps extension (rope attachment)',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/Q0Zr8eytxlcgZ1.gif',
    id: '1319',
    name: 'cable palm rotational row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/pLCo0FMFUu9V84.gif',
    id: '0195',
    name: 'cable preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/EnIt7KTtqHfb-j.gif',
    id: '1268',
    name: 'cable press on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/9LVUa9CM-0t8tj.gif',
    id: '0196',
    name: 'cable pull through (with rope)',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/-wbON4kTkwOKzU.gif',
    id: '0198',
    name: 'cable pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/SjmGn0x4YW-Pyh.gif',
    id: '0197',
    name: 'cable pulldown (pro lat bar)',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/snPu46MjRHhZ8Q.gif',
    id: '1638',
    name: 'cable pulldown bicep curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/noDt-wicVo4B0-.gif',
    id: '0201',
    name: 'cable pushdown',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/9MPgFwkGSMVDFX.gif',
    id: '0199',
    name: 'cable pushdown (straight arm) v. 2',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/gjJ-BmUuPErY9C.gif',
    id: '0200',
    name: 'cable pushdown (with rope attachment)',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/egpYmkJNx12Zq9.gif',
    id: '0202',
    name: 'cable rear delt row (stirrups)',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/aNHVrb2jdYDcrF.gif',
    id: '0203',
    name: 'cable rear delt row (with rope)',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/VaamBfAlTYJQzY.gif',
    id: '0204',
    name: 'cable rear drive',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/5IbROS0U1eNpfT.gif',
    id: '0205',
    name: 'cable rear pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/WJklFlO-yLx2OA.gif',
    id: '0873',
    name: 'cable reverse crunch',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/y1HfY771eZjGFE.gif',
    id: '0206',
    name: 'cable reverse curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/NiN14R6ygeAfdE.gif',
    id: '2406',
    name: 'cable reverse grip triceps pushdown (sz-bar) (with arm blaster)',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/ZDjkK74xtbrZV0.gif',
    id: '1413',
    name: 'cable reverse one arm curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/YW1OwGOgkvVdjx.gif',
    id: '0209',
    name: 'cable reverse preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/G5hDlcKUCYj5YR.gif',
    id: '0210',
    name: 'cable reverse wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/qZTHSlYu5KYTot.gif',
    id: '0207',
    name: 'cable reverse-grip pushdown',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/zig8zA1tsWjWx3.gif',
    id: '0208',
    name: 'cable reverse-grip straight back seated high row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/qtHXrMZ9ACy8MQ.gif',
    id: '1320',
    name: 'cable rope crossover seated row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/X66vb8PM5GKlNF.gif',
    id: '1321',
    name: 'cable rope elevated seated row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/aCcdtz0IRGtMDM.gif',
    id: '1322',
    name: 'cable rope extension incline bench row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/qbNJUBMlHtuum6.gif',
    id: '1639',
    name: 'cable rope hammer preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/8Kote00sh49vsm.gif',
    id: '1724',
    name: 'cable rope high pulley overhead tricep extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/2n07NXctAo2vzT.gif',
    id: '1725',
    name: 'cable rope incline tricep extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/R1cx9jbI71ZPfI.gif',
    id: '1726',
    name: 'cable rope lying on floor tricep extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/ydEYEpBhT7-8Nj.gif',
    id: '1640',
    name: 'cable rope one arm hammer preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/XDx1MeEYEL1GpT.gif',
    id: '1323',
    name: 'cable rope seated row',
    target: 'upper back',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/VezqgitngaS24o.gif',
    id: '0211',
    name: 'cable russian twists (on stability ball)',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/Q-kmqblrLYk6Jx.gif',
    id: '2144',
    name: 'cable seated chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/bXNVK95CcAL674.gif',
    id: '0212',
    name: 'cable seated crunch',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/7MKEshuC-AVQB1.gif',
    id: '1641',
    name: 'cable seated curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/UWsJDhZVVNKI1S.gif',
    id: '0213',
    name: 'cable seated high row (v-bar)',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/9EjNIO-ax7dJpm.gif',
    id: '0214',
    name: 'cable seated one arm alternate row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/TwYcWTxbMrt4oo.gif',
    id: '1642',
    name: 'cable seated one arm concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/ZkB0lQmHXzfm14.gif',
    id: '1643',
    name: 'cable seated overhead curl',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/72wzNOcGLkgxUc.gif',
    id: '0215',
    name: 'cable seated rear lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/uDnGl6Y9zC8IQm.gif',
    id: '0861',
    name: 'cable seated row',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/pmXxIyh4fzZHPH.gif',
    id: '0216',
    name: 'cable seated shoulder internal rotation',
    target: 'delts',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/EHtGBysA08Rv2H.gif',
    id: '2399',
    name: 'cable seated twist',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/FkbCNeOLPgAsbz.gif',
    id: '0218',
    name: 'cable seated wide-grip row',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/2B1797VqBMJDWK.gif',
    id: '0219',
    name: 'cable shoulder press',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/sYqR4GZJZypwlW.gif',
    id: '0220',
    name: 'cable shrug',
    target: 'traps',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/XZc4zqvVVcfJMy.gif',
    id: '0222',
    name: 'cable side bend',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/xXoT7SbgOj1AP0.gif',
    id: '0221',
    name: 'cable side bend crunch (bosu ball)',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/j0bisxXaEHQTJr.gif',
    id: '0223',
    name: 'cable side crunch',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/XqfNhf17tFFvdd.gif',
    id: '1717',
    name: 'cable squat row (with rope attachment)',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/ZlwJU7Ov71vaLe.gif',
    id: '1644',
    name: 'cable squatting curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/rsN24Yl3Z4ILXV.gif',
    id: '0224',
    name: 'cable standing back wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/mls4YX0m3NBY3N.gif',
    id: '1375',
    name: 'cable standing calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/kcfLFKpAEVDlp9.gif',
    id: '0225',
    name: 'cable standing cross-over high reverse fly',
    target: 'delts',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/ox8GdHXcXmpwZC.gif',
    id: '0226',
    name: 'cable standing crunch',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/aLHWCl-hu2jpWl.gif',
    id: '0874',
    name: 'cable standing crunch (with rope attachment)',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/Qg6UM1lr5g7f2E.gif',
    id: '0227',
    name: 'cable standing fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/Hf49wHxYeBJrQ4.gif',
    id: '0228',
    name: 'cable standing hip extension',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/7IX3273dp75bal.gif',
    id: '0229',
    name: 'cable standing inner curl',
    target: 'biceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/aUDb-qyEp1MQAS.gif',
    id: '0230',
    name: 'cable standing lift',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/XOmhi-e3EN6I0X.gif',
    id: '0231',
    name: 'cable standing one arm triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/lxiOS57qUdi1ZW.gif',
    id: '1376',
    name: 'cable standing one leg calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/Fs2jKNuhQPPr0N.gif',
    id: '0232',
    name: 'cable standing pulldown (with rope)',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/h8PIN3LZGlOL1j.gif',
    id: '0233',
    name: 'cable standing rear delt row (with rope)',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/W5vUJjylfPTu7x.gif',
    id: '1727',
    name: 'cable standing reverse grip one arm overhead tricep extension',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/yZud5wmjxTkRZe.gif',
    id: '0234',
    name: 'cable standing row (v-bar)',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/scybGT5Vroa7Xu.gif',
    id: '0235',
    name: 'cable standing shoulder external rotation',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/SzuOYQnhNrr5wY.gif',
    id: '0236',
    name: 'cable standing twist row (v-bar)',
    target: 'upper back',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/xAYnhwzcyQy5pC.gif',
    id: '1269',
    name: 'cable standing up straight crossovers',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/KONtLj-oukd8Y5.gif',
    id: '0238',
    name: 'cable straight arm pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/W3DVOnClSvSdly.gif',
    id: '0237',
    name: 'cable straight arm pulldown (with rope)',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/mUa5r5gDKGPoE4.gif',
    id: '0239',
    name: 'cable straight back seated row',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/xsusfKP5MXOckh.gif',
    id: '0240',
    name: 'cable supine reverse fly',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/iiCX4viN9tGgFI.gif',
    id: '2464',
    name: 'cable thibaudeau kayak row',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/KuFmpFyo9cEHbh.gif',
    id: '0241',
    name: 'cable triceps pushdown (v-bar)',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/o7EVUwl8zfxe1R.gif',
    id: '2405',
    name: 'cable triceps pushdown (v-bar) (with arm blaster)',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/QGoJSQSS3dwMKu.gif',
    id: '0242',
    name: 'cable tuck reverse crunch',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/xpGz5FBh0sTBG-.gif',
    id: '0243',
    name: 'cable twist',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/voiiLzGljmzsla.gif',
    id: '0862',
    name: 'cable twist (up-down)',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/PJfrYDHmVUsOuB.gif',
    id: '0244',
    name: 'cable twisting pull',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/4qC3SEfOojfdt4.gif',
    id: '1645',
    name: 'cable two arm curl on incline bench',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/Obm2OSIjwFj28m.gif',
    id: '1728',
    name: 'cable two arm tricep kickback',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/awa9yTRGL68RV0.gif',
    id: '0245',
    name: 'cable underhand pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'chest',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/voj0KUrX5X-6OO.gif',
    id: '1270',
    name: 'cable upper chest crossovers',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/XxKZrHoIwt82lo.gif',
    id: '1324',
    name: 'cable upper row',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/u5ovSSeC-j3fxp.gif',
    id: '0246',
    name: 'cable upright row',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/gHkWiOK4020uuy.gif',
    id: '1325',
    name: 'cable wide grip rear pulldown behind neck',
    target: 'lats',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/OS5RrRUJjOZYg-.gif',
    id: '0247',
    name: 'cable wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/MWk9i6H2jzT7Jd.gif',
    id: '1407',
    name: 'calf push stretch with hands against wall',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/IPMSJ4yTMq7wN6.gif',
    id: '1377',
    name: 'calf stretch with hands against wall',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/cXCBtS08WXGhhX.gif',
    id: '1378',
    name: 'calf stretch with rope',
    target: 'calves',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/fMR2bLQFhsT8yv.gif',
    id: '0248',
    name: 'cambered bar lying row',
    target: 'upper back',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/FcNwZTVXjQd2AZ.gif',
    id: '2963',
    name: 'captains chair straight leg raise',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/7SFmw7EykszNze.gif',
    id: '1548',
    name: 'chair leg extended stretch',
    target: 'quads',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/rVTjX9AnNbRH0d.gif',
    id: '1271',
    name: 'chest and front of shoulder stretch',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/joM0SA1hzp6J2E.gif',
    id: '0251',
    name: 'chest dip',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/szHbDbhDcXTgyC.gif',
    id: '1430',
    name: 'chest dip (on dip-pull-up cage)',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/kjm2I9UUsGYmLx.gif',
    id: '2462',
    name: 'chest dip on straight bar',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/QM0tgrbYE4PlPI.gif',
    id: '1272',
    name: 'chest stretch with exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ocuVq-PbZ-edEG.gif',
    id: '3216',
    name: 'chest tap push-up (male)',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/nohvVeQ20pWV2J.gif',
    id: '1326',
    name: 'chin-up',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/prXznRMZM9oLOr.gif',
    id: '0253',
    name: 'chin-ups (narrow parallel grip)',
    target: 'upper back',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/3Z2weUk2u7yOO6.gif',
    id: '0257',
    name: 'circles knee stretch',
    target: 'calves',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/PB8UoZbEYGf8ju.gif',
    id: '1273',
    name: 'clap push up',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/mO4oROTGmmSEYt.gif',
    id: '0258',
    name: 'clock push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/zQSQc1-6pC6BXM.gif',
    id: '1327',
    name: 'close grip chin-up',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/F8Tx2GmAyENGWt.gif',
    id: '0259',
    name: 'close-grip push-up',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/iD7RjZTty73WrS.gif',
    id: '2398',
    name: 'close-grip push-up (on knees)',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/50SgxjEyqqnnpx.gif',
    id: '0260',
    name: 'cocoons',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/HYLSsG6NAjNyK1.gif',
    id: '1468',
    name: 'crab twist toe touch',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/grR1RCXr14Dp2v.gif',
    id: '0262',
    name: 'cross body crunch',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/OsEfcuzMtaRyQi.gif',
    id: '0267',
    name: 'crunch (hands overhead)',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/hBWOJwgOOtYeNX.gif',
    id: '0271',
    name: 'crunch (on stability ball)',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/7n-ZmKV6qH2yp3.gif',
    id: '0272',
    name: 'crunch (on stability ball, arms straight)',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/67o5Umi09eGmYj.gif',
    id: '0274',
    name: 'crunch floor',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/dB3fwTYxJS8tqA.gif',
    id: '3016',
    name: 'curl-up',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/6IuECUuRDrBRo7.gif',
    id: '3769',
    name: 'curtsey squat',
    target: 'glutes',
  },
  {
    bodyPart: 'cardio',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/fRixjmB0hmrTOC.gif',
    id: '2331',
    name: 'cycle cross trainer',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/2gZLRLkdnpQTd-.gif',
    id: '0276',
    name: 'dead bug',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/DekXdQl-tl6mjz.gif',
    id: '0277',
    name: 'decline crunch',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5LLUSyu0xDyLot.gif',
    id: '0279',
    name: 'decline push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/FFzYKAp4j7TdMO.gif',
    id: '0282',
    name: 'decline sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/IvYzNz6fsL0Fn8.gif',
    id: '1274',
    name: 'deep push up',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/rMdMKcvbijKQOE.gif',
    id: '0283',
    name: 'diamond push-up',
    target: 'triceps',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/BBL00uxjkxfQWF.gif',
    id: '0284',
    name: 'donkey calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/n80op32OkV7nCf.gif',
    id: '1275',
    name: 'drop push up',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/vmVMRjYbug0EJF.gif',
    id: '0285',
    name: 'dumbbell alternate biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/7hJMP9UuSuAvqn.gif',
    id: '2403',
    name: 'dumbbell alternate biceps curl (with arm blaster)',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/d4tCOcKTO4O1uy.gif',
    id: '1646',
    name: 'dumbbell alternate hammer preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/f63foBXgCmlXst.gif',
    id: '1647',
    name: 'dumbbell alternate preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/hQTUv814hTNIAc.gif',
    id: '1648',
    name: 'dumbbell alternate seated hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/NuHfa2RtlefAsZ.gif',
    id: '0286',
    name: 'dumbbell alternate side press',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/vHvqwpLb3udb1-.gif',
    id: '1649',
    name: 'dumbbell alternating bicep curl with leg raised on exercise ball',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/4troP-HLpcU7yH.gif',
    id: '1650',
    name: 'dumbbell alternating seated bicep curl on exercise ball',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/74s3WH-alADisz.gif',
    id: '2137',
    name: 'dumbbell arnold press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/N3XVzF-uOvwMZg.gif',
    id: '0287',
    name: 'dumbbell arnold press v. 2',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/xx6CRYq37QduMX.gif',
    id: '0288',
    name: 'dumbbell around pullover',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/S18rZmWiYPgw4R.gif',
    id: '0289',
    name: 'dumbbell bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/5omXiyW37r7qko.gif',
    id: '0290',
    name: 'dumbbell bench seated press',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/OgZSE7ldpbPShh.gif',
    id: '0291',
    name: 'dumbbell bench squat',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/q7sjP9L17C9PKG.gif',
    id: '0293',
    name: 'dumbbell bent over row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/HBl2tUAMim7bJY.gif',
    id: '1651',
    name: 'dumbbell bicep curl lunge with bowling motion',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/IXoPInXy33vZ2m.gif',
    id: '1652',
    name: 'dumbbell bicep curl on exercise ball with leg raised',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/lLeihYNSoO0VMj.gif',
    id: '1653',
    name: 'dumbbell bicep curl with stork stance',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/iAlBEsqxklP81H.gif',
    id: '0294',
    name: 'dumbbell biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/xVL2dihTjCJI6-.gif',
    id: '2401',
    name: 'dumbbell biceps curl (with arm blaster)',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Ar0HysrOI0m-7S.gif',
    id: '1654',
    name: 'dumbbell biceps curl reverse',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/BRuGe66di4nBtD.gif',
    id: '1655',
    name: 'dumbbell biceps curl squat',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/LGZojXtWJNMbw3.gif',
    id: '1656',
    name: 'dumbbell biceps curl v sit on bosu ball',
    target: 'biceps',
  },
  {
    bodyPart: 'cardio',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/NJsXzVIOQpEgVQ.gif',
    id: '1201',
    name: 'dumbbell burpee',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/aM4Pa-t9ThnbzE.gif',
    id: '0295',
    name: 'dumbbell clean',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/vcSTsNzj5xhODJ.gif',
    id: '1731',
    name: 'dumbbell close grip press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/MSpDooqDVYRhS6.gif',
    id: '0296',
    name: 'dumbbell close-grip press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/qt5AuWiESwxmSF.gif',
    id: '0297',
    name: 'dumbbell concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Q3BlLP5LtUByxQ.gif',
    id: '3635',
    name: 'dumbbell contralateral forward lunge',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/bvx1KVQFOeELW0.gif',
    id: '0298',
    name: 'dumbbell cross body hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/HVO2KFIvWhHWzh.gif',
    id: '1657',
    name: 'dumbbell cross body hammer curl v. 2',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/dlq2TCNcoYcUmE.gif',
    id: '0299',
    name: 'dumbbell cuban press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/s5RyY4gv4d5fmm.gif',
    id: '2136',
    name: 'dumbbell cuban press v. 2',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/6uBV2eyeAJhbs5.gif',
    id: '0300',
    name: 'dumbbell deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/YQRd1nlCUrOCSb.gif',
    id: '0301',
    name: 'dumbbell decline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/CRENvGpnI1eSCG.gif',
    id: '0302',
    name: 'dumbbell decline fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/NNFqCLyd5wGw5a.gif',
    id: '0303',
    name: 'dumbbell decline hammer press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/CXN5E7NbeKulUQ.gif',
    id: '1276',
    name: 'dumbbell decline one arm fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/QZcq3AMNVMlpQC.gif',
    id: '1617',
    name: 'dumbbell decline one arm hammer press',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/VtaGR1krymR32X.gif',
    id: '0305',
    name: 'dumbbell decline shrug',
    target: 'traps',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/jzgafgAKkI2LMG.gif',
    id: '0304',
    name: 'dumbbell decline shrug v. 2',
    target: 'traps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/7XZ7FFiM4Tx2UD.gif',
    id: '0306',
    name: 'dumbbell decline triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/2KgEOXubRsEYiU.gif',
    id: '0307',
    name: 'dumbbell decline twist fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/oc5yrCcoW8swC4.gif',
    id: '1437',
    name: 'dumbbell finger curls',
    target: 'forearms',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/UcC8tSbvVneL3A.gif',
    id: '0308',
    name: 'dumbbell fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/O-UWNPp0iUd12K.gif',
    id: '1277',
    name: 'dumbbell fly on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/h5Ov2RdWjEyRW5.gif',
    id: '1732',
    name: 'dumbbell forward lunge triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/G0kC43DM58HLC4.gif',
    id: '0310',
    name: 'dumbbell front raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/ycPmmhoxHz8BfD.gif',
    id: '0309',
    name: 'dumbbell front raise v. 2',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/UPq3L3wg-Gvh2h.gif',
    id: '0311',
    name: 'dumbbell full can lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/T3mDANa3skz6uT.gif',
    id: '1760',
    name: 'dumbbell goblet squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/ZohNnamvq0Vq4n.gif',
    id: '0313',
    name: 'dumbbell hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/WCzmgCSlVt-ZxD.gif',
    id: '1659',
    name: 'dumbbell hammer curl on exercise ball',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/5DVV9o2BRwg9qC.gif',
    id: '0312',
    name: 'dumbbell hammer curl v. 2',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/q1hvduf-Q7LdJ1.gif',
    id: '2402',
    name: 'dumbbell hammer curls (with arm blaster)',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Iw2UtinM7pGqtB.gif',
    id: '1664',
    name: 'dumbbell high curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/HkECMfUO244Eia.gif',
    id: '3545',
    name: 'dumbbell incline alternate press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/FnyEpLdYAMyaJw.gif',
    id: '0314',
    name: 'dumbbell incline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/mmkfMvUJzFnfKY.gif',
    id: '0315',
    name: 'dumbbell incline biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Mk61H6t1-K2dNF.gif',
    id: '0316',
    name: 'dumbbell incline breeding',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/htjU0KtgoHQAVj.gif',
    id: '0318',
    name: 'dumbbell incline curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/tTNF4vYJwgVj3d.gif',
    id: '0317',
    name: 'dumbbell incline curl v. 2',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/fJGxbmdihwu9Wq.gif',
    id: '0319',
    name: 'dumbbell incline fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Ga2N345PMzQC2m.gif',
    id: '1278',
    name: 'dumbbell incline fly on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/GZRr2lqrHj0VpA.gif',
    id: '0320',
    name: 'dumbbell incline hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/RZZIDrO4KZPEzQ.gif',
    id: '0321',
    name: 'dumbbell incline hammer press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/2e0IuWhFulLiQY.gif',
    id: '1618',
    name: 'dumbbell incline hammer press on exercise ball',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/hGlTOvQ834UjEw.gif',
    id: '0322',
    name: 'dumbbell incline inner biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/MXk1aQyoSmVOCE.gif',
    id: '1279',
    name: 'dumbbell incline one arm fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Br64L3g4AHbbpS.gif',
    id: '1280',
    name: 'dumbbell incline one arm fly on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/jjjULf5kx6ySBK.gif',
    id: '1619',
    name: 'dumbbell incline one arm hammer press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/0j4HOmC2Qmdatx.gif',
    id: '1620',
    name: 'dumbbell incline one arm hammer press on exercise ball',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/gS1GW67Xd2mSKB.gif',
    id: '0323',
    name: 'dumbbell incline one arm lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/SlxSZl-mj2646p.gif',
    id: '1281',
    name: 'dumbbell incline one arm press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/wSFUaCVUJnZIsa.gif',
    id: '1282',
    name: 'dumbbell incline one arm press on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/cxl5H7BSqVKDdq.gif',
    id: '0324',
    name: 'dumbbell incline palm-in press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/GWVBsK4aYvRcW-.gif',
    id: '1283',
    name: 'dumbbell incline press on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/O5iYBZEP2lngR1.gif',
    id: '0325',
    name: 'dumbbell incline raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/qRQtVsXZZ4nR-W.gif',
    id: '0326',
    name: 'dumbbell incline rear lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/LVehfMsBuxeTZc.gif',
    id: '0327',
    name: 'dumbbell incline row',
    target: 'upper back',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/w-wmXcMbf7xGRX.gif',
    id: '0328',
    name: 'dumbbell incline shoulder raise',
    target: 'serratus anterior',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/NMdLf1LGxtM8ES.gif',
    id: '0329',
    name: 'dumbbell incline shrug',
    target: 'traps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/AfEOeJdYUn7-Ce.gif',
    id: '3542',
    name: 'dumbbell incline t-raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/jSPlnht8UTYXHv.gif',
    id: '0330',
    name: 'dumbbell incline triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/SPS3mXwMLBo0FC.gif',
    id: '0331',
    name: 'dumbbell incline twisted flyes',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/rOL-kVXneWE5-M.gif',
    id: '1733',
    name: 'dumbbell incline two arm extension',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/gZhhvHoRdPIoPN.gif',
    id: '3541',
    name: 'dumbbell incline y-raise',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/7RqdaeqS7JszBk.gif',
    id: '0332',
    name: 'dumbbell iron cross',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/qIFzc-NPmQWOqM.gif',
    id: '0333',
    name: 'dumbbell kickback',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/AfE4EyUAKcdc0N.gif',
    id: '1734',
    name: 'dumbbell kickbacks on exercise ball',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/k2Lpeh3mO9twh3.gif',
    id: '1660',
    name: 'dumbbell kneeling bicep curl exercise ball',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/wO3j151F0I7hCI.gif',
    id: '0334',
    name: 'dumbbell lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/8ZBmjPnOOlCcxT.gif',
    id: '0335',
    name: 'dumbbell lateral to front raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/CDEByuS0AbxwQg.gif',
    id: '0336',
    name: 'dumbbell lunge',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/qD19klfNDVXEYs.gif',
    id: '1658',
    name: 'dumbbell lunge with bicep curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/guY0aE7aPGHZYt.gif',
    id: '0337',
    name: 'dumbbell lying  extension (across face)',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/cWVN-8SnLoFbUy.gif',
    id: '1729',
    name: 'dumbbell lying alternate extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/YKLI4I9iYaf5pA.gif',
    id: '0338',
    name: 'dumbbell lying elbow press',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/VydAKb8sTP0Tz9.gif',
    id: '0863',
    name: 'dumbbell lying external shoulder rotation',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/MMAJS-HAgcZmHa.gif',
    id: '0339',
    name: 'dumbbell lying femoral',
    target: 'hamstrings',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/JinZZs8BC7ITun.gif',
    id: '0340',
    name: 'dumbbell lying hammer press',
    target: 'pectorals',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/LplOs-pkCiLWjy.gif',
    id: '2470',
    name: 'dumbbell lying on floor rear delt raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/lahTStvfX5ti8b.gif',
    id: '0341',
    name: 'dumbbell lying one arm deltoid rear',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/yVcgE1OukY8j3O.gif',
    id: '0343',
    name: 'dumbbell lying one arm press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/245amMiQwhG-sP.gif',
    id: '0342',
    name: 'dumbbell lying one arm press v. 2',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/gjSzCVfX7kXxC4.gif',
    id: '0344',
    name: 'dumbbell lying one arm pronated triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/pmLM7REUtsj6Qb.gif',
    id: '0345',
    name: 'dumbbell lying one arm rear lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/gdmlhfbqdPHmXU.gif',
    id: '0346',
    name: 'dumbbell lying one arm supinated triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/aXIGA6H85nqq2W.gif',
    id: '0347',
    name: 'dumbbell lying pronation',
    target: 'forearms',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/buRrvA9caPoeCZ.gif',
    id: '2705',
    name: 'dumbbell lying pronation on floor',
    target: 'forearms',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/2sTXAeq-6ZiKWk.gif',
    id: '1284',
    name: 'dumbbell lying pullover on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/EjFl05-Lt9HxRl.gif',
    id: '1328',
    name: 'dumbbell lying rear delt row',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/e5qR3Yvi8xSkha.gif',
    id: '0348',
    name: 'dumbbell lying rear lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/OAjffCctC51Y0G.gif',
    id: '1735',
    name: 'dumbbell lying single extension',
    target: 'triceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Wtn4gCnZwfCh3q.gif',
    id: '0349',
    name: 'dumbbell lying supination',
    target: 'forearms',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/jjMB1QzQ0QjsOm.gif',
    id: '2706',
    name: 'dumbbell lying supination on floor',
    target: 'forearms',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/9bpl5Fg1zb4wCP.gif',
    id: '1661',
    name: 'dumbbell lying supine biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/yG0bt9YoIRt9U-.gif',
    id: '0350',
    name: 'dumbbell lying supine curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/yz5ugmwmMlE4Wx.gif',
    id: '0351',
    name: 'dumbbell lying triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/KCkRhF2EzZH17g.gif',
    id: '1662',
    name: 'dumbbell lying wide curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Xgi0eFauD56PTq.gif',
    id: '0352',
    name: 'dumbbell neutral grip bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/mlPUumnF8Hk12k.gif',
    id: '1285',
    name: 'dumbbell one arm bench fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/o2wPxUgwIZ2FfW.gif',
    id: '0292',
    name: 'dumbbell one arm bent-over row',
    target: 'upper back',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/n2yCN90pJQ5HnQ.gif',
    id: '1286',
    name: 'dumbbell one arm chest fly on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Eb1GAQ6jlS9N4R.gif',
    id: '0353',
    name: 'dumbbell one arm concetration curl (on stability ball)',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/NjBJB0gAgPuSho.gif',
    id: '1287',
    name: 'dumbbell one arm decline chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/gD1xMqtwsrIumT.gif',
    id: '1288',
    name: 'dumbbell one arm fly on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/VuGUpiTaFlLeOg.gif',
    id: '1736',
    name: 'dumbbell one arm french press on exercise ball',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/fbwKZujLAXZscv.gif',
    id: '1663',
    name: 'dumbbell one arm hammer preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/CSRZN09yNj7rfk.gif',
    id: '1621',
    name: 'dumbbell one arm hammer press on exercise ball',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/-QfNaXG-KYMaMJ.gif',
    id: '1289',
    name: 'dumbbell one arm incline chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/HUTxELZpne6ddo.gif',
    id: '0354',
    name: 'dumbbell one arm kickback',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/tzhwDGl1sdZynK.gif',
    id: '0355',
    name: 'dumbbell one arm lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/WGUTE0R94UmnUW.gif',
    id: '0356',
    name: 'dumbbell one arm lateral raise with support',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/DbYgwp3jY4TLLZ.gif',
    id: '1290',
    name: 'dumbbell one arm press on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/rBRKdt9w1rGvLj.gif',
    id: '1665',
    name: 'dumbbell one arm prone curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/qkKtx97030NIz6.gif',
    id: '1666',
    name: 'dumbbell one arm prone hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/QXAcGRAhvDoXM5.gif',
    id: '1291',
    name: 'dumbbell one arm pullover on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/IWsmwA9CYvkNVE.gif',
    id: '0358',
    name: 'dumbbell one arm revers wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/RiYf7JwO2UwUdT.gif',
    id: '0359',
    name: 'dumbbell one arm reverse fly (with support)',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/9ATJ-ffjXd1obd.gif',
    id: '1622',
    name: 'dumbbell one arm reverse grip press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/XmBbg-9Rz6jquN.gif',
    id: '1414',
    name: 'dumbbell one arm reverse preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/EcC4stYqlvkuI0.gif',
    id: '1667',
    name: 'dumbbell one arm reverse spider curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/b65kuqrFM-E35j.gif',
    id: '1668',
    name: 'dumbbell one arm seated bicep curl on exercise ball',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/y4cnOSZP2H6XnK.gif',
    id: '1669',
    name: 'dumbbell one arm seated hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Nhr7gCIzMqRsPO.gif',
    id: '1415',
    name: 'dumbbell one arm seated neutral wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/MGdxUqYjNYf17P.gif',
    id: '0361',
    name: 'dumbbell one arm shoulder press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/bkgxM5S5hyDT1-.gif',
    id: '0360',
    name: 'dumbbell one arm shoulder press v. 2',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/ko6Cj-i4slfTr9.gif',
    id: '3888',
    name: 'dumbbell one arm snatch',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/8R-1hhXIaPuhJR.gif',
    id: '1670',
    name: 'dumbbell one arm standing curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/04QqGNHLCPgY3D.gif',
    id: '1671',
    name: 'dumbbell one arm standing hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/KUrwGkAlYinZMv.gif',
    id: '0362',
    name: 'dumbbell one arm triceps extension (on bench)',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/aq1NRYEN3L0Bhe.gif',
    id: '0363',
    name: 'dumbbell one arm upright row',
    target: 'delts',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/OpQqN25bJO5qZo.gif',
    id: '0364',
    name: 'dumbbell one arm wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/7XGQ2kZhjsXPWn.gif',
    id: '1672',
    name: 'dumbbell one arm zottman preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Ms01cHRtu2UMtp.gif',
    id: '1292',
    name: 'dumbbell one leg fly on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Pa--IU-2T4aYFW.gif',
    id: '0365',
    name: 'dumbbell over bench neutral wrist curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/ID9nb7Kk5RP-0A.gif',
    id: '0366',
    name: 'dumbbell over bench one arm  neutral wrist curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/K8tVxyVatzYsK7.gif',
    id: '1441',
    name: 'dumbbell over bench one arm reverse wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/UPqOPXrbo5bKMZ.gif',
    id: '0367',
    name: 'dumbbell over bench one arm wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/C7ZwiCc-WVbS8v.gif',
    id: '0368',
    name: 'dumbbell over bench revers wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/v2TSPxcFMZoOtF.gif',
    id: '0369',
    name: 'dumbbell over bench wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/SFpwdm4TBc0DFP.gif',
    id: '1329',
    name: 'dumbbell palm rotational bent over row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Bt7NY9YIkLWIJx.gif',
    id: '1623',
    name: 'dumbbell palms in incline bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/o6GBnSUbGHUcRn.gif',
    id: '0370',
    name: 'dumbbell peacher hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/xHqOnzqRa9PIE9.gif',
    id: '0371',
    name: 'dumbbell plyo squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/rMkhF27-ckdt95.gif',
    id: '0372',
    name: 'dumbbell preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/e3C3TArWkaP2Rs.gif',
    id: '1673',
    name: 'dumbbell preacher curl over exercise ball',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/NgTOkFp7NUIl8q.gif',
    id: '1293',
    name: 'dumbbell press on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/lhVgCzLVDVN7T4.gif',
    id: '0373',
    name: 'dumbbell pronate-grip triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/x-4-ZSijOyW4jR.gif',
    id: '0374',
    name: 'dumbbell prone incline curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/fo5OKXq9fUxsGb.gif',
    id: '1674',
    name: 'dumbbell prone incline hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/puFUF3pMiDXrh7.gif',
    id: '0375',
    name: 'dumbbell pullover',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/mckFgbCDXY0FRp.gif',
    id: '1294',
    name: 'dumbbell pullover hip extension on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/KLsjRwk1G8pgmI.gif',
    id: '1295',
    name: 'dumbbell pullover on exercise ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/x5f8PsDw4mmKmg.gif',
    id: '1700',
    name: 'dumbbell push press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/vGcGakX2mZKQmE.gif',
    id: '0376',
    name: 'dumbbell raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/YYySUFTQs5vYdI.gif',
    id: '2292',
    name: 'dumbbell rear delt raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/zteZUUZ4UyGFDC.gif',
    id: '0377',
    name: 'dumbbell rear delt row_shoulder',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/sbERTb-kMgXzps.gif',
    id: '0378',
    name: 'dumbbell rear fly',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/SrPFGVDYmXc8dS.gif',
    id: '0380',
    name: 'dumbbell rear lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/vdsLFGdV2g2Z8m.gif',
    id: '0379',
    name: 'dumbbell rear lateral raise (support head)',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/eCZs92F1jqrWd2.gif',
    id: '0381',
    name: 'dumbbell rear lunge',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/SKrWrZGlFGvOEB.gif',
    id: '0382',
    name: 'dumbbell revers grip biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/hZ2QhP6DWBDIHC.gif',
    id: '1624',
    name: 'dumbbell reverse bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/IvLS8V74HWExfT.gif',
    id: '0383',
    name: 'dumbbell reverse fly',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/A92w1u60Is-LEP.gif',
    id: '1330',
    name: 'dumbbell reverse grip incline bench one arm row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/sHpx24BS16r0Pn.gif',
    id: '1331',
    name: 'dumbbell reverse grip incline bench two arm row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/KirMCisGnl-NDg.gif',
    id: '2327',
    name: 'dumbbell reverse grip row (female)',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/KJV4Mmdp6Sw3dw.gif',
    id: '0384',
    name: 'dumbbell reverse preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/f90cjWQjdyHZ4g.gif',
    id: '1675',
    name: 'dumbbell reverse spider curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/ZXDylNCjrBAa13.gif',
    id: '0385',
    name: 'dumbbell reverse wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/8fDhV-bxrQPMDC.gif',
    id: '1459',
    name: 'dumbbell romanian deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/kCogelyfsERnVH.gif',
    id: '0386',
    name: 'dumbbell rotation reverse fly',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/I7xlHp3aXWGJZs.gif',
    id: '2397',
    name: 'dumbbell scott press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/wtMS6wusvgMukj.gif',
    id: '0387',
    name: 'dumbbell seated alternate front raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/ORyFxLeYTZSlyo.gif',
    id: '1676',
    name: 'dumbbell seated alternate hammer curl on exercise ball',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/94hwjA-K0UhAPD.gif',
    id: '0388',
    name: 'dumbbell seated alternate press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/7IdnPC3Gw2IR1T.gif',
    id: '3546',
    name: 'dumbbell seated alternate shoulder',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/ICdRtMBUZFIz0W.gif',
    id: '0389',
    name: 'dumbbell seated bench extension',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/PeBXtrT9T0oWVx.gif',
    id: '2317',
    name: 'dumbbell seated bent arm lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/S8Cer5i62IteAu.gif',
    id: '1730',
    name: 'dumbbell seated bent over alternate kickback',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/5HtfeyGPBetpmF.gif',
    id: '1737',
    name: 'dumbbell seated bent over triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/528QUae8tW2-lm.gif',
    id: '1677',
    name: 'dumbbell seated bicep curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/vjKtd1EJi3X1mR.gif',
    id: '0390',
    name: 'dumbbell seated biceps curl (on stability ball)',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/O09s0hCpoYA6G8.gif',
    id: '3547',
    name: 'dumbbell seated biceps curl to shoulder press',
    target: 'biceps',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/PwsmEqM2kNRMjv.gif',
    id: '1379',
    name: 'dumbbell seated calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/ep27aqTpz8pPNU.gif',
    id: '0391',
    name: 'dumbbell seated curl',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/yvLBAAzG77B88C.gif',
    id: '0392',
    name: 'dumbbell seated front raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/-KAvRIlfjwzLmu.gif',
    id: '1678',
    name: 'dumbbell seated hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/sgyuOcs5ZJYfPs.gif',
    id: '0393',
    name: 'dumbbell seated inner biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/B6nRwOiuN7fylY.gif',
    id: '0394',
    name: 'dumbbell seated kickback',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/b3KvbRylXHO2Ms.gif',
    id: '0396',
    name: 'dumbbell seated lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/kUYRh9ijx-nUY8.gif',
    id: '0395',
    name: 'dumbbell seated lateral raise v. 2',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/8Ld4PqKZGnKNRG.gif',
    id: '0397',
    name: 'dumbbell seated neutral wrist curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/9WnGXfKnJFtXbr.gif',
    id: '1679',
    name: 'dumbbell seated one arm bicep curl on exercise ball with leg raised',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/hEZT0jbqk2soWD.gif',
    id: '0398',
    name: 'dumbbell seated one arm kickback',
    target: 'triceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/YaWxQoV3oBpvmi.gif',
    id: '0399',
    name: 'dumbbell seated one arm rotate',
    target: 'forearms',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/x-KvnyRijK5WSV.gif',
    id: '0400',
    name: 'dumbbell seated one leg calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Ezxn4LQ1Rm8Yw8.gif',
    id: '1380',
    name: 'dumbbell seated one leg calf raise - hammer grip',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/W37Paglkk-kXiT.gif',
    id: '1381',
    name: 'dumbbell seated one leg calf raise - palm up',
    target: 'calves',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/3rnbRa4AyBEbNW.gif',
    id: '0401',
    name: 'dumbbell seated palms up wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/WNojVzgw96tzNR.gif',
    id: '0402',
    name: 'dumbbell seated preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/CzeuZZDGvrlfm-.gif',
    id: '0403',
    name: 'dumbbell seated revers grip concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/76nnsC2ULbeiZh.gif',
    id: '1738',
    name: 'dumbbell seated reverse grip one arm overhead tricep extension',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/tWBXWRJJi0kAmh.gif',
    id: '0405',
    name: 'dumbbell seated shoulder press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/V2RNI2YibtuKS7.gif',
    id: '0404',
    name: 'dumbbell seated shoulder press (parallel grip)',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/brlT5WGK7hgYmU.gif',
    id: '2188',
    name: 'dumbbell seated triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/nDPYtGwoEUjvnf.gif',
    id: '0406',
    name: 'dumbbell shrug',
    target: 'traps',
  },
  {
    bodyPart: 'waist',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/l4vQc43u5G8ctc.gif',
    id: '0407',
    name: 'dumbbell side bend',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/axgqRZU0QEsQep.gif',
    id: '0408',
    name: 'dumbbell side lying one hand raise',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/7aubmMfNNwDnVh.gif',
    id: '3664',
    name: 'dumbbell side plank with rear fly',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/pXYb3E2fCKAfit.gif',
    id: '3548',
    name: 'dumbbell single arm overhead carry',
    target: 'delts',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/eKsedYOq4sSmu8.gif',
    id: '0409',
    name: 'dumbbell single leg calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/UrUOb9IzxyGGBv.gif',
    id: '1757',
    name: 'dumbbell single leg deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/fNQMkLHo7XcpTy.gif',
    id: '2805',
    name: 'dumbbell single leg deadlift with stepbox support',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/nBhHe5sUu4lLKk.gif',
    id: '0410',
    name: 'dumbbell single leg split squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/2K8YXW9p5ALqRS.gif',
    id: '0411',
    name: 'dumbbell single leg squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/ENrBO-XWEnJaOZ.gif',
    id: '0413',
    name: 'dumbbell squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/N1G3xyeNjtAjpo.gif',
    id: '3560',
    name: 'dumbbell standing alternate hammer curl and press',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/2H3Zz0xeTVqh7E.gif',
    id: '0414',
    name: 'dumbbell standing alternate overhead press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/0OdaTXA6xkB8pl.gif',
    id: '0415',
    name: 'dumbbell standing alternate raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/iiTqHDzH1RPV46.gif',
    id: '1739',
    name: 'dumbbell standing alternating tricep kickback',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/N3tFMSfucI51s8.gif',
    id: '2143',
    name: 'dumbbell standing around world',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/iNQMNVUZFAL6vf.gif',
    id: '1740',
    name: 'dumbbell standing bent over one arm triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/7uxr5USEjGTtQD.gif',
    id: '1741',
    name: 'dumbbell standing bent over two arm triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/UNuXvbHfNkoIw0.gif',
    id: '0416',
    name: 'dumbbell standing biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/pjytJZ8LubpaCF.gif',
    id: '0417',
    name: 'dumbbell standing calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/SfLC2R4FFW3Xwg.gif',
    id: '0418',
    name: 'dumbbell standing concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/6iLPiwapJAhOSm.gif',
    id: '0419',
    name: 'dumbbell standing front raise above head',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/fcJd5DeSvkvT9F.gif',
    id: '2321',
    name: 'dumbbell standing inner biceps curl v. 2',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/yS1yydN7D-o49G.gif',
    id: '0420',
    name: 'dumbbell standing kickback',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Blq-t2yB4nBKt-.gif',
    id: '0421',
    name: 'dumbbell standing one arm concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/wvKpf65tN5jnib.gif',
    id: '0422',
    name: 'dumbbell standing one arm curl (over incline bench)',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Y0EXzYCz3dprkT.gif',
    id: '1680',
    name: 'dumbbell standing one arm curl over incline bench',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Aug9QqX79fKnxW.gif',
    id: '0423',
    name: 'dumbbell standing one arm extension',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/QgsgM4V2ycvjlQ.gif',
    id: '0424',
    name: 'dumbbell standing one arm palm in press',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/s4ARszpJPR4M5p.gif',
    id: '0425',
    name: 'dumbbell standing one arm reverse curl',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Z4hihyFCnoyOmS.gif',
    id: '0426',
    name: 'dumbbell standing overhead press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/byQok4sWVamJYY.gif',
    id: '0427',
    name: 'dumbbell standing palms in press',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/R10qL3m9ByKQCa.gif',
    id: '0428',
    name: 'dumbbell standing preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/9N3rwCe6QQA8nN.gif',
    id: '0429',
    name: 'dumbbell standing reverse curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/qsE21a-WeYqROK.gif',
    id: '0430',
    name: 'dumbbell standing triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/YGDrtPnrSUBPRM.gif',
    id: '2293',
    name: 'dumbbell standing zottman preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/04ujvSLim-2j6U.gif',
    id: '1684',
    name: 'dumbbell step up single leg balance with bicep curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/1H0FyySyshtWDZ.gif',
    id: '0431',
    name: 'dumbbell step-up',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/9l9ZkvwBQiik1T.gif',
    id: '2796',
    name: 'dumbbell step-up lunge',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/oONYTaagQJ93HZ.gif',
    id: '2812',
    name: 'dumbbell step-up split squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/8pucg3MHwImbAM.gif',
    id: '0432',
    name: 'dumbbell stiff leg deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/F7Ud3qLoFC6cB6.gif',
    id: '0433',
    name: 'dumbbell straight arm pullover',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/3KUZX9tnbMX7xI.gif',
    id: '0434',
    name: 'dumbbell straight leg deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/xpVW0xQC0MwI-m.gif',
    id: '2808',
    name: 'dumbbell sumo pull through',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/On4CLHUAgwVNwZ.gif',
    id: '2803',
    name: 'dumbbell supported squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/euiQFJGn-1PAOj.gif',
    id: '0436',
    name: 'dumbbell tate press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/0x4TjaWmX-L6P3.gif',
    id: '1742',
    name: 'dumbbell tricep kickback with stork stance',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/D5l1GpMJ8ggM3B.gif',
    id: '1743',
    name: 'dumbbell twisting bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/U9Br1C8JpwbFDZ.gif',
    id: '0437',
    name: 'dumbbell upright row',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/dAeORVCMJUnK5d.gif',
    id: '1765',
    name: 'dumbbell upright row (back pov)',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/vBONJqqLaNcR9P.gif',
    id: '0864',
    name: 'dumbbell upright shoulder external rotation',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/KLaQZJjIL82ZTd.gif',
    id: '5201',
    name: 'dumbbell waiter biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/cNKwEHZI4CVGOn.gif',
    id: '0438',
    name: 'dumbbell w-press',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/gmoViYCRDz9bmD.gif',
    id: '0439',
    name: 'dumbbell zottman curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/63FjzpVv-Ftpfg.gif',
    id: '2294',
    name: 'dumbbell zottman preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/gGGaH0IWZWLdor.gif',
    id: '2189',
    name: 'dumbbells seated triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/xVnRFQ6yquVDFI.gif',
    id: '1167',
    name: 'dynamic chest stretch (male)',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/AjcubeAQsvBTlF.gif',
    id: '3287',
    name: 'elbow dips',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/pCftWmiDSwndc7.gif',
    id: '1772',
    name: 'elbow lift - reverse push-up',
    target: 'upper back',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/xr4wZp1g5Xrozm.gif',
    id: '0443',
    name: 'elbow-to-knee',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/t3380vWzAhXesj.gif',
    id: '3292',
    name: 'elevator',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/EYMTURveGy9UI0.gif',
    id: '1332',
    name: 'exercise ball alternating arm ups',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/srpN46ncP6TPt3.gif',
    id: '1333',
    name: 'exercise ball back extension with arms extended',
    target: 'spine',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/QwRSYPSXlcCAHf.gif',
    id: '1334',
    name: 'exercise ball back extension with hands behind head',
    target: 'spine',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/ZNpSeANcz9PHrC.gif',
    id: '1335',
    name: 'exercise ball back extension with knees off ground',
    target: 'spine',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/wMwuJigdgYMYjM.gif',
    id: '1336',
    name: 'exercise ball back extension with rotation',
    target: 'spine',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/rSici-8sa9hmLu.gif',
    id: '1744',
    name: 'exercise ball dip',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/8VwGfeoJiDx6hM.gif',
    id: '1559',
    name: 'exercise ball hip flexor stretch',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/bpt2famMA60a1a.gif',
    id: '1338',
    name: 'exercise ball hug',
    target: 'spine',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/Zrs5k5bfWaJcFH.gif',
    id: '1339',
    name: 'exercise ball lat stretch',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/E97q9wOeRVSyjF.gif',
    id: '1341',
    name: 'exercise ball lower back stretch (pyramid)',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/D4OFPVz12S5Wg2.gif',
    id: '1342',
    name: 'exercise ball lying side lat stretch',
    target: 'lats',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/-D6XJB4RzxDBWP.gif',
    id: '1382',
    name: 'exercise ball on the wall calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/EW40sKhHVtWNxF.gif',
    id: '3241',
    name: 'exercise ball on the wall calf raise (tennis ball between ankles)',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/YpMhfT3aDbPvkZ.gif',
    id: '3240',
    name: 'exercise ball on the wall calf raise (tennis ball between knees)',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/loDQLmPP4lf7cc.gif',
    id: '1416',
    name: 'exercise ball one leg prone lower body rotation',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/OYNR6aFHBXUZ3a.gif',
    id: '1417',
    name: 'exercise ball one legged diagonal kick hamstring curl',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/zHSCg0qakL2lCA.gif',
    id: '1296',
    name: 'exercise ball pike push up',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/IHyQNavTB53E0R.gif',
    id: '1343',
    name: 'exercise ball prone leg raise',
    target: 'spine',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/WPGcRPQCFVctHx.gif',
    id: '1560',
    name: 'exercise ball seated hamstring stretch',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/ldNKyrqbBUSCu1.gif',
    id: '1745',
    name: 'exercise ball seated triceps stretch',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/PPTocuQVDPTwBz.gif',
    id: '1746',
    name: 'exercise ball supine triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/0GgMsVwZbHcXwX.gif',
    id: '1747',
    name: 'ez bar french press on exercise ball',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/ZI8NcxjWN0NYvS.gif',
    id: '3010',
    name: 'ez bar lying bent arms pullover',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/6EKBNo9fJn2Wbv.gif',
    id: '1748',
    name: 'ez bar lying close grip triceps extension behind head',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/dcBVXRlovgTbOj.gif',
    id: '1344',
    name: 'ez bar reverse grip bent over row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/hCbbQ8NHYttKfZ.gif',
    id: '1682',
    name: 'ez bar seated close grip concentration curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/HJ3SrroHP6z-Hg.gif',
    id: '1749',
    name: 'ez bar standing french press',
    target: 'triceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/W4epdj-m6RqijX.gif',
    id: '0445',
    name: 'ez barbell anti gravity press',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/YQsCB4gZXXFTJj.gif',
    id: '1627',
    name: 'ez barbell close grip preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/IIeZYwORFt9xCs.gif',
    id: '0446',
    name: 'ez barbell close-grip curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/le514MMY60xjaA.gif',
    id: '0447',
    name: 'ez barbell curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/I1uhK81HJ7spjS.gif',
    id: '0448',
    name: 'ez barbell decline close grip face press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/4xOxO8ncmJ7Vmw.gif',
    id: '2186',
    name: 'ez barbell decline triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/BsItvRXNITUCcN.gif',
    id: '0449',
    name: 'ez barbell incline triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/nV4UucRsSr0PQK.gif',
    id: '0450',
    name: 'ez barbell jm bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/s3N10lxgV8FDJY.gif',
    id: '0451',
    name: 'ez barbell reverse grip curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/7x0ApXScZv3S7a.gif',
    id: '0452',
    name: 'ez barbell reverse grip preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/7TMO2A7ldRrcBa.gif',
    id: '1458',
    name: 'ez barbell seated curls',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/Zzm3O33ARVJIKn.gif',
    id: '0453',
    name: 'ez barbell seated triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/GDswsle5QcjjUY.gif',
    id: '0454',
    name: 'ez barbell spider curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/HgkTJz6kXqRk14.gif',
    id: '1628',
    name: 'ez barbell spider curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/jSK016eQ9K3IkO.gif',
    id: '2404',
    name: 'ez-bar biceps curl (with arm blaster)',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/5bwMRBkptxhQYC.gif',
    id: '2432',
    name: 'ez-bar close-grip bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'ez barbell',
    gifUrl: 'https://backend.fitlinez.com/all/F9RfRkflGO00gu.gif',
    id: '2741',
    name: 'ez-barbell standing wide grip biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/jpTq6et-05s4bd.gif',
    id: '2133',
    name: 'farmers walk',
    target: 'quads',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/mvpKbFSv2qXCFe.gif',
    id: '0455',
    name: 'finger curls',
    target: 'forearms',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/NXvJ66DJMxKdHO.gif',
    id: '3303',
    name: 'flag',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/HvbIjjiSXAWyWg.gif',
    id: '0456',
    name: 'flexion leg sit up (bent knee)',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/uuVg-r3EgQ9CyB.gif',
    id: '0457',
    name: 'flexion leg sit up (straight arm)',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/qSwmZy56d-4erE.gif',
    id: '0458',
    name: 'floor fly (with barbell)',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/rAxUUvCSyk3MRp.gif',
    id: '0459',
    name: 'flutter kicks',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/1yq3BTOr4NSoky.gif',
    id: '1472',
    name: 'forward jump',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/bCi9vIBCTDSvb3.gif',
    id: '3470',
    name: 'forward lunge (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/ih0duxfaXI0emX.gif',
    id: '3194',
    name: 'frankenstein squat',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/i4uuGqbt3yq027.gif',
    id: '2429',
    name: 'frog crunch',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/lnaDlId9XKXLtB.gif',
    id: '3301',
    name: 'frog planche',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/20-syxTQzxhYDF.gif',
    id: '3296',
    name: 'front lever',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/V5CrQaWMdDZ4Pu.gif',
    id: '3295',
    name: 'front lever reps',
    target: 'upper back',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/pkB9I9q4c7dvcl.gif',
    id: '0464',
    name: 'front plank with twist',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/BTFmcEXmk9wxM7.gif',
    id: '3315',
    name: 'full maltese',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/3Tn1JNBY9s9xVj.gif',
    id: '3299',
    name: 'full planche',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/zTyjaN3BzjfydV.gif',
    id: '3327',
    name: 'full planche push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/EwDvx07z0hdcYW.gif',
    id: '0466',
    name: 'gironda sternum chin',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/0wKggO6OtZ6NbA.gif',
    id: '3561',
    name: 'glute bridge march',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/b2po6ASzDZYOTx.gif',
    id: '3523',
    name: 'glute bridge two legs on bench (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/xUvPbO2v89HZDN.gif',
    id: '3193',
    name: 'glute-ham raise',
    target: 'hamstrings',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/VSTfhCL31QTFFh.gif',
    id: '0467',
    name: 'gorilla chin',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/nlSpKqgHEotzCs.gif',
    id: '0469',
    name: 'groin crunch',
    target: 'abs',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/xd-e6SvU7fQicO.gif',
    id: '1383',
    name: 'hack calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/DtBOoIzdW0oAAp.gif',
    id: '1384',
    name: 'hack one leg calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/cNGUHetDt35-Ow.gif',
    id: '3221',
    name: 'half knee bends (male)',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/YtIZg7NeirHV6g.gif',
    id: '3202',
    name: 'half sit-up (male)',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/XG54-MvXvm0dA8.gif',
    id: '1511',
    name: 'hamstring stretch',
    target: 'hamstrings',
  },
  {
    bodyPart: 'chest',
    equipment: 'upper body ergometer',
    gifUrl: 'https://backend.fitlinez.com/all/fg5e67z6f4PyUB.gif',
    id: '2139',
    name: 'hands bike',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/7NxBgRaIGcWvpN.gif',
    id: '3218',
    name: 'hands clasped circular toe touch (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/knPt6Gcrdmnc7O.gif',
    id: '3215',
    name: 'hands reversed clasped circular toe touch (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/g2a6VYbHFfae2d.gif',
    id: '3302',
    name: 'handstand',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/udIPCIpQYUQJkl.gif',
    id: '0471',
    name: 'handstand push-up',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/RD-4cYjL0SZ40M.gif',
    id: '1764',
    name: 'hanging leg hip raise',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/3Dgr-Wo1ZBToSs.gif',
    id: '0472',
    name: 'hanging leg raise',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ZptKeVaiAUHWzZ.gif',
    id: '1761',
    name: 'hanging oblique knee raise',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/lnkYtBGKPPMYEw.gif',
    id: '0473',
    name: 'hanging pike',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/6-iEnZI-3YCJKg.gif',
    id: '0474',
    name: 'hanging straight leg hip raise',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/L8CiooIYOQuAxJ.gif',
    id: '0475',
    name: 'hanging straight leg raise',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/0Q0dc9bjqSUbdt.gif',
    id: '0476',
    name: 'hanging straight twisting leg hip raise',
    target: 'abs',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/dR2gaGUrjWW173.gif',
    id: '3636',
    name: 'high knee against wall',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/bqDxa5d8Iepfg7.gif',
    id: '0484',
    name: 'hip raise (bent knee)',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5pl715ypJG7g9p.gif',
    id: '1418',
    name: 'hug keens to chest',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/K7r8zlWUguXQoX.gif',
    id: '3234',
    name: 'hyght dumbbell fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/UcHtfxq8CrcOBK.gif',
    id: '0489',
    name: 'hyperextension',
    target: 'spine',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5sDjt0Y9SifEyJ.gif',
    id: '0488',
    name: 'hyperextension (on bench)',
    target: 'spine',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/uwBcX9yIk1PalB.gif',
    id: '3289',
    name: 'impossible dips',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/rkMArHhnaXWY7e.gif',
    id: '1471',
    name: 'inchworm',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/u5ND7XUs5ZVJmK.gif',
    id: '3698',
    name: 'inchworm v. 2',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/0DwkhqOMCIiNLd.gif',
    id: '0490',
    name: 'incline close-grip push-up',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/iGIRHL6ukMamiu.gif',
    id: '0491',
    name: 'incline leg hip raise (leg straight)',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/EJzodd8TXOgrP0.gif',
    id: '0492',
    name: 'incline push up depth jump',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/G98UwZJKAeVGBZ.gif',
    id: '0493',
    name: 'incline push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/LAWVGBq3dixLoJ.gif',
    id: '3785',
    name: 'incline push-up (on box)',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ncEVPWXtRQlJJl.gif',
    id: '0494',
    name: 'incline reverse grip push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ISr-KxndYYSiZ1.gif',
    id: '3011',
    name: 'incline scapula push up',
    target: 'serratus anterior',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/FS7QqIr9itt2Qc.gif',
    id: '0495',
    name: 'incline twisting sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/C0a7kUu51VWWXM.gif',
    id: '1564',
    name: 'intermediate hip flexor and quad stretch',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/LjO-akOvg9evDj.gif',
    id: '0496',
    name: 'inverse leg curl (bench support)',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/kBEnGWElDw8UfO.gif',
    id: '2400',
    name: 'inverse leg curl (on pull-up cable machine)',
    target: 'hamstrings',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/s34FFYMjTyrjoW.gif',
    id: '0499',
    name: 'inverted row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/efDeqSHW6GrBWN.gif',
    id: '2300',
    name: 'inverted row bent knees',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/CBtYE6uSDcSxih.gif',
    id: '2298',
    name: 'inverted row on bench',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/wblYQNUBxaTriN.gif',
    id: '0497',
    name: 'inverted row v. 2',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/G4s47bcFvTT3op.gif',
    id: '0498',
    name: 'inverted row with straps',
    target: 'upper back',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/b5rEaLXzOCBurB.gif',
    id: '1419',
    name: 'iron cross stretch',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/i-rOGjvJskZ59F.gif',
    id: '1297',
    name: 'isometric chest squeeze',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/M6pNHIIMBg9Vlh.gif',
    id: '0500',
    name: 'isometric wipers',
    target: 'pectorals',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/PSbU5VpBf1akNR.gif',
    id: '0501',
    name: 'jack burpee',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/anIjcY8fyaF0I4.gif',
    id: '3224',
    name: 'jack jump (male)',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/qfAS2sn42KND0W.gif',
    id: '0507',
    name: 'jackknife sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/MXtZ0AtgrgSyVt.gif',
    id: '0508',
    name: 'janda sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'cardio',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/W-tNZNZIaY-weA.gif',
    id: '2612',
    name: 'jump rope',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/UFZ3Kc-WxTxgTR.gif',
    id: '0514',
    name: 'jump squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/bxXZe3yIxBXJtx.gif',
    id: '0513',
    name: 'jump squat v. 2',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/UG9HeHRCJ82hzI.gif',
    id: '0517',
    name: 'kettlebell advanced windmill',
    target: 'abs',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/amm9IY18KsQn--.gif',
    id: '0518',
    name: 'kettlebell alternating hang clean',
    target: 'forearms',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/FNnI-l1A2BJUfd.gif',
    id: '0520',
    name: 'kettlebell alternating press',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/UfYvJDx9uujiQR.gif',
    id: '0519',
    name: 'kettlebell alternating press on floor',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/dOLjmt9c3ln0Sn.gif',
    id: '0521',
    name: 'kettlebell alternating renegade row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/zZpAAMSHusw5n-.gif',
    id: '0522',
    name: 'kettlebell alternating row',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/RzsqldLeZxqlDW.gif',
    id: '0523',
    name: 'kettlebell arnold press',
    target: 'delts',
  },
  {
    bodyPart: 'waist',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/GvDhnGyuiAj93j.gif',
    id: '0524',
    name: 'kettlebell bent press',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/5gBFWXwbfw-kDb.gif',
    id: '0525',
    name: 'kettlebell bottoms up clean from the hang position',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/PYl7pm0YwJx9Mx.gif',
    id: '0526',
    name: 'kettlebell double alternating hang clean',
    target: 'biceps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/PbMJ01O4G-7OJa.gif',
    id: '0527',
    name: 'kettlebell double jerk',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/bBqqQ6hRiH7JkY.gif',
    id: '0528',
    name: 'kettlebell double push press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/cmdJ3kztdlHd9i.gif',
    id: '0529',
    name: 'kettlebell double snatch',
    target: 'delts',
  },
  {
    bodyPart: 'waist',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/n9LskEK6F9v8Rg.gif',
    id: '0530',
    name: 'kettlebell double windmill',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/vINn3ASAoFsfX4.gif',
    id: '0531',
    name: 'kettlebell extended range one arm press on floor',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/tUMxK1pRxryYvK.gif',
    id: '0532',
    name: 'kettlebell figure 8',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/p2UIUy1WZFEU4Y.gif',
    id: '0533',
    name: 'kettlebell front squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/M4J5mh8nhmT3JN.gif',
    id: '0534',
    name: 'kettlebell goblet squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/4n-tcGobbs6V0l.gif',
    id: '0535',
    name: 'kettlebell hang clean',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/E1HSo6TXtVUwK7.gif',
    id: '0536',
    name: 'kettlebell lunge pass through',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/gPB2N0ihTfexMZ.gif',
    id: '0537',
    name: 'kettlebell one arm clean and jerk',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/3Qkya8Lt66JExB.gif',
    id: '1298',
    name: 'kettlebell one arm floor press',
    target: 'pectorals',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/t78tZ9NG0iIWur.gif',
    id: '0538',
    name: 'kettlebell one arm jerk',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/s1wuF6NPGpVB2b.gif',
    id: '0539',
    name: 'kettlebell one arm military press to the side',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/MD9O8yl11N52ch.gif',
    id: '0540',
    name: 'kettlebell one arm push press',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/nE5QtA32oEtWyq.gif',
    id: '0541',
    name: 'kettlebell one arm row',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/lLv9mu70EqnYsi.gif',
    id: '0542',
    name: 'kettlebell one arm snatch',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/O3yONZ-q-xG097.gif',
    id: '0543',
    name: 'kettlebell pirate supper legs',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/OK1gejw-gOwBxv.gif',
    id: '0544',
    name: 'kettlebell pistol squat',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/QKiiDfkuCnua46.gif',
    id: '0545',
    name: 'kettlebell plyo push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/4TK8NDrtcKitZ4.gif',
    id: '0546',
    name: 'kettlebell seated press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/7XccKdjsBPfxhU.gif',
    id: '1438',
    name: 'kettlebell seated two arm military press',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/nWcpKy7Y5uO4rw.gif',
    id: '0547',
    name: 'kettlebell seesaw press',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/Kqa2fhVN0POhHt.gif',
    id: '0548',
    name: 'kettlebell sumo high pull',
    target: 'traps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/hxRpORkVMhIMwn.gif',
    id: '0549',
    name: 'kettlebell swing',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/hxmZ5IBx2pzlOi.gif',
    id: '0550',
    name: 'kettlebell thruster',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/fpexgDBqsqu0dO.gif',
    id: '0551',
    name: 'kettlebell turkish get up (squat style)',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/eE-3nPUAr6o3j8.gif',
    id: '0552',
    name: 'kettlebell two arm clean',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/jp7aeuOr2HiVNP.gif',
    id: '0553',
    name: 'kettlebell two arm military press',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/mIZXA9JgJal3XZ.gif',
    id: '1345',
    name: 'kettlebell two arm row',
    target: 'upper back',
  },
  {
    bodyPart: 'waist',
    equipment: 'kettlebell',
    gifUrl: 'https://backend.fitlinez.com/all/IZd4u14mA0wxTM.gif',
    id: '0554',
    name: 'kettlebell windmill',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/rzAVUHTwImc5qt.gif',
    id: '0555',
    name: 'kick out sit',
    target: 'hamstrings',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/HcZ7HI0hooMDjt.gif',
    id: '0558',
    name: 'kipping muscle up',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5a72Ucv5Jd2muB.gif',
    id: '3640',
    name: 'knee touch crunch',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/ek0kAxA4ExGkXY.gif',
    id: '1420',
    name: 'kneeling jump squat',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/0WqFC9Fwf5EsY2.gif',
    id: '1346',
    name: 'kneeling lat stretch',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/eYHLzdrEIzoMAQ.gif',
    id: '3239',
    name: 'kneeling plank tap shoulder (male)',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/fvcHIkizAh4Wtm.gif',
    id: '3211',
    name: 'kneeling push-up (male)',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/tf37k9Pq9NgTzq.gif',
    id: '3288',
    name: 'korean dips',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/rGglB6z1MMzFL8.gif',
    id: '3418',
    name: 'l-pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/sDHkQtIqqIzOxd.gif',
    id: '3419',
    name: 'l-sit on floor',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/ukeg2pHZe-nsWl.gif',
    id: '0562',
    name: 'landmine 180',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/wsOhojQlRiOPep.gif',
    id: '3237',
    name: 'landmine lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/dQr8mGx6EzWTOC.gif',
    id: '3300',
    name: 'lean planche',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/6TaAIuCr0bUGeG.gif',
    id: '2271',
    name: 'left hook. boxing',
    target: 'delts',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/1cUPu0FLjQzZSL.gif',
    id: '0570',
    name: 'leg pull in flat bench',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/P1XCpblN8oXEwR.gif',
    id: '1576',
    name: 'leg up hamstring stretch',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/sPY3S3rrir5cWV.gif',
    id: '2287',
    name: 'lever alternate leg press ',
    target: 'quads',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/CeAlIbcx8NeC7T.gif',
    id: '0571',
    name: 'lever alternating narrow grip seated row ',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/2Gq3vCSgFIhtbh.gif',
    id: '0572',
    name: 'lever assisted chin-up',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/Yx3j8Wtr6mmVxa.gif',
    id: '0573',
    name: 'lever back extension',
    target: 'spine',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/J0E3ceMdaUT-jN.gif',
    id: '0574',
    name: 'lever bent over row ',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/IkH9W9Bm-kfYiH.gif',
    id: '3200',
    name: 'lever bent-over row with v-bar ',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/Xn9DPi4CDa-dSn.gif',
    id: '0575',
    name: 'lever bicep curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/HO1nZvRkyT4Bfg.gif',
    id: '2289',
    name: 'lever calf press ',
    target: 'calves',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/mTEAM9GjcmAcWy.gif',
    id: '0577',
    name: 'lever chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/fj20LhkD6h32wg.gif',
    id: '0576',
    name: 'lever chest press ',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/2U9okd6PgmJhjv.gif',
    id: '0578',
    name: 'lever deadlift ',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/wHBa37tM3zVxKq.gif',
    id: '1300',
    name: 'lever decline chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/GnOaF6o4toFuzO.gif',
    id: '1253',
    name: 'lever donkey calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/zYf9XwFEG0xVZA.gif',
    id: '0579',
    name: 'lever front pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/JAmc5R66keuFyP.gif',
    id: '0580',
    name: 'lever gripless shrug',
    target: 'traps',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/vgmPmWGSe-uvmu.gif',
    id: '1439',
    name: 'lever gripless shrug v. 2',
    target: 'traps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/rTNgccFrkIxjUj.gif',
    id: '2288',
    name: 'lever gripper hands ',
    target: 'forearms',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/KeSmy9oz-YahdW.gif',
    id: '1615',
    name: 'lever hammer grip preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/2YJKHjsppK0Fa6.gif',
    id: '0581',
    name: 'lever high row ',
    target: 'upper back',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/YLZeOVERtJHD3h.gif',
    id: '2286',
    name: 'lever hip extension v. 2',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/WxUa-PTsujHiN8.gif',
    id: '2611',
    name: 'lever horizontal one leg press',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/fN5iXmra8bPOcq.gif',
    id: '1299',
    name: 'lever incline chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/wmSB2M7-Gh0Ezq.gif',
    id: '1479',
    name: 'lever incline chest press v. 2',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/AFdk0LcIXw3Fhw.gif',
    id: '0582',
    name: 'lever kneeling leg curl ',
    target: 'hamstrings',
  },
  {
    bodyPart: 'waist',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/LKYF9lpZSz9pV-.gif',
    id: '0583',
    name: 'lever kneeling twist',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/6abi8QOIaOVqQe.gif',
    id: '0584',
    name: 'lever lateral raise',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/3JHxpIAuaRnD0X.gif',
    id: '0585',
    name: 'lever leg extension',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/QhSLhBoLtk4KXq.gif',
    id: '0586',
    name: 'lever lying leg curl',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/UJMwHDubYrjZjA.gif',
    id: '3195',
    name: 'lever lying two-one leg curl',
    target: 'hamstrings',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/Aqc-EM4fAp0cTI.gif',
    id: '0587',
    name: 'lever military press ',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/60gNTLTfnZHCwB.gif',
    id: '0588',
    name: 'lever narrow grip seated row ',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/I3Qd23rstoUVdj.gif',
    id: '0589',
    name: 'lever one arm bent over row ',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/-JsVZYwyOjaf6G.gif',
    id: '1356',
    name: 'lever one arm lateral high row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/Pmtl5ieeQqgA--.gif',
    id: '1347',
    name: 'lever one arm lateral wide pulldown ',
    target: 'lats',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/A-depscoNxIoaz.gif',
    id: '0590',
    name: 'lever one arm shoulder press ',
    target: 'delts',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/RJOrVttpp5Zm1G.gif',
    id: '0591',
    name: 'lever overhand triceps dip',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/CMELNQsJiIXajD.gif',
    id: '0592',
    name: 'lever preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/4adn6aPn3okksP.gif',
    id: '1614',
    name: 'lever preacher curl v. 2',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/ZMAuY1vwm7xlNP.gif',
    id: '2285',
    name: 'lever pullover ',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/-8UZY0PBjfGyY9.gif',
    id: '2736',
    name: 'lever reverse grip lateral pulldown ',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/XGPopFzdv2T1Wh.gif',
    id: '1616',
    name: 'lever reverse grip preacher curl',
    target: 'biceps',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/8rahfmvwVSkIdQ.gif',
    id: '1348',
    name: 'lever reverse grip vertical row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/Ql6tBtIPqV3Etc.gif',
    id: '0593',
    name: 'lever reverse hyperextension ',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/oGfbsjpkGXcA9E.gif',
    id: '1349',
    name: 'lever reverse t-bar row',
    target: 'upper back',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/tVzrWwhnIXGpCE.gif',
    id: '2315',
    name: 'lever rotary calf',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/z7Y1do0OxLSahl.gif',
    id: '2335',
    name: 'lever seated calf press',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/R5d1IaczIb8lbP.gif',
    id: '0594',
    name: 'lever seated calf raise ',
    target: 'calves',
  },
  {
    bodyPart: 'waist',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/AEAV6dlsQ-GTGB.gif',
    id: '1452',
    name: 'lever seated crunch',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/EYEWh2SDdqyPG9.gif',
    id: '0595',
    name: 'lever seated crunch (chest pad)',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/USnbFYgQWZcwv8.gif',
    id: '3760',
    name: 'lever seated crunch v. 2',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/jlCws4EJk3mWiD.gif',
    id: '1451',
    name: 'lever seated dip',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/i4rcyIMxS-7R4i.gif',
    id: '0596',
    name: 'lever seated fly',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/4QpQ-521-uS-jr.gif',
    id: '3759',
    name: 'lever seated good morning',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/LaI6AJk4xk3Wfd.gif',
    id: '0597',
    name: 'lever seated hip abduction',
    target: 'abductors',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/nQ9Ua7ixkOwyEL.gif',
    id: '0598',
    name: 'lever seated hip adduction',
    target: 'adductors',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/stdUjFz5tA1EZv.gif',
    id: '0599',
    name: 'lever seated leg curl',
    target: 'hamstrings',
  },
  {
    bodyPart: 'waist',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/G2v6Z3yNJeOJz0.gif',
    id: '0600',
    name: 'lever seated leg raise crunch ',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/Yc3r8nXM2kg76Y.gif',
    id: '0602',
    name: 'lever seated reverse fly',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/tMbL8oEulIsN7y.gif',
    id: '0601',
    name: 'lever seated reverse fly (parallel grip)',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/lpIvIBLyq2Eg72.gif',
    id: '1350',
    name: 'lever seated row',
    target: 'upper back',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/jcgDr7v3GBDqWr.gif',
    id: '1385',
    name: 'lever seated squat calf raise on leg press machine',
    target: 'calves',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/TrGy7W1D1XmO5i.gif',
    id: '0603',
    name: 'lever shoulder press ',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/s3oJyKm88RRQaQ.gif',
    id: '0869',
    name: 'lever shoulder press  v. 2',
    target: 'delts',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/CQprJUxBZcgDUM.gif',
    id: '2318',
    name: 'lever shoulder press  v. 3',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/0cYwh7C1wcgU83.gif',
    id: '0604',
    name: 'lever shrug ',
    target: 'traps',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/poH8CydPXmm2Es.gif',
    id: '0605',
    name: 'lever standing calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/vsqyxgj7qow7gp.gif',
    id: '3758',
    name: 'lever standing chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/A6riVDHMnpywXS.gif',
    id: '0606',
    name: 'lever t bar row ',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/XnPeT3QudAssCs.gif',
    id: '1351',
    name: 'lever t-bar reverse grip row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/L6Am6Onkiw8ToA.gif',
    id: '0607',
    name: 'lever triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/fOQ8krWiazmsca.gif',
    id: '1313',
    name: 'lever unilateral row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/Fy4sTA8GB91NdU.gif',
    id: '0609',
    name: 'london bridge',
    target: 'upper back',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/KXpunS5SKt1Hte.gif',
    id: '3013',
    name: 'low glute bridge on floor',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5FTiHwbXBAlOL7.gif',
    id: '1352',
    name: 'lower back curl',
    target: 'spine',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/NRBAKZLbG43qGV.gif',
    id: '3582',
    name: 'lunge with jump',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/2pDsOg8zVHNcb5.gif',
    id: '1688',
    name: 'lunge with twist',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/V3Hwg4ub13Ubm6.gif',
    id: '0613',
    name: 'lying (side) quads stretch',
    target: 'quads',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/rxnQQuAn-WVPEi.gif',
    id: '2312',
    name: 'lying elbow to knee',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/vdtJdnhC4qT6OG.gif',
    id: '0620',
    name: 'lying leg raise flat bench',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/0oYBLdT-OLt1qH.gif',
    id: '0865',
    name: 'lying leg-hip raise',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/ZqEWwsddEhhWbf.gif',
    id: '1301',
    name: 'machine inner chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/z9bcOGLKcHHmZJ.gif',
    id: '0624',
    name: 'march sit (wall)',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/AchIeQDV0vTbv9.gif',
    id: '1353',
    name: 'medicine ball catch and overhead throw',
    target: 'lats',
  },
  {
    bodyPart: 'chest',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/zIxACCf2EIXeFs.gif',
    id: '1302',
    name: 'medicine ball chest pass',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/cMA5O2qPv0FZqj.gif',
    id: '1303',
    name: 'medicine ball chest push from 3 point stance',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/YSkNf1L0CZy8mv.gif',
    id: '1304',
    name: 'medicine ball chest push multiple response',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/Tb8n0ZYOA-TxnY.gif',
    id: '1305',
    name: 'medicine ball chest push single response',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/DjVqrwyRgHkuns.gif',
    id: '1312',
    name: 'medicine ball chest push with run release',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/hcEdjn7uLxeAXV.gif',
    id: '1701',
    name: 'medicine ball close grip push up',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/3CJ7a-hGgNR4qa.gif',
    id: '1354',
    name: 'medicine ball overhead slam',
    target: 'upper back',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/injrfTa0tbfadC.gif',
    id: '1750',
    name: 'medicine ball supine chest throw',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/8vKpacySE9KEyF.gif',
    id: '0627',
    name: 'mixed grip chin-up',
    target: 'lats',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/uUvNtNao3Zf0Di.gif',
    id: '3217',
    name: 'modified hindu push-up (male)',
    target: 'pectorals',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/eWQVeT-Sy5haEN.gif',
    id: '1421',
    name: 'modified push up to lower arms',
    target: 'forearms',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5XWaipA777q3BF.gif',
    id: '0628',
    name: 'monster walk',
    target: 'glutes',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/f0hnyJDcQBW4mi.gif',
    id: '0630',
    name: 'mountain climber',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/Lk3k-sPmUKKALZ.gif',
    id: '0631',
    name: 'muscle up',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/A1HUbfUBwCoSc7.gif',
    id: '1401',
    name: 'muscle-up (on vertical bar)',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/QxU5nZW02GxIoH.gif',
    id: '2328',
    name: 'narrow push-up on exercise ball',
    target: 'triceps',
  },
  {
    bodyPart: 'neck',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/0U2l07yLFFiLcW.gif',
    id: '1403',
    name: 'neck side stretch',
    target: 'levator scapulae',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/KSAJyS-WiU4SyP.gif',
    id: '0634',
    name: 'negative crunch',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/6YGohQSkRCCDEh.gif',
    id: '1495',
    name: 'oblique crunch v. 2',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/fRJRhki2WJ3o6Q.gif',
    id: '0635',
    name: 'oblique crunches floor',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'olympic barbell',
    gifUrl: 'https://backend.fitlinez.com/all/QkjwqXvPmJAV7O.gif',
    id: '0636',
    name: 'olympic barbell hammer curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'olympic barbell',
    gifUrl: 'https://backend.fitlinez.com/all/gBdQwOxkNZv5io.gif',
    id: '0637',
    name: 'olympic barbell triceps extension',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/rKEu74goW6-Rsi.gif',
    id: '1355',
    name: 'one arm against wall',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/yCTuP2WnKztdmX.gif',
    id: '0638',
    name: 'one arm chin-up',
    target: 'lats',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/I2T7Vt8NlWXK5x.gif',
    id: '0639',
    name: 'one arm dip',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/uWpNm46Lz-13Kn.gif',
    id: '0640',
    name: 'one arm slam (with medicine ball)',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/LVa7MV6MSGk2YL.gif',
    id: '1773',
    name: 'one arm towel row',
    target: 'upper back',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/tzzwvnVrudfUo3.gif',
    id: '1386',
    name: 'one leg donkey calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/OWzUMU3q5iRS5D.gif',
    id: '1387',
    name: 'one leg floor calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/pakBz3QyyEEJ9O.gif',
    id: '1476',
    name: 'one leg squat',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/RVjLrPDihl1EC4.gif',
    id: '0641',
    name: 'otis up',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/Gpl2DtiiJ9xL3P.gif',
    id: '0642',
    name: 'outside leg kick push-up',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/lod5px34Lo-JBS.gif',
    id: '0643',
    name: 'overhead triceps stretch',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/X8-NEjhwyabe51.gif',
    id: '3147',
    name: 'pelvic tilt',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/h3InrikQ5wu7Ec.gif',
    id: '1422',
    name: 'pelvic tilt into bridge',
    target: 'glutes',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/9m7qcs08RgJIvq.gif',
    id: '1388',
    name: 'peroneals stretch',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ZfljfVCCzoxwrc.gif',
    id: '3662',
    name: 'pike-to-cobra push-up',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/kFJEF1VwqCzc3m.gif',
    id: '1306',
    name: 'plyo push up',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/jkfTg4jNk7jgXT.gif',
    id: '1687',
    name: 'posterior step to overhead reach',
    target: 'abs',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/g7otRY450QKAFd.gif',
    id: '1389',
    name: 'posterior tibialis stretch',
    target: 'calves',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/cB0lGPGFk2YFrd.gif',
    id: '3119',
    name: 'potty squat',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/F2QrZO2bxvFXBc.gif',
    id: '3132',
    name: 'potty squat with support',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/KD596-jFK9zmL9.gif',
    id: '0648',
    name: 'power clean',
    target: 'hamstrings',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/aDCrnjnFmbJoWY.gif',
    id: '3665',
    name: 'power point plank',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/qlq2VuTWQpJok2.gif',
    id: '3203',
    name: 'prisoner half sit-up (male)',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/kB7JXVzXu-CW0f.gif',
    id: '1707',
    name: 'prone twist on stability ball',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/bCAre6fZspRWPa.gif',
    id: '0651',
    name: 'pull up (neutral grip)',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/G9fWyES5v9jgJp.gif',
    id: '0650',
    name: 'pull-in (on stability ball)',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/MbLKwosFDCQl32.gif',
    id: '0652',
    name: 'pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ZZJVVplhzaQYlR.gif',
    id: '1689',
    name: 'push and pull bodyweight',
    target: 'pectorals',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/h7sRjQ35bGS-c0.gif',
    id: '3638',
    name: 'push to run',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'chest',
    equipment: 'bosu ball',
    gifUrl: 'https://backend.fitlinez.com/all/3S-bqebx7Fui2f.gif',
    id: '1307',
    name: 'push up on bosu ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/W7fbR7fWB1uRV5.gif',
    id: '0662',
    name: 'push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'bosu ball',
    gifUrl: 'https://backend.fitlinez.com/all/9QDBmWRnGY0Lwi.gif',
    id: '0653',
    name: 'push-up (bosu ball)',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/Z3tjonaKuXs9zW.gif',
    id: '0655',
    name: 'push-up (on stability ball)',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/GBHwNiXHoaX-3-.gif',
    id: '0656',
    name: 'push-up (on stability ball)',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/uDXDeHo3lJwUoO.gif',
    id: '0659',
    name: 'push-up (wall)',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/huRKWSxOdlD5XC.gif',
    id: '0658',
    name: 'push-up (wall) v. 2',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/Dfv-I4dzPqoDsq.gif',
    id: '0660',
    name: 'push-up close-grip off dumbbell',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/afpR0Q090p-Euw.gif',
    id: '0661',
    name: 'push-up inside leg kick',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/5jzqVCG8TI6q5a.gif',
    id: '0663',
    name: 'push-up medicine ball',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/grBjwB06MPl9Fs.gif',
    id: '1467',
    name: 'push-up on lower arms',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/yD4gV8br6lUA4H.gif',
    id: '3145',
    name: 'push-up plus',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/-8QPvoP8yw1hwT.gif',
    id: '0664',
    name: 'push-up to side plank',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/gcDfdwNXEBirh5.gif',
    id: '3533',
    name: 'quads',
    target: 'quads',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/47n0jxSbEf1SWC.gif',
    id: '3201',
    name: 'quarter sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/mrAbVmnywFMOd6.gif',
    id: '3552',
    name: 'quick feet v. 2',
    target: 'quads',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/hXOw15S8vH9WIp.gif',
    id: '0666',
    name: 'raise single arm push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/sNwxtTn5LmTxrh.gif',
    id: '0668',
    name: 'rear decline bridge',
    target: 'glutes',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/f1jHl9U4pkc-aA.gif',
    id: '0669',
    name: 'rear deltoid stretch',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/CdF7950KwfEc77.gif',
    id: '0670',
    name: 'rear pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/db1VOxOyL9o7-I.gif',
    id: '1582',
    name: 'reclining big toe pose with rope',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'resistance band',
    gifUrl: 'https://backend.fitlinez.com/all/M59K-zRwivdpkn.gif',
    id: '3236',
    name: 'resistance band hip thrusts on knees (female)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'resistance band',
    gifUrl: 'https://backend.fitlinez.com/all/XaHkmC7C1XVMPX.gif',
    id: '3007',
    name: 'resistance band leg extension',
    target: 'quads',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'resistance band',
    gifUrl: 'https://backend.fitlinez.com/all/DQfdkMftHwUOkF.gif',
    id: '3123',
    name: 'resistance band seated biceps curl',
    target: 'biceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'resistance band',
    gifUrl: 'https://backend.fitlinez.com/all/v-m7QYDgREoiG9.gif',
    id: '3124',
    name: 'resistance band seated chest press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'resistance band',
    gifUrl: 'https://backend.fitlinez.com/all/04AE1NnRDuDPIm.gif',
    id: '3006',
    name: 'resistance band seated hip abduction',
    target: 'abductors',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'resistance band',
    gifUrl: 'https://backend.fitlinez.com/all/yRI3lzGypOMqyY.gif',
    id: '3122',
    name: 'resistance band seated shoulder press',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'resistance band',
    gifUrl: 'https://backend.fitlinez.com/all/2DJGSOIHmd3Ej4.gif',
    id: '3144',
    name: 'resistance band seated straight back row',
    target: 'upper back',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/m2g2ID1-VspA4q.gif',
    id: '0872',
    name: 'reverse crunch',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/z2Ae0fUOGLgaZC.gif',
    id: '0672',
    name: 'reverse dip',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/Jhs0XnY-PsIT6E.gif',
    id: '0673',
    name: 'reverse grip machine lat pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/gRX58IITxsDOqv.gif',
    id: '0674',
    name: 'reverse grip pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/picAmT5ur2-1im.gif',
    id: '0675',
    name: 'reverse hyper extension (on stability ball)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/Z-7OGnJmBmWwPp.gif',
    id: '1423',
    name: 'reverse hyper on flat bench',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/Tr4uxDFwqb3pPC.gif',
    id: '3663',
    name: 'reverse plank with leg lift',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/yWIXbOh-ap2qIt.gif',
    id: '0677',
    name: 'ring dips',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/30hjfeOLL5iWeO.gif',
    id: '2571',
    name: 'rocking frog stretch',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5FyvJ0HzojQm6l.gif',
    id: '0678',
    name: 'rocky pull-up pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'roller',
    gifUrl: 'https://backend.fitlinez.com/all/ZxNmzP8Ka5lw77.gif',
    id: '2208',
    name: 'roller back stretch',
    target: 'spine',
  },
  {
    bodyPart: 'waist',
    equipment: 'roller',
    gifUrl: 'https://backend.fitlinez.com/all/0BtAbjIDQhhgI4.gif',
    id: '2204',
    name: 'roller body saw',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'roller',
    gifUrl: 'https://backend.fitlinez.com/all/WnSH5OKoYomdPY.gif',
    id: '2205',
    name: 'roller hip lat stretch',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'roller',
    gifUrl: 'https://backend.fitlinez.com/all/uKeyjckd-Uigy5.gif',
    id: '2202',
    name: 'roller hip stretch',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'roller',
    gifUrl: 'https://backend.fitlinez.com/all/5Awoh42LB5x6kd.gif',
    id: '2206',
    name: 'roller reverse crunch',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'roller',
    gifUrl: 'https://backend.fitlinez.com/all/EDTHSCcv7CIDOV.gif',
    id: '2203',
    name: 'roller seated shoulder flexor depresor retractor',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'roller',
    gifUrl: 'https://backend.fitlinez.com/all/4VMBQE6djm4oaf.gif',
    id: '2209',
    name: 'roller seated single leg shoulder flexor depresor retractor',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'roller',
    gifUrl: 'https://backend.fitlinez.com/all/FdFOv-4D5M-dSa.gif',
    id: '2207',
    name: 'roller side lat stretch',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/ROdLMnRIFQUmVS.gif',
    id: '0680',
    name: 'rope climb',
    target: 'upper back',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ywJDUe8-llJEPU.gif',
    id: '0685',
    name: 'run',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/uqNzNHzFHjdERn.gif',
    id: '0684',
    name: 'run (equipment)',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/UgAIhOFILnHcFp.gif',
    id: '1585',
    name: 'runners stretch',
    target: 'hamstrings',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/DMvRDedZ3fn-L7.gif',
    id: '0687',
    name: 'russian twist',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/xvdaGIa53DKCtR.gif',
    id: '3012',
    name: 'scapula dips',
    target: 'traps',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/f6MGVj5s3rLZct.gif',
    id: '3021',
    name: 'scapula push-up',
    target: 'serratus anterior',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ngPtM5qaOnqXzC.gif',
    id: '0688',
    name: 'scapular pull-up',
    target: 'traps',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/vol8L8OKT4bTcf.gif',
    id: '3219',
    name: 'scissor jumps (male)',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/NEnVQbX9fNa4BO.gif',
    id: '1390',
    name: 'seated calf stretch (male)',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/UFcSkisJV87YmE.gif',
    id: '1424',
    name: 'seated glute stretch',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/nythR1JfDsHZSG.gif',
    id: '0689',
    name: 'seated leg raise',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/vejb9vj-GbyFS8.gif',
    id: '0690',
    name: 'seated lower back stretch',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/nsjGd8usUpnJGk.gif',
    id: '2567',
    name: 'seated piriformis stretch',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/67yVq49z58FMcj.gif',
    id: '0691',
    name: 'seated side crunch (wall)',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/GQawnhRTZXxats.gif',
    id: '1587',
    name: 'seated wide angle pose sequence',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/P8DmQZyW-IrKQL.gif',
    id: '0697',
    name: 'self assisted inverse leg curl',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/YfZGY4zhuWPZIT.gif',
    id: '1766',
    name: 'self assisted inverse leg curl',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/K8l84QEJ6qRzPE.gif',
    id: '0696',
    name: 'self assisted inverse leg curl (on floor)',
    target: 'hamstrings',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/uMEFIowmWcYXhD.gif',
    id: '3222',
    name: 'semi squat jump (male)',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/O2mfxF9erq-2he.gif',
    id: '3656',
    name: 'short stride run',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/TqCkYjw3MfDGrv.gif',
    id: '1763',
    name: 'shoulder grip pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/t5fClSdDiVatbE.gif',
    id: '3699',
    name: 'shoulder tap',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/LIzVkekcH1VaZ9.gif',
    id: '0699',
    name: 'shoulder tap push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/caqwKmcA3Fy9Mb.gif',
    id: '1774',
    name: 'side bridge hip abduction',
    target: 'abductors',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/cI-Bpz5Le79prT.gif',
    id: '0705',
    name: 'side bridge v. 2',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/MH9c-Dbv2sOBKX.gif',
    id: '0709',
    name: 'side hip (on parallel bars)',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/KzaI1QLshsWTPJ.gif',
    id: '0710',
    name: 'side hip abduction',
    target: 'abductors',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ny10GPnxqTq5zU.gif',
    id: '1358',
    name: 'side lying floor stretch',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/KfZwDmPOxheff2.gif',
    id: '3667',
    name: 'side lying hip adduction (male)',
    target: 'adductors',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/CPAywiX11eI3Cc.gif',
    id: '1775',
    name: 'side plank hip adduction',
    target: 'adductors',
  },
  {
    bodyPart: 'neck',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/O08PNHxDHMT5j8.gif',
    id: '0716',
    name: 'side push neck stretch',
    target: 'levator scapulae',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/NLoaiAq8eQgqL4.gif',
    id: '0717',
    name: 'side push-up',
    target: 'triceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/4SQzs55IjqxYNT.gif',
    id: '0721',
    name: 'side wrist pull stretch',
    target: 'forearms',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/R9Lwq1ZOZ00iw2.gif',
    id: '0720',
    name: 'side-to-side chin',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/E93sPwGRWALAmA.gif',
    id: '3213',
    name: 'side-to-side toe touch (male)',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/vJKilGlDXxkgYe.gif',
    id: '0725',
    name: 'single arm push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/sEVOA8w08-rdMu.gif',
    id: '3645',
    name: 'single leg bridge with outstretched leg',
    target: 'glutes',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/37S6UtFoscP7Eb.gif',
    id: '0727',
    name: 'single leg calf raise (on a dumbbell)',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5AO-wMxG7klTmQ.gif',
    id: '0730',
    name: 'single leg platform slide',
    target: 'hamstrings',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/voAs9c8otEsnaa.gif',
    id: '1759',
    name: 'single leg squat (pistol) male',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/Yl-7GHiFixAWnE.gif',
    id: '1489',
    name: 'sissy squat',
    target: 'quads',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/XYsclCH2TW4SXx.gif',
    id: '0735',
    name: 'sit-up v. 2',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/bmwa-3mLeDfD-4.gif',
    id: '3679',
    name: 'sit-up with arms on chest',
    target: 'abs',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/1k5mAwkTp4FXTX.gif',
    id: '3361',
    name: 'skater hops',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'skierg machine',
    gifUrl: 'https://backend.fitlinez.com/all/-88W0cdU3BfkOS.gif',
    id: '2142',
    name: 'ski ergometer',
    target: 'triceps',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/Ldee6p26au4Mwg.gif',
    id: '3671',
    name: 'ski step',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/cqcTn6T2x0uYe0.gif',
    id: '3304',
    name: 'skin the cat',
    target: 'upper back',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/xFeGmCRDynZozZ.gif',
    id: '1425',
    name: 'sled 45 degrees one leg press',
    target: 'glutes',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/vuU0CMqWpvZSLy.gif',
    id: '0738',
    name: 'sled 45в° calf press',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/Vt12iV3SseDtz-.gif',
    id: '0739',
    name: 'sled 45в° leg press',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/gd8Y0hhcMNXGUu.gif',
    id: '1464',
    name: 'sled 45в° leg press (back pov)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/FLWMDneBxQF3vq.gif',
    id: '1463',
    name: 'sled 45в° leg press (side pov)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/7eoQCdWNs58jTz.gif',
    id: '0740',
    name: 'sled 45в° leg wide press',
    target: 'glutes',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/uuSyEdrsG9frAU.gif',
    id: '1391',
    name: 'sled calf press on leg press',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/XuOL0dd-bAbNsH.gif',
    id: '0741',
    name: 'sled closer hack squat',
    target: 'glutes',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/PZYJs5R4Acul90.gif',
    id: '0742',
    name: 'sled forward angled calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/Wg8loj8Du3YjRG.gif',
    id: '0743',
    name: 'sled hack squat',
    target: 'glutes',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/fq8XW-Upf0Ro9C.gif',
    id: '2334',
    name: 'sled lying calf press',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/YpWwGRemWBT30y.gif',
    id: '0744',
    name: 'sled lying squat',
    target: 'glutes',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'sled machine',
    gifUrl: 'https://backend.fitlinez.com/all/O6lHsTFh0APjaV.gif',
    id: '1392',
    name: 'sled one leg calf press on leg press',
    target: 'calves',
  },
  {
    bodyPart: 'waist',
    equipment: 'hammer',
    gifUrl: 'https://backend.fitlinez.com/all/84VKgKS3AKZTqT.gif',
    id: '1496',
    name: 'sledge hammer',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/yMD8TFIMQ3N9hK.gif',
    id: '0746',
    name: 'smith back shrug',
    target: 'traps',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/IcbR8DmrRWbHRf.gif',
    id: '0747',
    name: 'smith behind neck press',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/RwIMMz84KlkUEc.gif',
    id: '0748',
    name: 'smith bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/Vi0HAMPyLbxJ-c.gif',
    id: '0749',
    name: 'smith bent knee good morning',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/BaUv9AdNBiIVZH.gif',
    id: '1359',
    name: 'smith bent over row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/nTW0PyS2Kph800.gif',
    id: '0750',
    name: 'smith chair squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/liQ9z4yOZOpUEp.gif',
    id: '0751',
    name: 'smith close-grip bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/V-ADVpC3X0V6MQ.gif',
    id: '0752',
    name: 'smith deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/iMk2wJ4qCJSmby.gif',
    id: '0753',
    name: 'smith decline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/WPaHMAZNQdhPdL.gif',
    id: '0754',
    name: 'smith decline reverse-grip press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/320HtCBX7n6ZHh.gif',
    id: '1433',
    name: 'smith front squat (clean grip)',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/CqOPdWS8uMX-C8.gif',
    id: '3281',
    name: 'smith full squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/KClqqZepbuit8T.gif',
    id: '0755',
    name: 'smith hack squat',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/vGm5XS6kWhi1I9.gif',
    id: '0756',
    name: 'smith hip raise',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/3JWVHTEoNn0Qmb.gif',
    id: '0757',
    name: 'smith incline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/MuUZCFB9CqCBvw.gif',
    id: '0758',
    name: 'smith incline reverse-grip press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/v8p78nh9GFtbAS.gif',
    id: '0759',
    name: 'smith incline shoulder raises',
    target: 'serratus anterior',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/52fBSxn965I6Bg.gif',
    id: '0760',
    name: 'smith leg press',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/W9AeDHGaoWhmJg.gif',
    id: '1434',
    name: 'smith low bar squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/2SNMp1Bu-7n5Sx.gif',
    id: '1683',
    name: 'smith machine bicep curl',
    target: 'biceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/deV0Kpa7PNGyfW.gif',
    id: '1625',
    name: 'smith machine decline close grip bench press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/F554zDh7kjTIFJ.gif',
    id: '1752',
    name: 'smith machine incline tricep extension',
    target: 'triceps',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/Sp0Mw1moA3vayv.gif',
    id: '1626',
    name: 'smith machine reverse decline close grip bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'back',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/mO7p6lOrnu7qgG.gif',
    id: '0761',
    name: 'smith narrow row',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/RmoHQTperLMtxQ.gif',
    id: '1360',
    name: 'smith one arm row',
    target: 'upper back',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/vBuX9itHlfbFQR.gif',
    id: '1393',
    name: 'smith one leg floor calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/CsaAzBBHBe2XQ-.gif',
    id: '0762',
    name: 'smith rear delt row',
    target: 'delts',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/3b7Ixzihz6ZxWw.gif',
    id: '0763',
    name: 'smith reverse calf raises',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/LHOXKp0AxBYOy7.gif',
    id: '1394',
    name: 'smith reverse calf raises',
    target: 'calves',
  },
  {
    bodyPart: 'back',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/RF7q4O-GChbaXq.gif',
    id: '1361',
    name: 'smith reverse grip bent over row',
    target: 'upper back',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/xNK1jUR9mNecD3.gif',
    id: '0764',
    name: 'smith reverse-grip press',
    target: 'pectorals',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/XQ1SZMFE8L9-yD.gif',
    id: '1395',
    name: 'smith seated one leg calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/w2uRF8HL6JivzY.gif',
    id: '0765',
    name: 'smith seated shoulder press',
    target: 'delts',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/vB6YwjZeYuupLH.gif',
    id: '1426',
    name: 'smith seated wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/73yMsCLxBFalEE.gif',
    id: '0766',
    name: 'smith shoulder press',
    target: 'delts',
  },
  {
    bodyPart: 'back',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/fr-4RIc2BCqR4C.gif',
    id: '0767',
    name: 'smith shrug',
    target: 'traps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/5A5VwlS9AaDncC.gif',
    id: '0768',
    name: 'smith single leg split squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/OuuhSuaw2kKo38.gif',
    id: '0769',
    name: 'smith sprint lunge',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/qxKnTuki1F-JVR.gif',
    id: '0770',
    name: 'smith squat',
    target: 'glutes',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/VhcY6EAL5WMAmV.gif',
    id: '0771',
    name: 'smith standing back wrist curl',
    target: 'forearms',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/4IFhWp-qd4Vh6v.gif',
    id: '0772',
    name: 'smith standing behind head military press',
    target: 'delts',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/tLru3ruim3XJEd.gif',
    id: '0773',
    name: 'smith standing leg calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/7UYju2qfabRTHj.gif',
    id: '0774',
    name: 'smith standing military press',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/KPSoQNT0Rp0lzN.gif',
    id: '3142',
    name: 'smith sumo squat',
    target: 'glutes',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/9xVDVAinGO2k8m.gif',
    id: '1396',
    name: 'smith toe raise',
    target: 'calves',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/G3eE6QIvEBswo-.gif',
    id: '0775',
    name: 'smith upright row',
    target: 'delts',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/Uf-SmbQnYTRhm1.gif',
    id: '1308',
    name: 'smith wide grip bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'smith machine',
    gifUrl: 'https://backend.fitlinez.com/all/oNh2kbkhuLgHWI.gif',
    id: '1309',
    name: 'smith wide grip decline bench press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/oXLMgSqtBwuUWj.gif',
    id: '0776',
    name: 'snatch pull',
    target: 'quads',
  },
  {
    bodyPart: 'waist',
    equipment: 'dumbbell',
    gifUrl: 'https://backend.fitlinez.com/all/5eayJAo1wCiJNU.gif',
    id: '0777',
    name: 'spell caster',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/bSozLRNxgVf3Ds.gif',
    id: '1362',
    name: 'sphinx',
    target: 'spine',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/2i1bD07PTv7zKb.gif',
    id: '0778',
    name: 'spider crawl push up',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/NRb28OHoYsnCv-.gif',
    id: '1363',
    name: 'spine stretch',
    target: 'spine',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/d8mstpGl7Hauag.gif',
    id: '2329',
    name: 'spine twist',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/qt-u01GL0TzW-Z.gif',
    id: '2368',
    name: 'split squats',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/MxKy4la0Z8mPcq.gif',
    id: '0786',
    name: 'squat jerk',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'bosu ball',
    gifUrl: 'https://backend.fitlinez.com/all/xF8sPcHqbdgxtj.gif',
    id: '1705',
    name: 'squat on bosu ball',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/UE5IGXgXddm-ih.gif',
    id: '1685',
    name: 'squat to overhead reach',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/AWJDXOPTcNqyu5.gif',
    id: '1686',
    name: 'squat to overhead reach with twist',
    target: 'quads',
  },
  {
    bodyPart: 'waist',
    equipment: 'stability ball',
    gifUrl: 'https://backend.fitlinez.com/all/xuY1MtL0uGl-oG.gif',
    id: '2297',
    name: 'stability ball crunch (full range hands behind head)',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/OHK-sxFsGrql5o.gif',
    id: '3291',
    name: 'stalder press',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/GicdirUEsByqJh.gif',
    id: '3669',
    name: 'standing archer',
    target: 'upper back',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'barbell',
    gifUrl: 'https://backend.fitlinez.com/all/CEwTc6zYyc4GmG.gif',
    id: '0788',
    name: 'standing behind neck press',
    target: 'delts',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/hN0z5r8YY-EhYg.gif',
    id: '1490',
    name: 'standing calf raise (on a staircase)',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/7XkpnIBn1sY5Qg.gif',
    id: '1397',
    name: 'standing calves',
    target: 'calves',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/joQYo5flbSIEMo.gif',
    id: '1398',
    name: 'standing calves calf stretch',
    target: 'calves',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'rope',
    gifUrl: 'https://backend.fitlinez.com/all/kQuIDidTkJv18w.gif',
    id: '1599',
    name: 'standing hamstring and calf stretch with strap',
    target: 'hamstrings',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/mvUmV790h5vDyw.gif',
    id: '0794',
    name: 'standing lateral stretch',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/0h-ve7UcZFosOc.gif',
    id: '1364',
    name: 'standing pelvic tilt',
    target: 'spine',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/uJ7olDaazB5s4C.gif',
    id: '0795',
    name: 'standing single leg curl',
    target: 'hamstrings',
  },
  {
    bodyPart: 'waist',
    equipment: 'wheel roller',
    gifUrl: 'https://backend.fitlinez.com/all/VzcfuCVtJJPWop.gif',
    id: '0796',
    name: 'standing wheel rollerout',
    target: 'abs',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/jCq54udz0KbH6Z.gif',
    id: '3223',
    name: 'star jump (male)',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'cardio',
    equipment: 'stationary bike',
    gifUrl: 'https://backend.fitlinez.com/all/MVFymcuN0KRuTK.gif',
    id: '2138',
    name: 'stationary bike run v. 3',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'cardio',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/4cpVG7ju7FcsSD.gif',
    id: '0798',
    name: 'stationary bike walk',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/OL5ksd4lNh1Y6M.gif',
    id: '3314',
    name: 'straddle maltese',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/xSXKfbIo-NhVw1.gif',
    id: '3298',
    name: 'straddle planche',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/5J6wv64m1Ec9oV.gif',
    id: '1427',
    name: 'straight leg outer hip abductor',
    target: 'abductors',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/yl7Rmov9cG1iOX.gif',
    id: '0803',
    name: 'superman push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/XrA-Tm4A1lTDaV.gif',
    id: '0805',
    name: 'suspended abdominal fallout',
    target: 'abs',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/6S3fNQY0ZKpJDb.gif',
    id: '0806',
    name: 'suspended push-up',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/rp73qxBQhM4Zdg.gif',
    id: '0807',
    name: 'suspended reverse crunch',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/luI28a0rzY5jTj.gif',
    id: '0808',
    name: 'suspended row',
    target: 'upper back',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/dkJgbFifDeIKc9.gif',
    id: '0809',
    name: 'suspended split squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/eMLXXn2RCBd7PJ.gif',
    id: '3433',
    name: 'swimmer kicks v. 2 (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/RpLbMZEKpCyKWR.gif',
    id: '3318',
    name: 'swing 360',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/2bTG3qF5l0UFQ1.gif',
    id: '1753',
    name: 'three bench dip',
    target: 'triceps',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'tire',
    gifUrl: 'https://backend.fitlinez.com/all/gVhZNn6r09w3o7.gif',
    id: '2459',
    name: 'tire flip',
    target: 'glutes',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'trap bar',
    gifUrl: 'https://backend.fitlinez.com/all/JGzxOcEfeQEMrk.gif',
    id: '0811',
    name: 'trap bar deadlift',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/VtfResQMMRw0aV.gif',
    id: '0814',
    name: 'triceps dip',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/SEJNEIitugpKOV.gif',
    id: '0812',
    name: 'triceps dip (bench leg)',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/hl3Cu609tIabuC.gif',
    id: '0813',
    name: 'triceps dip (between benches)',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/bGWHgOuD6ZuxS1.gif',
    id: '0815',
    name: 'triceps dips floor',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/gV1OWvWsaACfNw.gif',
    id: '0816',
    name: 'triceps press',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/DU5-wSg6Nkgsbx.gif',
    id: '0817',
    name: 'triceps stretch',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/awJ6hP9XBC8HQC.gif',
    id: '0871',
    name: 'tuck crunch',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'cable',
    gifUrl: 'https://backend.fitlinez.com/all/-dhlxdfuOUsIL9.gif',
    id: '0818',
    name: 'twin handle parallel grip lat pulldown',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/JIUhS529m-g5QK.gif',
    id: '1466',
    name: 'twist hip lift',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/7xMKPblsma1Kcx.gif',
    id: '2802',
    name: 'twisted leg raise',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/WgA2hAg-PDBy5z.gif',
    id: '2801',
    name: 'twisted leg raise (female)',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/ARd0RoCxvFiqtA.gif',
    id: '3231',
    name: 'two toe touch (male)',
    target: 'spine',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/YBXTsU1t4ge0Yl.gif',
    id: '1365',
    name: 'upper back stretch',
    target: 'upper back',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/H4a4uLoInOvDx5.gif',
    id: '1366',
    name: 'upward facing dog',
    target: 'spine',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/Jpye14hiIzn0VO.gif',
    id: '3420',
    name: 'v-sit on floor',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/A7m0XeWbTbBlCo.gif',
    id: '0826',
    name: 'vertical leg raise (on parallel bars)',
    target: 'abs',
  },
  {
    bodyPart: 'cardio',
    equipment: 'elliptical machine',
    gifUrl: 'https://backend.fitlinez.com/all/JdWgNoO0Z2DUJ3.gif',
    id: '2141',
    name: 'walk elliptical cross trainer',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/61JB64gJK2K-fF.gif',
    id: '3655',
    name: 'walking high knees lunge',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/WWQBr-zuoKPiJd.gif',
    id: '1460',
    name: 'walking lunge',
    target: 'glutes',
  },
  {
    bodyPart: 'cardio',
    equipment: 'leverage machine',
    gifUrl: 'https://backend.fitlinez.com/all/vTn8pdyguyeWxF.gif',
    id: '3666',
    name: 'walking on incline treadmill',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'cardio',
    equipment: 'stepmill machine',
    gifUrl: 'https://backend.fitlinez.com/all/IZ8duym6sOZL5I.gif',
    id: '2311',
    name: 'walking on stepmill',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/6aPSNAhljdqfbk.gif',
    id: '0830',
    name: 'weighted bench dip',
    target: 'triceps',
  },
  {
    bodyPart: 'back',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/CSefIXF80Hl1P9.gif',
    id: '2987',
    name: 'weighted close grip chin-up on dip cage',
    target: 'lats',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/wns9IU-RWckXKX.gif',
    id: '3643',
    name: 'weighted cossack squats (male)',
    target: 'glutes',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/XGLLIg7YOyiH0p.gif',
    id: '0832',
    name: 'weighted crunch',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/hqtVvkEJOWC9cR.gif',
    id: '3670',
    name: 'weighted decline sit-up',
    target: 'abs',
  },
  {
    bodyPart: 'lower legs',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/RULw0NIdgGJSoo.gif',
    id: '0833',
    name: 'weighted donkey calf raise',
    target: 'calves',
  },
  {
    bodyPart: 'chest',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/J2et8L3I8Xg3I9.gif',
    id: '1310',
    name: 'weighted drop push up',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/Qr5Qkd7x8hBXJE.gif',
    id: '2135',
    name: 'weighted front plank',
    target: 'abs',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/Uy5vtwFRjEz6I4.gif',
    id: '0834',
    name: 'weighted front raise',
    target: 'delts',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/b4t0wzD4HKOqy1.gif',
    id: '0866',
    name: 'weighted hanging leg-hip raise',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/XMrbWYmBHziVqO.gif',
    id: '0835',
    name: 'weighted hyperextension (on stability ball)',
    target: 'spine',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/-47TMxNSSSPR4F.gif',
    id: '3641',
    name: 'weighted kneeling step with swing',
    target: 'delts',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/z5MGlo4CI1sz95.gif',
    id: '3644',
    name: 'weighted lunge with swing',
    target: 'glutes',
  },
  {
    bodyPart: 'back',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/4jW9KujnztIL8S.gif',
    id: '3286',
    name: 'weighted muscle up',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/Q3VMEEp4uXkfkA.gif',
    id: '3312',
    name: 'weighted muscle up (on bar)',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/40NNbU9iJHSdUQ.gif',
    id: '3290',
    name: 'weighted one hand pull up',
    target: 'lats',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/HJv7svX0qsouN0.gif',
    id: '0840',
    name: 'weighted overhead crunch (on stability ball)',
    target: 'abs',
  },
  {
    bodyPart: 'back',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/vB74fEIrY74EI0.gif',
    id: '0841',
    name: 'weighted pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'shoulders',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/6oMAe-y0YqTU6Z.gif',
    id: '0844',
    name: 'weighted round arm',
    target: 'delts',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/03h7FwSWZg7MzL.gif',
    id: '0846',
    name: 'weighted russian twist',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/rlmEoIDtjkhFO3.gif',
    id: '0845',
    name: 'weighted russian twist (legs up)',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/4CA0dtGCwXmhTX.gif',
    id: '2371',
    name: 'weighted russian twist v. 2',
    target: 'abs',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'medicine ball',
    gifUrl: 'https://backend.fitlinez.com/all/jPjlBAh1g7oo8Z.gif',
    id: '0847',
    name: 'weighted seated bicep curl  (on stability ball)',
    target: 'biceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/Nw9Q2DGAGl2F-r.gif',
    id: '0849',
    name: 'weighted seated twist (on stability ball)',
    target: 'abs',
  },
  {
    bodyPart: 'waist',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/yqBmsfUzWTG5Cp.gif',
    id: '0850',
    name: 'weighted side bend (on stability ball)',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/-qwwRDnkNrsgA1.gif',
    id: '0851',
    name: 'weighted sissy squat',
    target: 'quads',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/eBv5W8x3qRlnp0.gif',
    id: '0852',
    name: 'weighted squat',
    target: 'glutes',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/9RnQzyYWftoL0E.gif',
    id: '0853',
    name: 'weighted standing curl',
    target: 'biceps',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/xMPu5H7z23FQlw.gif',
    id: '0854',
    name: 'weighted standing hand squeeze',
    target: 'forearms',
  },
  {
    bodyPart: 'chest',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/0IDQv8pVs7qrRx.gif',
    id: '3313',
    name: 'weighted straight bar dip',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/mT1lMwq0-VG1n3.gif',
    id: '3642',
    name: 'weighted stretch lunge',
    target: 'glutes',
  },
  {
    bodyPart: 'chest',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/0Av5-SaabC3cA9.gif',
    id: '0856',
    name: 'weighted svend press',
    target: 'pectorals',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/Q6IBhWmXhJKOv1.gif',
    id: '1754',
    name: 'weighted three bench dips',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/FRrmGnUu8mcZS-.gif',
    id: '1755',
    name: 'weighted tricep dips',
    target: 'triceps',
  },
  {
    bodyPart: 'upper arms',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/01NLr99eW9ztEI.gif',
    id: '1767',
    name: 'weighted triceps dip on high parallel bars',
    target: 'triceps',
  },
  {
    bodyPart: 'waist',
    equipment: 'wheel roller',
    gifUrl: 'https://backend.fitlinez.com/all/3Vn3p0RT6S5hT3.gif',
    id: '0857',
    name: 'wheel rollerout',
    target: 'abs',
  },
  {
    bodyPart: 'cardio',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/lfyXIm7pRFVjYw.gif',
    id: '3637',
    name: 'wheel run',
    target: 'cardiovascular system',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/KiWvvJlM39gfHL.gif',
    id: '1429',
    name: 'wide grip pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'back',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/Jf8x5UaHf1R3-J.gif',
    id: '1367',
    name: 'wide grip rear pull-up',
    target: 'lats',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/1Hv-SAStTDQ2Mb.gif',
    id: '1311',
    name: 'wide hand push up',
    target: 'pectorals',
  },
  {
    bodyPart: 'chest',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/H1VctTnZ2VTN9s.gif',
    id: '2363',
    name: 'wide-grip chest dip on high parallel bars',
    target: 'pectorals',
  },
  {
    bodyPart: 'waist',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/HDVKJVq2SNxg0S.gif',
    id: '0858',
    name: 'wind sprints',
    target: 'abs',
  },
  {
    bodyPart: 'upper legs',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/I4-XFtCKjYmxnz.gif',
    id: '1604',
    name: 'world greatest stretch',
    target: 'hamstrings',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'body weight',
    gifUrl: 'https://backend.fitlinez.com/all/xS9tDh4P3aO1yB.gif',
    id: '1428',
    name: 'wrist circles',
    target: 'forearms',
  },
  {
    bodyPart: 'lower arms',
    equipment: 'weighted',
    gifUrl: 'https://backend.fitlinez.com/all/UhlHBw8JpuzkXL.gif',
    id: '0859',
    name: 'wrist rollerer',
    target: 'forearms',
  },
];

export default Alll;
