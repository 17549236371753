import React from 'react';
import { useLocation } from 'react-router-dom';

function EditSession() {
  const location = useLocation();

  return (
    <div>
      <h1>Edit Session</h1>
      <p>{location.state.video.name}</p>
    </div>
  );
}

export default EditSession;
