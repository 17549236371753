import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { IconPlus } from '../img/Icons';
import { addSingleItem, getsingleDay } from '../apis/UpdateWorkoutPlan';

function SearchComponent({ items, planId, day }) {
  // Log the planId and day
  console.log('planId', planId);
  console.log('day', day);

  const handleOnSearch = (string, results) => {
    // Handle search input
    // console.log(string, results);
  };

  const handleOnHover = (result) => {
    // Handle item hover
    // console.log(result);
  };

  const handleOnSelect = async (item) => {
    // Handle item selection
    try {
      await addSingleItem(planId, item._id, day).then(() => {
        getsingleDay(planId, day);
      });

      // Optionally, handle UI updates or notifications here
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleOnFocus = () => {
    console.log('Focused');
  };

  const formatResult = (item) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px',
          cursor: 'pointer',
        }}>
        <span style={{ marginRight: 10 }}>
          <IconPlus />
        </span>
        <span>{item.name}</span>
        <span style={{ marginLeft: 'auto' }}>{item.bodyPart}</span>
        <img
          src={item.gifUrl}
          alt={item.name}
          style={{
            width: 50,
            height: 50,
            borderRadius: '50%',
            marginLeft: 10,
          }}
        />
      </div>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <ReactSearchAutocomplete
        maxResults={100}
        items={items}
        onSearch={handleOnSearch}
        onHover={handleOnHover}
        onSelect={handleOnSelect}
        onFocus={handleOnFocus}
        autoFocus
        formatResult={formatResult}
      />
    </div>
  );
}

export default SearchComponent;
