import api from './api';
const getImageReport = async (_id) => {
  try {
    const response = await api.get(`/imagereport/${_id}`);
    return response.data;
  } catch (err) {
    console.error(err);
    // Consider how to handle the error here (e.g., rethrow, return null, etc.)
    throw err;
  }
};

const getComments = async () => {
  try {
    const response = await api.get(`/comment`);
    console.log(response);
    return response.data;
  } catch (err) {
    console.error(err);
    // Consider how to handle the error here (e.g., rethrow, return null, etc.)
    throw err;
  }
};

const deleteComment = async (id) => {
  try {
    const response = await api.delete(`/comment/${id}`);
    alert('Comment deleted successfully');
    return response.data;
  } catch (err) {
    console.error(err);
    // Consider how to handle the error here (e.g., rethrow, return null, etc.)
    throw err;
  }
};

const getLastReports = async () => {
  try {
    const response = await api.get(`/report/last24hours`);
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.error(err);
    // Consider how to handle the error here (e.g., rethrow, return null, etc.)
    throw err;
  }
};

const sendReplyToComment = async (id, response, adminId, adminName) => {
  try {
    const res = await api.post(`/comment/comments/${id}/response`, {
      response,
      adminId,
      adminName,
    });
    return res.data;
  } catch (err) {
    console.error(err);
    // Consider how to handle the error here (e.g., rethrow, return null, etc.)
    throw err;
  }
};

export {
  getImageReport,
  getComments,
  deleteComment,
  getLastReports,
  sendReplyToComment,
};
