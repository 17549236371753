import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import api from '../../apis/api';
import products from '../../data/products';
function EmailSender() {
  const [multiSender, setMultiSender] = useState(false);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [isSent, setIsSent] = useState(false);
  const [groupSend, setGroupSend] = useState(false);
  const [userList, setUserList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userProduct, setUserProduct] = useState('');
  const [sender, setSender] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [status, setStatus] = useState('idle');

  const handleQuesTextRich = (e) => {
    console.log(e.target.getContent());
    setMessage(e.target.getContent());
  };

  const getUserList = () => {
    setStatus('loading');
    api
      .post(`/emailfinder`, {
        params: {
          product: userProduct,
        },
      })
      .then((res) => {
        setUserList(res.data);
        if (userList.length > 0) {
          console.log('userList.length', userList?.length);
          setGroupSend(true);
        }
        setStatus('idle');
      })
      .catch((err) => {
        alert(err.message);
        setStatus('error');
      });
  };
  useEffect(() => {
    getUserList();
  }, [userProduct]);

  useEffect(() => {
    console.log(userList?.length);
  }, [userList]);

  console.log('groupSend', groupSend);
  console.log('userList', userList);

  const AlertMessage = () => {
    if (status === 'sent') {
      return <Alert variant="info">{resultMessage}</Alert>;
    } else if (status === 'error') {
      return <Alert variant="warning">{resultMessage}</Alert>;
    }
  };

  const sendEmail = async () => {
    setStatus('sending');
    // let email = groupSend ? userList : email;
    let email = [
      'vahidinline@gmail.com',
      'afshari.vahid@gmail.com',
      'vahid@fitlinez.com',
      'info@fitlinez.com',
    ];
    try {
      const response = await fetch('http://localhost:8080/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'process.env.API_KEY',
        },
        body: JSON.stringify({
          email,
          subject,
          message,
          sender,
          senderEmail,
        }),
      });
      const data = await response.json();
      console.log(data);
      if (data.success) {
        setResultMessage(data.message);
        setStatus('sent');
      } else {
        setResultMessage(data.message);
        setStatus('error');
      }
    } catch (error) {
      console.log(error);

      setResultMessage(error.message);
      setStatus('error');
    }
  };

  //console.log(emailList);

  return (
    //bootstrap form
    <Container xs={12} md={8} lg={6} className="mt-5">
      <Row>
        <Col>
          <Form>
            <Form.Check
              type="checkbox"
              label="Send to custom users"
              onChange={(e) => setMultiSender(e.target.checked)}
            />
          </Form>
        </Col>
      </Row>
      {multiSender ? (
        <Row className="mb-5">
          <Col>
            <Form>
              <Form.Select
                value={userProduct || []}
                onChange={(e) => setUserProduct(e.target.value)}>
                <option value="">No Product</option>
                {products?.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form>
          </Col>
          <Col>
            {' '}
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  onChange={(e) => setSearchQuery(e.target.value)}
                  type="text"
                  value={searchQuery}
                  placeholder="Search by name or email"
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      ) : (
        <Row className="mb-5">
          <Col>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      )}
      <Row className="mb-5">
        <Col>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Email subject"
                onChange={(e) => setSubject(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Sender Name"
                onChange={(e) => setSender(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setSenderEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <AlertMessage />
      </Row>
      <Row>
        <Editor
          apiKey="y7gnmtbsaxnjbgh3405ioqbdm24eit5f0ovek49w8yvq5r9q"
          init={{
            branding: false,
            height: 400,
            menubar: true,
            plugins:
              'print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern',
            toolbar:
              'formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
            image_advtab: true,
          }}
          onChange={(e) => handleQuesTextRich(e)}
        />
      </Row>
      <Row>
        <Col>
          <Button
            //disabled={isSending}
            className="mt-3"
            onClick={sendEmail}
            variant="success">
            Send Email
          </Button>{' '}
        </Col>
      </Row>
    </Container>
  );
}

export default EmailSender;
