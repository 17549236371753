import axios from 'axios';
import { useEffect, useState } from 'react';
import { Accordion, Card, Col, Container, Row, Stack } from 'react-bootstrap';
import SupportForm from '../../components/supportForm';
import api from '../../apis/api';

const Support = () => {
  const [tickets, setTickets] = useState([]);
  const getAllTickets = async () => {
    const response = await api.get('/support');
    setTickets(response.data);
  };
  console.log(tickets);
  useEffect(() => {
    getAllTickets();
  }, []);

  return (
    <Container>
      <h1>Support</h1>
      <Accordion defaultActiveKey="0">
        {tickets.map((ticket, i) => (
          <Accordion.Item eventKey={i}>
            <Accordion.Header>
              <Stack direction="horizontal" gap={3}>
                <div className="ms-auto">{ticket.topic}</div>
                <div className="vr" />
                <div className="ms-auto">{ticket.ticketNumber}</div>
                <div className="ms-auto">{ticket.deviceName}</div>
                <div className="">
                  {' '}
                  {ticket.status === 'pending' ? (
                    <span className="badge bg-warning text-dark ms-2">
                      {ticket.status}
                    </span>
                  ) : (
                    <span className="badge bg-success text-dark ms-2">
                      {ticket.status}
                    </span>
                  )}
                </div>
              </Stack>
            </Accordion.Header>
            <Accordion.Body>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title>{ticket.date}</Card.Title>
                      <Card.Text>Issue:{ticket.description}</Card.Text>
                      <Card.Text>Issue at: {ticket.path}</Card.Text>
                      <Card.Text>User Id: {ticket.userId}</Card.Text>
                      <Card.Text>User Name: {ticket.userName}</Card.Text>
                      {ticket.reply &&
                        ticket.reply.map((reply) => (
                          <Row>
                            <Col>
                              <Card.Text>Reply: {reply.reply}</Card.Text>
                              <Card.Text>Reply at: {reply.date}</Card.Text>
                            </Col>
                          </Row>
                        ))}
                    </Col>
                    <Col>
                      <SupportForm ticket={ticket} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      {/* <ListGroup>
        {tickets.map((ticket) => (
          <ListGroup.Item>
            <h3>{ticket.title}</h3>
            <p>{ticket.description}</p>
          </ListGroup.Item>
        ))}
      </ListGroup> */}
    </Container>
  );
};

export default Support;
