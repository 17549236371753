import React, { useContext, useEffect, useState } from 'react';
import { Table, Form, Col, Row, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as _ from 'lodash';
import UserContext from '../../context/usercontext';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import bodyPart from '../../data/bodyParts';
import api from '../../apis/api';

function ActiveList(props) {
  const ItemType = 'TABLE_ROW';
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [excercise, setExcercise] = useState([]);
  const [bodyPartList, setBodyPartList] = useState('all');

  const [typeList, setTypeList] = useState('normal');
  const [category, setCategory] = useState('all');
  const [toggleActive, setToggleActive] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useContext(UserContext);
  const [toggleLoc, setToggleLoc] = useState('Gym');
  const [editedrow, setEditedRow] = useState();
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [imageUrls, setImageUrls] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // const handleClick = (d) => {
  //   const queryParams = new URLSearchParams({
  //     data: JSON.stringify(d),
  //   }).toString();
  //   navigate(`/editworkout?${queryParams}`);
  // };

  const filteredExcercise = excercise
    // .filter(
    //   (item) =>
    //     item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     item.name?.toLowerCase().includes(searchQuery.toLowerCase())
    // )
    // .filter((item) => !typeList || typeList === 'all' || item.type === typeList)
    .filter(
      (item) =>
        !bodyPartList ||
        bodyPartList === 'all' ||
        item.mainTarget === bodyPartList
    );

  //filter by having instructor
  //.filter((item) => toggleActive === false || item.instructor);
  // .filter((item) =>
  //   toggleActive
  //     ? item.hasOwnProperty('instructor') && item.instructor
  //     : !item.hasOwnProperty('instructor') || !item.instructor
  // );

  const clientData = async () => {
    try {
      const response = await api.get('/newplan');
      setExcercise(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const sortedData = [...filteredExcercise].sort((a, b) => {
    if (a.type === 'warmup' && b.type !== 'warmup') {
      return -1; // "warmup" appears before other types
    } else if (a.type !== 'warmup' && b.type === 'warmup') {
      return 1; // Other types appear after "warmup"
    } else if (a.type === 'cooldown' && b.type !== 'cooldown') {
      return 1; // Other types appear before "cooldown"
    } else if (a.type !== 'cooldown' && b.type === 'cooldown') {
      return -1; // "cooldown" appears after other types
    } else {
      return a.order - b.order;
    }
  });

  useEffect(() => {
    clientData();
  }, []);

  const handleClick = (d) => {
    const queryParams = new URLSearchParams({
      data: JSON.stringify(d),
    }).toString();
    window.open(`/editworkout?${queryParams}`, '_blank');
  };

  return (
    <>
      <Row
        style={{
          justifyContent: 'space-between',
          width: '80%',
          margin: 'auto',
          alignContent: 'center',
          marginBottom: '20px',
        }}>
        <Col>
          <div>{sortedData.length}</div>
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              label={toggleLoc === 'Gym' ? 'Gym ' : 'Home'}
              onChange={() =>
                toggleLoc === 'Gym' ? setToggleLoc('Home') : setToggleLoc('Gym')
              }
            />
          </Form>
        </Col>

        {/* <Col>
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              label={toggleActive === true ? 'Show All' : 'No Instruction'}
              onChange={() =>
                toggleActive === true
                  ? setToggleActive(false)
                  : setToggleActive(true)
              }
            />
          </Form>
        </Col> */}
        <Col>
          <Form.Control
            type="text"
            placeholder="Search by name"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        </Col>
        <Col>
          <Form>
            <Form.Select onChange={(e) => setBodyPartList(e.target.value)}>
              <option value="all">All</option>
              {bodyPart.map((part, index) => (
                <option key={index} value={part}>
                  {part}
                </option>
              ))}
            </Form.Select>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Select onChange={(e) => setTypeList(e.target.value)}>
              <option value="all">All</option>
              <option value="normal">normal</option>
              <option value="warmup">Warm up</option>
              <option value="cooldown">Cool Down</option>
            </Form.Select>
          </Form>
        </Col>

        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}></Col>
      </Row>

      <Table
        striped="columns"
        hover
        style={{
          width: '80%',
          margin: 'auto',
        }}>
        <thead className="thead-dark">
          <tr>
            <th
              style={{
                width: '30%',
              }}
              className="col">
              Name
            </th>
            {/* <th
              style={{
                width: '5%',
              }}
              className="col">
              Status
            </th> */}

            <th
              style={{
                width: '7%',
              }}
              className="col">
              Type
            </th>
            <th
              style={{
                width: '7%',
              }}
              className="col">
              Input Type
            </th>

            <th
              style={{
                width: '20%',
              }}
              className="col">
              Main Muscle
            </th>
            <th
              style={{
                width: '20%',
              }}
              className="col">
              Muscle Type
            </th>
            <th
              style={{
                width: '12%',
              }}
              className="col">
              Image
            </th>
            <th
              style={{
                width: '13%',
              }}
              className="col">
              Location
            </th>
            <th
              style={{
                width: '13%',
              }}
              className="col">
              Equipment
            </th>
            {/* <th
              style={{
                width: '3%',
              }}
              className="col">
              Instruction
            </th> */}

            <th className="col">Edit</th>
          </tr>
        </thead>
        {sortedData?.map(
          (d, i) =>
            // d?.category === item?.name &&
            (d?.loc === (toggleLoc === 'Gym' ? 'Gym' : 'Home') ||
              d?.loc === 'Both') && (
              <tbody>
                <tr>
                  <td>
                    <h4>{d?.name}</h4>
                    <p
                      className="text-muted small
                    ">
                      {d._id}
                    </p>
                  </td>
                  {/* <td>{d?.active === true ? 'Active' : 'Not active '}</td> */}

                  <td>{d?.type}</td>
                  <td>{d?.inputType}</td>

                  <td>
                    <p>Main muscle: {d?.mainTarget}</p>
                    <p> Other muscles: {d?.otherTarget}</p>
                  </td>
                  <td>
                    <p> {d?.muscleType}</p>
                  </td>
                  <td>
                    <img
                      src={d.gifUrl}
                      alt="gif"
                      width="100px"
                      height="100px"
                    />
                  </td>

                  <td>{d?.loc}</td>
                  <td>{d?.equipment}</td>
                  <td>
                    {/* {d.instructor ? (
                      'has Instruction'
                    ) : ( */}
                    <a
                      target="_blank"
                      style={{
                        color: 'white',
                        textDecoration: 'none',
                        backgroundColor: 'green',
                        padding: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleClick(d)}>
                      Edit
                    </a>
                    {/* )} */}
                  </td>
                </tr>
              </tbody>
            )
        )}
      </Table>
    </>
  );
}

export default ActiveList;
