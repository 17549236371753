import api from './api';

const getUserByEmail = async (email) => {
  try {
    console.log(email);
    const response = await api.get(`/api/findUser/`, {
      params: {
        email,
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

//find users by product

const getUsersByProduct = async (product) => {
  try {
    console.log(product);
    const response = await api.get(`/api/userbyproduct/`, {
      params: {
        product,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export { getUserByEmail, getUsersByProduct };
