const products = [
  {
    id: 1,
    name: 'Shape up Academy',
    value: 'Shape up Academy',
  },
  {
    id: 1,
    name: 'Shape up pro 5',
    value: 'Shape up pro 5',
  },
  {
    id: 1,
    name: 'Shape up 5',
    value: 'Shape up 5',
  },
  {
    id: 1,
    name: 'Shape up pro 4',
    value: 'Shape up pro 4',
  },
  {
    id: 1,
    name: 'Shape up 6',
    value: 'Shape up 6',
  },
  {
    id: 1,
    name: 'Shape up 7',
    value: 'Shape up 7',
  },
  {
    id: 1,
    name: 'Shape up pro 6',
    value: 'Shape up pro 6',
  },
  {
    id: 1,
    name: 'Shape up pro 7',
    value: 'Shape up pro 7',
  },
];

export default products;
