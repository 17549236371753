import { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../../apis/api';

const TrainerForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    nickName: '',
    photo: '',
    location: '',
    socialMedia: [{ platform: '', link: '' }],
    trainerType: '',
    scope: '',
    country: '',
    certificates: [{ name: '', institution: '', dateReceived: '' }],
    expertise: [{ area: '', description: '' }],
    bio: '',
    languages: '',
    availability: '',
    acceptTerms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSocialMediaChange = (index, e) => {
    const updatedSocialMedia = formData.socialMedia.map((sm, i) =>
      i === index ? { ...sm, [e.target.name]: e.target.value } : sm
    );
    setFormData({ ...formData, socialMedia: updatedSocialMedia });
  };

  const handleCertificateChange = (index, e) => {
    const updatedCertificates = formData.certificates.map((cert, i) =>
      i === index ? { ...cert, [e.target.name]: e.target.value } : cert
    );
    setFormData({ ...formData, certificates: updatedCertificates });
  };

  const handleExpertiseChange = (index, e) => {
    const updatedExpertise = formData.expertise.map((exp, i) =>
      i === index ? { ...exp, [e.target.name]: e.target.value } : exp
    );
    setFormData({ ...formData, expertise: updatedExpertise });
  };

  const addField = (field) => {
    setFormData({
      ...formData,
      [field]: [...formData[field], { platform: '', link: '' }],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/trainer', formData);
      alert('Trainer data submitted successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="mb-4">Trainer Registration</h1>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Nickname</Form.Label>
              <Form.Control
                type="text"
                name="nickName"
                value={formData.nickName}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Photo URL</Form.Label>
              <Form.Control
                type="text"
                name="photo"
                value={formData.photo}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Social Media</Form.Label>
          {formData.socialMedia.map((sm, index) => (
            <Row key={index} className="mb-2">
              <Col>
                <Form.Control
                  as="select"
                  name="platform"
                  value={sm.platform}
                  onChange={(e) => handleSocialMediaChange(index, e)}>
                  <option value="">Select Platform</option>
                  <option value="instagram">Instagram</option>
                  <option value="facebook">Facebook</option>
                  <option value="twitter">Twitter</option>
                  <option value="linkedin">LinkedIn</option>
                  <option value="youtube">YouTube</option>
                  <option value="tiktok">TikTok</option>
                </Form.Control>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="link"
                  placeholder="Link"
                  value={sm.link}
                  onChange={(e) => handleSocialMediaChange(index, e)}
                />
              </Col>
            </Row>
          ))}
          <Button variant="link" onClick={() => addField('socialMedia')}>
            Add Social Media
          </Button>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Trainer Type</Form.Label>
          <Form.Control
            as="select"
            name="trainerType"
            value={formData.trainerType}
            onChange={handleChange}>
            <option value="">Select Type</option>
            <option value="personal">Personal</option>
            <option value="group">Group</option>
            <option value="specialist">Specialist</option>
            <option value="master">Master</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Scope</Form.Label>
          <Form.Control
            as="select"
            name="scope"
            value={formData.scope}
            onChange={handleChange}>
            <option value="">Select Scope</option>
            <option value="local">Local</option>
            <option value="global">Global</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Certificates</Form.Label>
          {formData.certificates.map((cert, index) => (
            <div key={index}>
              <Form.Control
                type="text"
                name="name"
                placeholder="Certificate Name"
                value={cert.name}
                onChange={(e) => handleCertificateChange(index, e)}
                className="mb-2"
              />
              <Form.Control
                type="text"
                name="institution"
                placeholder="Institution"
                value={cert.institution}
                onChange={(e) => handleCertificateChange(index, e)}
                className="mb-2"
              />
              <Form.Control
                type="date"
                name="dateReceived"
                value={cert.dateReceived}
                onChange={(e) => handleCertificateChange(index, e)}
                className="mb-2"
              />
            </div>
          ))}
          <Button variant="link" onClick={() => addField('certificates')}>
            Add Certificate
          </Button>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Areas of Expertise</Form.Label>
          {formData.expertise.map((exp, index) => (
            <div key={index}>
              <Form.Control
                type="text"
                name="area"
                placeholder="Expertise Area"
                value={exp.area}
                onChange={(e) => handleExpertiseChange(index, e)}
                className="mb-2"
              />
              <Form.Control
                type="text"
                name="description"
                placeholder="Description"
                value={exp.description}
                onChange={(e) => handleExpertiseChange(index, e)}
                className="mb-2"
              />
            </div>
          ))}
          <Button variant="link" onClick={() => addField('expertise')}>
            Add Expertise
          </Button>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Bio</Form.Label>
          <Form.Control
            as="textarea"
            name="bio"
            value={formData.bio}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Languages</Form.Label>
          <Form.Control
            type="text"
            name="languages"
            value={formData.languages}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Availability</Form.Label>
          <Form.Control
            as="select"
            name="availability"
            value={formData.availability}
            onChange={handleChange}>
            <option value="">Select Availability</option>
            <option value="full-time">Full-Time</option>
            <option value="part-time">Part-Time</option>
            <option value="freelance">Freelance</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            name="acceptTerms"
            label="I accept the terms and conditions"
            checked={formData.acceptTerms}
            onChange={handleChange}
          />
        </Form.Group>

        <Button type="submit" className="w-100">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default TrainerForm;
