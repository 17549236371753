import React, { useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import Users from '../img/users.svg';
import Workout from '../img/workout.svg';
import Booking from '../img/booking.svg';
import UserContext from '../context/usercontext';

const homeData = [
  {
    id: 1,
    name: 'User Managements',
    link: '/users',
    description: 'Users',
    img: Users,
  },
  {
    id: 2,
    name: 'Excercise Managements',
    link: '/newplans',
    description: 'Excercises',
    img: Workout,
  },
  {
    id: 3,
    name: 'Booking Managements',
    link: '/booking',
    description: 'Bookings',
    img: Booking,
  },
];

const Home = () => {
  const { user, setUser } = useContext(UserContext);
  return (
    <Container fluid="md">
      {/* two cols in big and medium screen in a row, 1 col in small display in a row */}
      {user?.role === 'admin' ? (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margintop: '20px',
          }}>
          {homeData.map((item) => (
            <Col xs={12} sm={4} md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>{item.name}</Card.Title>
                  <Card.Img src={item.img} />
                  <Card.Text>{item.description}</Card.Text>
                  <Button as={Link} to={item.link} variant="primary">
                    see {item.name}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Navigate to="/login" />
      )}
    </Container>
  );
};

export default Home;
