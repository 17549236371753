import React from 'react';
import { Badge, Col, ListGroup, Offcanvas, Row } from 'react-bootstrap';
import { Dribbble, Justify } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import '../../index.css';
function Sidebar({ listOfFilters }) {
  return (
    <Offcanvas
      show={true}
      responsive="lg"
      onHide={() => {}}
      style={{
        fontFamily: 'Vazirmatn',
        direction: 'rtl',
        JustifyContent: 'space-between',
        textDecoration: 'none',
        // fontFamily: 'Noto Naskh',
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '1px solid #ccc',
        padding: '5px',
        width: '100px',
        direction: 'rtl',
      }}>
      <Offcanvas.Body>
        <ListGroup>
          {listOfFilters.sort().map((item, index) => {
            return (
              <ListGroup.Item>
                <Link
                  style={{
                    fontFamily: 'Vazirmatn',
                  }}
                  to={`/users/${item.alias}`}
                  state={{ value: item.value, name: item.name }}>
                  {item.name}
                  <Badge bg="primary" className="ms-2">
                    {item?.value.length}
                  </Badge>
                </Link>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Sidebar;
