import { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { getUsersByProduct } from '../../apis/findUserByEmail';
import { getLastReports } from '../../apis/getData';
import '../../index.css';
import api from '../../apis/api';

const UserList = () => {
  const { state } = useLocation();
  console.log('state', state);
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState('idle');
  const handleGetUser = async () => {
    setStatus('loading');
    try {
      getUsersByProduct(state.name).then((res) => {
        setUsers(res);
        setStatus('success');
      });
    } catch (error) {
      console.error(error);
      setStatus('error');
    }
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  const name = state?.name;

  const [searchQuery, setSearchQuery] = useState('');
  // const filteredUsers = users.filter(
  //   (user) =>
  //     user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     user.email?.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const handleGetLastReport = async () => {
    if (state.name === 'گزارش هفتگی') {
      try {
        const response = await getLastReports();
        console.log(response);
        setUsers(response);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const handleGetAllUsers = async () => {
    if (state.name == 'تمام کاربران') {
      setStatus('loading');
      api
        .get(`/getUsers`)
        .then((res) => {
          setStatus('success');
          setUsers(res.data);
        })
        .catch((err) => {
          setStatus('error');
          alert(err.message);
        });
    }
  };

  useEffect(() => {
    handleGetLastReport();
  }, []);

  useEffect(() => {
    handleGetAllUsers();
  }, []);

  return (
    <Container
      style={{
        fontFamily: 'Vazirmatn',
      }}
      className="mt-5 w-1/2"
      dir="rtl">
      {status === 'loading' && <h1>در حال بارگزاری...</h1>}
      {status === 'error' && <h1>خطا در بارگذاری</h1>}
      {status === 'success' && (
        <>
          <Row className="mb-5 ">
            <div className="text-center w-full flex flex-row">
              <h3 className="">{name}</h3>
              <span>{users?.length} نفر</span>
            </div>
            <Col>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    onChange={(e) => setSearchQuery(e.target.value)}
                    type="text"
                    value={searchQuery}
                    placeholder="
                جستجو بر اساس نام یا ایمیل کاربران"
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={6} lg={12} xl={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>نام</th>
                    <th>ایمیل</th>
                    <th>دوره های ثبت نامی</th>
                    <th>عملیات</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>{user.name}</td>
                        <td>
                          {user.email}
                          <br />
                          {user._id}
                        </td>
                        <td>
                          {user?.userProduct?.map((product) => (
                            <Badge>{product.name}</Badge>
                          ))}
                        </td>

                        <td>
                          <Link
                            to={`/user/query?email=${user.email}`}
                            state={user}>
                            <Button variant="success">پروفایل کاربر</Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default UserList;
