import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Row,
  Image,
  Spinner,
  Alert,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Navigate, useLocation } from 'react-router-dom';
import UserContext from '../../context/usercontext';
import bodyPart from '../../data/bodyParts';
import equipment from '../../data/equipment';
import Alll from './response';
import api from '../../apis/api';

const AddWorkout = () => {
  const [allExcercise, setAllExcercise] = useState([]);

  const [editedExcercise, setEditedExcercise] = useState();

  // useEffect(() => {
  //   //get all excercise
  //   const getAllExcercise = async () => {
  //     try {
  //       const response = await axios.get(
  //         'http://localhost:8080/workout/newPlan'
  //       );
  //       //filter by active and bodypart
  //       const notactiveExcercise = response.data.filter(
  //         (item) =>
  //           item.active === false && item.bodyPart === excercise.bodyPart
  //       );

  //       setAllExcercise(notactiveExcercise);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   getAllExcercise();
  // }, []);
  const location = useLocation();
  const state = location.state;

  const { user } = useContext(UserContext);
  const [excercise, setExcercise] = useState(state?.data || {});
  //console.log('excercise', excercise);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState(state?.gifUrl || null);
  const [percent, setPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [selectedbodyPart, setSelectedbodyPart] = useState('all');
  // useEffect(() => {
  //   //add imageUrls to excercise
  //   setExcercise((prevExercise) => ({
  //     ...prevExercise,
  //     gifUrl: imageUrls,
  //   }));
  // }, [imageUrls]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === 'bodyPart') {
      setSelectedbodyPart(value);
    }
    if (name === 'subsitute') {
      // Find the exercise from allExcercise by matching the value
      console.log(value);
      const substitute = Alll.filter((item) => value.includes(item.name));
      console.log(substitute);

      setExcercise((prevExercise) => ({
        ...prevExercise,
        //subsitute is an array of objects

        [name]: substitute,
      }));
    } else {
      setExcercise((prevExercise) => ({
        ...prevExercise,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submit started');
    setIsLoading(true);
    setIsDisabled(true);

    try {
      const response = await api.post(`/workout/`, excercise);
      setIsLoading(false);
      setShowModal(true);
      console.log(response.status);
      setMessage('Update successful!');

      //reset form
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setShowModal(true);
      setMessage('Updated failed: ');
      setIsError(true);
    }
  };

  // const closeSuccessModal = () => {
  //   window.location.href = '/';
  // };

  // const handleUploadImage = async (e) => {
  //   setLoading(true);
  //   console.log(e.target.files[0]);
  //   const file = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append('file', file);

  //   try {
  //     const response = await axios.post(
  //       'https://backend.fitlinez.com/upload',
  //       formData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //         onUploadProgress: (progressEvent) => {
  //           const { loaded, total } = progressEvent;
  //           let percent = Math.floor((loaded * 100) / total);
  //           console.log(`${loaded}kb of ${total}kb | ${percent}%`);

  //           if (percent < 100) {
  //             setPercent(percent);
  //           }
  //         },
  //       }
  //     );
  //     console.log(response.data);
  //     setImageUrls(response.data.urls);
  //     setPercent(100);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      {user?.role === 'admin' ? (
        <Container style={{ marginTop: '5rem' }} fluid>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            {/* center h1 */}
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <h1>
                {excercise ? `Editing ${excercise.name}` : ' Edit excercise'}
              </h1>
            </Col>
          </Row>

          <Row>
            <Col
              fluid
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Form
                onSubmit={(e) => {
                  // setIsDisabled(true);
                  e.preventDefault();
                  handleSubmit(e);
                }}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Excersice Name</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.name}
                        required
                        type="text"
                        placeholder="name of excersice"
                        name="name"
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Excersice sets</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.sets}
                        type="number"
                        placeholder="number of sets"
                        name="sets"
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>
                  {excercise.type !== 'cooldown' &&
                    excercise.type !== 'warmup' && (
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Excersice order</Form.Label>
                          <Form.Control
                            defaultValue={excercise?.order}
                            type="number"
                            placeholder="order"
                            name="order"
                            onChange={handleInput}
                          />
                        </Form.Group>
                      </Col>
                    )}
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Input Type</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.inputType}
                        name="inputType"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Input Type</option>
                        <option value="timer">Timer</option>
                        <option value="rep">Reps</option>
                        <option value="weight">Weight</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* <Form.Group>
                      <Form.Label>Image (gif)</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Image"
                        name="gifUrl"
                        onChange={(e) => handleUploadImage(e)}
                      />
                    </Form.Group> */}
                    <Spinner
                      animation="grow"
                      variant="danger"
                      style={{ display: loading ? 'block' : 'none' }}
                    />
                  </Col>

                  <Col>
                    <Image
                      src={excercise?.gifUrl}
                      style={{ width: '200px', height: '200px' }}
                      roundedCircle
                    />
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.type}
                        name="type"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Excercise Type</option>
                        <option value="warmup">Warm up</option>
                        <option value="normal">Normal Excersice</option>
                        <option value="cooldown">Cool down</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Muscle Type</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.type}
                        name="muscleType"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select muscle Type</option>
                        <option value="huge">huge</option>
                        <option value="large">large</option>
                        <option value="long">long</option>
                        <option value="short">short</option>
                        <option value="small">small</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>equipment</Form.Label>
                      <Form.Select
                        defaultValue={excercise.equipment}
                        name="equipment"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Equipment</option>
                        {equipment.map((equipment) => (
                          <option value={equipment}>{equipment}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>bodyPart</Form.Label>
                      <Form.Select
                        defaultValue={excercise.target}
                        name="bodyPart"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Body Part</option>
                        {bodyPart.map((bodyPart) => (
                          <option value={bodyPart}>{bodyPart}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      {/* select multiple items */}
                      <Dropdown onChange={(e) => handleInput(e)}>
                        <Dropdown.Toggle
                          name="subsitute"
                          variant="success"
                          id="dropdown-basic">
                          Select Subtitutes
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {Alll.map((item, i) => {
                            return item.target === selectedbodyPart ? (
                              <Dropdown.Item
                                eventKey={item.id}
                                key={i}
                                onSelect={() => handleInput(item)}>
                                <img
                                  src={item.gifUrl}
                                  alt={item.name}
                                  width="50"
                                />
                                {item.name}
                              </Dropdown.Item>
                            ) : null;
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                      <Form.Select
                        multiple
                        name="subsitute"
                        as="select"
                        onChange={(e) => handleInput(e)}>
                        {Alll.map((item, i) => {
                          return item.target === selectedbodyPart ? (
                            <option key={i} value={item._id}>
                              {item.name}
                            </option>
                          ) : null;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Main Target</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.target}
                        name="mainTarget"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Main Target</option>
                        {bodyPart.map((bodyPart) => (
                          <option value={bodyPart}>{bodyPart}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>other Target</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.otherTarget}
                        name="otherTarget"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select other Target</option>
                        {bodyPart.map((bodyPart) => (
                          <option value={bodyPart}>{bodyPart}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col>
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.category}
                        name="category"
                        as="select"
                        onChange={handleInput}>
                        <option value="">Select Category</option>
                        {workOutCategories.map((category) => (
                          <option value={category.name}>{category.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group> */}
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Location</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.loc}
                        name="loc"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Location</option>
                        <option value="Home">Home</option>
                        <option value="Gym">Gym</option>
                        <option value="Both">Both</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Excersice Level</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.level}
                        name="level"
                        as="select"
                        required
                        onChange={handleInput}>
                        <option value="">Select Level</option>
                        <option value="beginner">beginner</option>
                        <option value="moderate">moderate</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group></Form.Group>
                    <Form.Group></Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        defaultValue={excercise?.active}
                        name="active"
                        onChange={handleInput}>
                        <option>Select Status</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.description}
                        as="textarea"
                        placeholder="details about the excersice"
                        name="description"
                        onChange={handleInput}
                        style={{ height: '100px' }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>instruction</Form.Label>
                      <Form.Control
                        defaultValue={excercise?.instructor}
                        as="textarea"
                        placeholder="instructions about the excersice"
                        name="instructor"
                        onChange={handleInput}
                        style={{ height: '100px' }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="m-3" controlId="formBasicEmail">
                  <Button
                    style={{ width: '100%' }}
                    variant="primary"
                    size="lg"
                    type="submit">
                    {!isLoading ? (
                      'Submit'
                    ) : (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Form.Group>
                {/* show alert or success message here */}
                {showModal && (
                  <Alert variant="success">
                    <Alert.Heading>Success</Alert.Heading>
                    <p>{message}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button
                        //onClick={() => closeSuccessModal()}
                        variant="outline-success">
                        Close
                      </Button>
                    </div>
                  </Alert>
                )}
                {isError && (
                  <Alert variant="danger">
                    <Alert.Heading>Error</Alert.Heading>
                    <p>{message}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={() => setIsError(false)}
                        variant="outline-danger">
                        Close
                      </Button>
                    </div>
                  </Alert>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default AddWorkout;
