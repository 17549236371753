import React, { useEffect, useState } from 'react';
import { Carousel, Col, Image, Row, Modal, Form } from 'react-bootstrap';
import { getImageReport } from '../../../apis/getData';
import moment from 'moment';
import 'moment/locale/fa'; // Import Farsi locale
import '../../../index.css';

function BeforeAfter({ _id, setUserImage, name }) {
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    //get user credential from storage
    const user = JSON.parse(localStorage.getItem('credentials'));

    //check if user is logged in
  }, []);

  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedCarousels, setSelectedCarousels] = useState([]);
  const [checkedCarousels, setCheckedCarousels] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setSelectedCarousels(checkedCarousels);
    setShow(true);
  };

  const handleCheck = (item) => {
    if (
      checkedCarousels.some((checkedItem) => checkedItem.date === item.date)
    ) {
      setCheckedCarousels(
        checkedCarousels.filter((checkedItem) => checkedItem.date !== item.date)
      );
    } else if (checkedCarousels.length < 2) {
      setCheckedCarousels([...checkedCarousels, item]);
    }
  };

  const getPastDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
  };

  useEffect(() => {
    const showData = async () => {
      try {
        const data = await getImageReport(_id);
        setItems(data);
        console.log(data, 'user images');
        setUserImage(items.length);
      } catch (error) {
        console.error(error);
      }
    };

    if (_id) {
      showData();
    }
  }, [_id]);

  return (
    <Row
      style={{
        fontFamily: 'Vazirmatn',
      }}>
      {items.map((item) => (
        <Col sm={6} key={item.date}>
          <Carousel interval={null} slide={false}>
            {item?.data.map((imageURL, index) => (
              <Carousel.Item key={index}>
                <Image
                  src={imageURL}
                  alt={`Report Image ${imageURL}`}
                  className="carousel-image"
                  onClick={() => handleShow()}
                />
                <Carousel.Caption>
                  <Row>
                    <Col>
                      <p>{moment(item.date).locale('fa').format('YYYY/M/D')}</p>
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        label="Select"
                        onChange={() => handleCheck(item)}
                      />
                    </Col>
                  </Row>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      ))}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {selectedCarousels.map((carousel, index) => (
              <Col key={index}>
                <Carousel interval={null} slide={false}>
                  {carousel.data.map((imageURL, imgIndex) => (
                    <Carousel.Item key={imgIndex}>
                      <Image
                        src={imageURL}
                        fluid
                        style={{
                          height: '500px',
                          width: '500px',
                          objectFit: 'cover',
                        }}
                      />
                      <Carousel.Caption>
                        <Row>
                          <Col>
                            <p>
                              {moment(carousel.date)
                                .locale('fa')
                                .format('YYYY/M/D')}
                            </p>
                          </Col>
                        </Row>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
    </Row>
  );
}

export default BeforeAfter;
