import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import api from '../../../apis/api';

const SessionIndex = () => {
  const [videos, setVideos] = useState({});

  useEffect(() => {
    const getVideos = async () => {
      try {
        const response = await api.get('/sessionvideo/all');
        const categorizedVideos = response.data.reduce((acc, video) => {
          const productKey = video.product;
          if (!acc[productKey]) {
            acc[productKey] = [];
          }
          acc[productKey].push(video);
          return acc;
        }, {});

        // Sort videos for each product by date (latest first)
        for (const product in categorizedVideos) {
          categorizedVideos[product].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
        }

        // Create an array of [product, latestDate] pairs to sort categories
        const sortedCategories = Object.entries(categorizedVideos).sort(
          (a, b) => {
            const latestDateA = new Date(a[1][0].createdAt);
            const latestDateB = new Date(b[1][0].createdAt);
            return latestDateB - latestDateA;
          }
        );

        // Convert sorted categories back to an object
        const sortedCategorizedVideos = Object.fromEntries(sortedCategories);

        setVideos(sortedCategorizedVideos);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    getVideos();
  }, []);

  return (
    <Container
      dir="rtl"
      style={{
        fontFamily: 'Vazirmatn',
      }}>
      <Row>
        <Col> لیست جلسات برگزار شده</Col>
        <Col>
          <Button onClick={() => (window.location.href = 'Sessions/addvideo')}>
            جلسه جدید
          </Button>
        </Col>
      </Row>
      {Object.keys(videos).map((product) => (
        <div key={product}>
          <Row>
            <Col>
              {' '}
              <h2>{product}</h2>
            </Col>
          </Row>
          <Row>
            {videos[product].map((video) => (
              <Col
                md={3}
                style={{
                  height: 150,
                }}
                key={video.id}>
                <Card
                  style={{
                    height: 100,
                  }}>
                  <Card.Img
                    variant="top"
                    src={video.thumbnailUrl}
                    alt={video.title}
                  />
                  <Card.Body>
                    <Card.Title>{video.title}</Card.Title>
                    <Card.Text>{video.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Container>
  );
};

export default SessionIndex;
