const bodyPart = [
  'Chest',
  'Upper Back',
  'Lower Back',
  'Shoulders',
  'Biceps',
  'Triceps',
  'Forearms',
  'Abdominals',
  'Rectus Abdominis',
  'Obliques',
  'Quadriceps',
  'Hamstrings',
  'Glutes',
  'Calves',
  'Hip Adductors',
  'Hip Abductors',
  'Neck',
  'Serratus Anterior',
  'Tibialis Anterior',
  'Psoas',
  'Rotator Cuff',
  'Latissimus Dorsi',
  'Transverse Abdominis',
  'Erector Spinae',
  'Iliotibial Band',
  'Piriformis',
  'Gastrocnemius',
  'Soleus',
];

export default bodyPart;
